const bubbleChartMixin = {
    data() {
        return {
            bubbleChartMixinOptions: {
                chart: {
                    type: 'packedbubble',
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: 'Deviation from the norm',
                    style: {
                        color: '#ffffff'
                    }
                },
                subtitle: {
                    text: 'A larger bubble represents a bigger deviation from your typical behavior',
                    style: {
                        color: '#ffffff'
                    }
                },
                tooltip: {
                    useHTML: true,
                    pointFormat: '<b>{point.name}:</b> {point.value}%'
                },
                plotOptions: {
                    packedbubble: {
                        minSize: '45%',
                        maxSize: '100%',
                        zMin: 0,
                        zMax: 1000,
                        layoutAlgorithm: {
                            gravitationalConstant: 0.05,
                            splitSeries: true,
                            seriesInteraction: false,
                            dragBetweenSeries: false,
                            parentNodeLimit: true
                        },
                        dataLabels: {
                            enabled: false,
                        }
                    }
                },
                series: []
            }
        }
    }
}

export default bubbleChartMixin;
