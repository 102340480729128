<template>
  <b-container fluid>
    <b-row align-h="center">
      <h5 class="text-secondary text-center">
        Are you sure you want to not import this record?
      </h5>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <span>Date:</span>
      </b-col>
      <b-col>
        <span class="text-secondary">{{ record.date }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <span>Payee:</span>
      </b-col>
      <b-col>
        <span class="text-secondary">{{ record.payee }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <span>Exclude from totals:</span>
      </b-col>
      <b-col>
        <span class="text-secondary">{{ record.exclude_from_totals }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <span>Category:</span>
      </b-col>
      <b-col>
        <span class="text-secondary">{{ categoryName(record.category) }}</span>
      </b-col>
    </b-row>
    <b-row v-if="record.balance" class="mt-1">
      <b-col>
        <span>Balance:</span>
      </b-col>
      <b-col>
        <span :class="record.balance < 0 ? 'text-danger' : 'text-success'">{{
          formatCurrency(record.balance, currency)
        }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <span>Amount:</span>
      </b-col>
      <b-col>
        <span :class="record.amount < 0 ? 'text-danger' : 'text-success'">{{
          formatCurrency(record.amount, currency)
        }}</span>
      </b-col>
    </b-row>
    <b-row v-if="record.dividends" class="mt-1">
      <b-col>
        <span>Dividends:</span>
      </b-col>
      <b-col>
        <span :class="record.dividends < 0 ? 'text-danger' : 'text-success'">{{
          formatCurrency(record.dividends, currency)
        }}</span>
      </b-col>
    </b-row>
    <b-row v-if="record.tags && record.tags.length" class="mt-1">
      <b-col>
        <span>Tags:</span>
      </b-col>
      <b-col>
        <b-badge
          v-for="tag_id in record.tags"
          :key="tag_id"
          variant="transparent"
          class="m-1 border border-secondary"
        >
          <b-icon icon="tag" />
          {{ tagName(tag_id) }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row v-if="record.notes && record.notes.length" class="mt-1">
      <b-col>
        <span>Notes:</span>
        <span class="text-secondary ml-3">{{ record.notes }}</span>
      </b-col>
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-button size="sm" variant="danger" @click="deleteRecord">
        <b-spinner v-if="fetching" small />
        <span v-else>Do not import</span>
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import Api from "../../api";
import { formatCurrency } from "@/utils/general.js";

export default {
    props: { recordIndex: [Number, String], hideEditModal: Function },
    data() {
        return {
            fetching: false,
            record: {},
        };
    },
    computed: {
        tagOptions() {
            return this.$store.getters["importer/tagList"];
        },
        categoryOptions() {
            switch (this.record.record_type) {
                case "transaction":
                    return this.$store.getters["importer/transactionCategoryList"];
                    break;
                case "income":
                    return this.$store.getters["importer/incomeCategoryList"];
                    break;
                case "saving":
                    return this.$store.getters["importer/savingCategoryList"];
                    break;
                default:
                    return [];
            }
        },
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
    },
    created() {
        this.record = {
            ...this.$store.getters["importer/record"](this.recordIndex),
            tags: [...this.$store.getters["importer/record"](this.recordIndex).tags],
        };
    },
    methods: {
        formatCurrency,
        /*
         * Return tag name from store based on tag id
         */
        tagName(tag_id) {
            return this.$store.getters["importer/tag"](tag_id).name;
        },
        /*
         * Return name from categoryOptions based on id
         */
        categoryName(category_id) {
            return this.categoryOptions.find((el) => el.id === category_id).name;
        },
        /*
         * Delete record in store and recordDeleted
         */
        deleteRecord() {
            this.$store.commit("importer/removeRecord", this.recordIndex);
            this.$emit("recordDeleted");
        },
    },
};
</script>