// vue imports
import Vue from "vue";

// 3rd party imports
import VueRouter from "vue-router";

// project imports
import Login from "@/components/login/Index.vue";
import BulkImport from "@/components/bulk_import/Index.vue";
import CSVIndex from "@/components/csv_import/Index.vue";
import CSVClassify from "@/components/csv_import/Classify.vue";
import CSVMapColumns from "@/components/csv_import/MapColumns.vue";
import CSVImportTable from "@/components/csv_import/ImportTable.vue";
import RecordCreator from "@/components/record_creator/Index.vue";
import AccountManager from "@/components/account_manager/Index.vue";
import CategoryManager from "@/components/category_manager/Index.vue";
import TagManager from "@/components/tag_manager/Index.vue";
import Rules from "@/components/rules/Index.vue";
import Settings from "@/components/settings/Index.vue";
import GeneralDashboard from "@/components/general_dashboard/Index.vue";
import InvestmentsDashboard from "@/components/investments_dashboard/Index.vue";
import DataManagementDashboard from "@/components/data_management_dashboard/Index.vue";
import FIPlanIndex from "@/components/fi_plan/Index.vue";
import FIPlanner from "@/components/fi_plan/Planner.vue";
import QueryTool from "@/components/query_tool/Index.vue";
import Onboarding from "@/components/onboarding/Index.vue";
import Signup from "@/components/signup/Index.vue";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history", // remove /#/ from end of urls
  base: "/app/", // base url that spa is served from
  /*
   * scroll to top of page when route is changed
   */
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "GeneralDashboard",
      component: GeneralDashboard,
    },
    // This alias is needed when the site is loaded as a PWA to route correctly
    // if user opens to pages other than the home page
    {
      path: "/index.html",
      component: GeneralDashboard,
      alias: "/",
    },
    {
      path: "/login/",
      name: "Login",
      component: Login,
    },
    {
      path: "/bulk-import/",
      name: "BulkImport",
      component: BulkImport,
    },
    {
      path: "/csv-import/import/",
      name: "CSVIndex",
      component: CSVIndex,
    },
    {
      path: "/csv-import/map-columns/",
      name: "CSVMapColumns",
      component: CSVMapColumns,
      props: true,
    },
    {
      path: "/csv-import/classify/",
      name: "CSVClassify",
      component: CSVClassify,
    },
    {
      path: "/csv-import/import/",
      name: "CSVImportTable",
      component: CSVImportTable,
    },
    {
      path: "/create-record/",
      name: "RecordCreator",
      component: RecordCreator,
      props: true,
    },
    {
      path: "/accounts/",
      name: "AccountManager",
      component: AccountManager,
    },
    {
      path: "/categories/",
      name: "CategoryManager",
      component: CategoryManager,
      props: true,
    },
    {
      path: "/tags/",
      name: "TagManager",
      component: TagManager,
    },
    {
      path: "/rules/",
      name: "Rules",
      component: Rules,
    },
    {
      path: "/settings/",
      name: "Settings",
      component: Settings,
    },
    {
      path: "/investments-dashboard/",
      name: "InvestmentsDashboard",
      component: InvestmentsDashboard,
    },
    {
      path: "/investments-management/",
      name: "InvestmentsManagement",
      component: DataManagementDashboard,
      props: true,
    },
    {
      path: "/income-management/",
      name: "IncomeManagement",
      component: DataManagementDashboard,
      props: true,
    },
    {
      path: "/transaction-management/",
      name: "TransactionManagement",
      component: DataManagementDashboard,
      props: true,
    },
    {
      path: "/fi-plan/",
      name: "FIPlanIndex",
      component: FIPlanIndex,
    },
    {
      path: "/fi-plan/planner/",
      name: "FIPlanner",
      props: true,
      component: FIPlanner,
    },
    {
      path: "/query/",
      name: "QueryTool",
      component: QueryTool,
    },
    {
      path: "/onboarding/",
      name: "Onboarding",
      component: Onboarding,
    },
    {
      path: "/signup/",
      name: "Signup",
      component: Signup,
    },
  ],
});

/*
 * Nav to login if user is not authenticated.
 * Nav to onboarding if onboarding not complete.
 * Nav to signup if user is not active.
 * Else nav to requested route.
 */
router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !store.getters.isAuthenticated)
    next({ name: "Login" });
  else if (
    to.name !== "Onboarding" &&
    store.getters["onboarding/onboardingIncomplete"]
  )
    next({ name: "Onboarding" });
  else next();
});

export default router;
