<template>
  <b-container fluid>
    <div v-if="records.length" class="mt-3">
      <b-row class="mt-4 mb-5">
        <b-table
          hover
          responsive
          outlined
          small
          bordered
          striped
          sticky-header="500px"
          :fields="tableFields"
          :items="records"
        >
          <!-- Headers -->
          <template v-slot:head(buttons)="scope">
            <div style="width: 100px">
              {{ scope.label }}
            </div>
          </template>
          <template v-slot:head(date)="scope">
            <div style="width: 130px">
              {{ scope.label }}
            </div>
          </template>
          <template v-slot:head(account)="scope">
            <div style="width: 150px">
              {{ scope.label }}
            </div>
          </template>
          <template v-slot:head(payee)="scope">
            <div style="width: 150px">
              {{ scope.label }}
            </div>
          </template>
          <template v-slot:head(exclude_from_totals)="scope">
            <div style="width: 150px">
              {{ scope.label }}
            </div>
          </template>
          <template v-slot:head(category)="scope">
            <div style="width: 150px">
              {{ scope.label }}
            </div>
          </template>
          <template v-slot:head(balance)="scope">
            <div style="width: 120px">
              {{ scope.label }}
            </div>
          </template>
          <template v-slot:head(amount)="scope">
            <div style="width: 120px">
              {{ scope.label }}
            </div>
          </template>
          <template v-slot:head(dividends)="scope">
            <div style="width: 120px">
              {{ scope.label }}
            </div>
          </template>
          <template v-slot:head(notes)="scope">
            <div style="width: 150px">
              {{ scope.label }}
            </div>
          </template>
          <!-- Data -->
          <template v-slot:cell(buttons)="scope">
            <b-button-group>
              <b-button
                size="sm"
                variant="outline-info"
                @click="displayUpdateModal(scope.item)"
              >
                <b-icon icon="pencil-square" variant="info" />
              </b-button>
              <b-button
                size="sm"
                variant="outline-info"
                @click="displayDeleteModal(scope.item)"
              >
                <b-icon icon="x-square" variant="danger" />
              </b-button>
            </b-button-group>
          </template>
          <template v-slot:cell(date)="scope">
            {{ date_string(scope.value) }}
          </template>
          <template v-slot:cell(account)="scope">
            {{
              account(scope.value).full_name
            }}
          </template>
          <template v-slot:cell(category)="scope">
            {{
              category(scope.value).name
            }}
          </template>
          <template v-slot:cell(balance)="scope">
            <span :class="scope.value < 0 ? 'text-danger' : 'text-success'">{{
              formatCurrency(scope.value, currency)
            }}</span>
          </template>
          <template v-slot:cell(amount)="scope">
            <span :class="scope.value < 0 ? 'text-danger' : 'text-success'">{{
              formatCurrency(scope.value, currency)
            }}</span>
          </template>
          <template v-slot:cell(dividends)="scope">
            <span :class="scope.value < 0 ? 'text-danger' : 'text-success'">{{
              formatCurrency(scope.value, currency)
            }}</span>
          </template>
          <template v-slot:cell(tags)="scope">
            <b-badge
              v-for="recordTag in scope.value"
              :key="recordTag"
              variant="transparent"
              class="m-1 border border-secondary"
            >
              <b-icon icon="tag" />
              {{ tag(recordTag).name }}
            </b-badge>
          </template>
        </b-table>
      </b-row>
    </div>
    <!--
            Modals 
        -->
    <b-modal ref="delete_modal" hide-footer>
      <DeleteForm
        :record="selectedRecord"
        :record-type="recordType"
        @recordDeleted="recordDeleted"
      />
    </b-modal>
    <b-modal ref="update_modal" hide-footer>
      <UpdateForm
        :record="selectedRecord"
        :record-type="recordType"
        @recordUpdated="recordUpdated"
      />
    </b-modal>
  </b-container>
</template>

<script>
import { formatCurrency } from "@/utils/general.js";
import DeleteForm from "./DeleteForm.vue";
import UpdateForm from "./UpdateForm.vue";

export default {
    components: { DeleteForm, UpdateForm },
    props: { recordType: String },
    data() {
        return {
            tableFields: this.getTableFields(),
            selectedRecord: {},
        };
    },
    computed: {
        records() {
            return this.$store.getters["record_creator/recordList"];
        },
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
    },
    methods: {
        formatCurrency,
        account(id) {
            return this.$store.getters["record_creator/account"](id);
        },
        category(id) {
            return this.$store.getters["record_creator/category"](id);
        },
        tag(id) {
            return this.$store.getters["record_creator/tag"](id);
        },
        date_string(date) {
            return new Date(date).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });
        },
        /*
         * set the selectedRecord and display the delete modal
         */
        displayDeleteModal(record) {
            this.selectedRecord = {
                ...record,
                tags: [...record.tags],
            };
            this.$refs["delete_modal"].show();
        },
        /*
         * emitted via DeleteForm when record deleted on server
         */
        recordDeleted() {
            this.$bvToast.toast("Record Deleted", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
            this.$store.commit("record_creator/deleteRecord", this.selectedRecord.id);
            this.$refs["delete_modal"].hide();
        },
        /*
         * set the selectedRecord and display the update modal
         */
        displayUpdateModal(record) {
            this.selectedRecord = {
                ...record,
                tags: [...record.tags],
            };
            this.$refs["update_modal"].show();
        },
        /*
         * emitted via UpdateForm when record updated on server
         */
        recordUpdated(record) {
            this.$bvToast.toast("Record Updated", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
            this.$store.commit("record_creator/createUpdateRecord", record);
            this.$refs["update_modal"].hide();
        },
        /*
         * return table headers
         */
        getTableFields() {
            let tableFields = [
                {
                    key: "buttons",
                    label: "",
                    sortable: false,
                    stickyColumn: true,
                    variant: "light",
                },
                {
                    key: "date",
                    label: "Date",
                    sortable: true,
                },
                {
                    key: "account",
                    label: "Account",
                    sortable: true,
                },
                {
                    key: "payee",
                    label: "Payee",
                    sortable: true,
                },
                {
                    key: "exclude_from_totals",
                    label: "Exclude From Totals",
                    sortable: true,
                },
                {
                    key: "category",
                    label: "Category",
                    sortable: true,
                },
                {
                    key: "amount",
                    label: "Amount",
                    sortable: true,
                },
                {
                    key: "tags",
                    label: "Tags",
                    sortable: false,
                },
                {
                    key: "notes",
                    label: "Notes",
                    sortable: true,
                },
            ];
            if (this.recordType === "saving") {
                tableFields.splice(6, 0, {
                    key: "balance",
                    label: "Balance",
                    sortable: true,
                });
                tableFields.splice(7, 0, {
                    key: "dividends",
                    label: "Dividends",
                    sortable: true,
                });
            }
            return tableFields;
        },
    },
};
</script>