<template>
  <b-container fluid>
    <b-row align-h="center" class="mt-2">
      <h5 class="text-secondary text-center">
        Are you sure you want to delete this tag?
      </h5>
    </b-row>
    <b-row align-h="center" class="mt-2">
      <span>{{ tag.name }}</span>
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-button size="sm" variant="danger" @click="deleteTag">
        <b-spinner v-if="fetching" small />
        <span v-else>Delete</span>
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
    props: { tag: Object },
    data() {
        return {
            fetching: false,
        };
    },
    methods: {
        /*
         * Delete tag on server then emit tagDeleted in parent
         */
        deleteTag() {
            this.fetching = true;
            Api.deleteTag(this.tag.id)
                .then((res) => {
                    this.fetching = false;
                    this.$bvToast.toast("Tag Deleted", {
                        title: "Success",
                        variant: "success",
                        autoHideDelay: 2500,
                    });
                    this.$emit("tagDeleted");
                })
                .catch((err) => {
                    this.fetching = false;
                    this.$bvToast.toast("Unable to delete tag.", {
                        title: "Tag Not Deleted",
                        variant: "danger",
                        autoHideDelay: 2500,
                    });
                    console.log(err.response);
                });
        },
    },
};
</script>