<template>
  <b-container fluid class="pt-5 pt-sm-0">
    <supportAlert link="https://www.moneyfi.app/support/financial_independence/" />
    <h3 class="text-center">
      Financial Independence
    </h3>
    <b-row align-h="center" class="mt-3">
      <b-col cols="10">
        <p class="text-secondary text-center">
          Create and manage your FI Plan.
        </p>
        <p class="text-secondary text-center">
          Track your progress, make adjustments and add future events to understand how
          they impact your FI timeline.
        </p>
      </b-col>
    </b-row>
    <b-row class="mt-4" align-h="center">
      <b-col cols="10">
        <b-card header-bg-variant="transparent" footer-class="bg-transparent">
          <template v-slot:header>
            <h5 class="text-center">
              Basic Plans
            </h5>
          </template>
          <b-row>
            <b-col cols="12" sm="4">
              <b-row align-h="center">
                <b-col>
                  <p class="text-secondary">
                    Create a plan based off of any data you want.
                  </p>
                  <p class="text-secondary">
                    Basic plans don't hook into any of your existing data.
                  </p>
                </b-col>
              </b-row>
              <b-row align-h="center">
                <router-link
                  :to="{
                    name: 'FIPlanner',
                    params: { planType: 'basic' },
                  }"
                >
                  <b-button variant="info" size="sm">
                    Create New Plan
                  </b-button>
                </router-link>
              </b-row>
            </b-col>
            <b-col cols="12" sm="8">
              <SavedPlans plan-type="basic" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-5" align-h="center">
      <b-col cols="10">
        <b-card header-bg-variant="transparent">
          <template v-slot:header>
            <h5 class="text-center">
              Integrated Plans
            </h5>
          </template>
          <b-row>
            <b-col cols="12" sm="4">
              <b-row align-h="center">
                <b-col>
                  <p class="text-secondary">
                    Create a plan which hooks into all your existing data.
                  </p>
                  <p class="text-secondary">
                    Integrated plans create an accurate projection based on your
                    real financial data.
                  </p>
                </b-col>
              </b-row>
              <b-row align-h="center">
                <router-link
                  :to="{
                    name: 'FIPlanner',
                    params: { planType: 'integrated' },
                  }"
                >
                  <b-button variant="info" size="sm">
                    Create New Plan
                  </b-button>
                </router-link>
              </b-row>
            </b-col>
            <b-col cols="12" sm="8">
              <SavedPlans plan-type="integrated" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SavedPlans from "./SavedPlans.vue";
import supportAlert from "@/components/support_alert/Index.vue";

export default {
    components: { SavedPlans, supportAlert },
};
</script>