import Vue from "vue";

const getDefaultState = () => {
  return {
    fetching: false,
    plan_params: {
      annual_contribution_increase: 5,
      annual_return: 8,
      balance: null,
      inflation: 2,
      monthly_contribution: null,
      monthly_expense: null,
      name: "New Plan",
      withdrawal_rate: 4,
      rolling_window: 12,
      rolling_agg_type: "median",
    },
    once_off_events: {},
    recurring_events: {},
  };
};

const state = getDefaultState();

const getters = {
  /*
   * List getters
   */
  onceOffEventList: (state) => {
    return Object.values(state.once_off_events);
  },
  recurringEventList: (state) => {
    return Object.values(state.recurring_events);
  },

  /*
   * Return true if event name already exists for specified eventType
   */
  eventNameExists: (state) => ({ eventType, eventName }) => {
    const events = Object.values(state[`${eventType}_events`]);
    return events.some(
      (evt) => evt.name.toLowerCase() === eventName.toLowerCase()
    );
  },

  /*
   * Get event by name / type
   */
  getEvent: (state) => ({ eventType, eventName }) => {
    return state[`${eventType}_events`][eventName];
  },
};

const mutations = {
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },

  /*
   * Update fetching to provided value
   */
  updateFetching: (state, data) => {
    state.fetching = data.fetching;
  },
  /*
   * initialise state data
   */
  initPlanParams: (state, planParams) => {
    state.plan_params = { ...planParams };
  },
  initOnceOffEvents: (state, onceOffEvents) => {
    let eventObject = {};
    onceOffEvents.forEach((onceOffEvent) => {
      eventObject[onceOffEvent.name] = { ...onceOffEvent };
    });
    Vue.set(state, "once_off_events", eventObject);
  },
  initRecurringEvents: (state, recurringEvents) => {
    let eventObject = {};
    recurringEvents.forEach((recurringEvent) => {
      eventObject[recurringEvent.name] = { ...recurringEvent };
    });
    Vue.set(state, "recurring_events", eventObject);
  },

  /*
   * Update a specific plan parameter
   */
  updatePlanParam: (state, data) => {
    state.plan_params[data.name] = data.value;
  },

  /*
   * Create or update event
   */
  createUpdateEvent: (state, { eventType, event }) => {
    Vue.set(state[`${eventType}_events`], event.name, event);
  },

  /*
   * Rename event by cloning it with a new name and deleting the original
   */
  renameEvent: (state, { eventType, oldName, newName }) => {
    const cloned = { ...state[`${eventType}_events`][oldName] };
    Vue.set(state[`${eventType}_events`], newName, cloned);
    Vue.delete(state[`${eventType}_events`], oldName);
  },

  /*
   * Delete event
   */
  deleteEvent: (state, { eventType, eventName }) => {
    Vue.delete(state[`${eventType}_events`], eventName);
  },

  /*
   * toggle event.exclude_from_comparison for specified event
   */
  toggleExcludeFromComparison: (state, { eventType, eventName }) => {
    let exclude_from_comparison =
      state[`${eventType}_events`][eventName].exclude_from_comparison;
    Vue.set(
      state[`${eventType}_events`][eventName],
      "exclude_from_comparison",
      !exclude_from_comparison
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
