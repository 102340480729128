export function formatCurrency(value, currency) {
    if (value !== '' && typeof value !== 'string') {
        try {
            let number = value.toLocaleString('en-US', {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
            return number;
        } catch (e) {
            return value;
        }
    }
    return value;
}