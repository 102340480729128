<template>
  <b-container fluid>
    <b-row>
      <b-col>Are you sure you want to delete this {{ planType }} plan?</b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <h4 class="text-secondary">
          {{ planName }}
        </h4>
      </b-col>
    </b-row>
    <hr>
    <b-row align-h="end" class="mt-1">
      <b-button size="sm" variant="danger" @click="deletePlan">
        <b-spinner v-if="fetching" small />
        <span v-else>Delete Plan</span>
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
    props: {
        planType: String,
        planName: { type: String, default: "" },
        planId: { type: Number, default: 0 },
    },
    data() {
        return {
            fetching: false,
        };
    },
    methods: {
        deletePlan() {
            this.fetching = true;
            Api.deletePlan(this.planType, this.planId)
                .then((res) => {
                    this.fetching = false;
                    this.$emit("planDeleted", this.planId);
                })
                .catch((err) => {
                    this.fetching = false;
                    console.log(err.response);
                });
        },
    },
};
</script>