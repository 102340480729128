<template>
  <div>
    <b-button v-b-toggle.sidebar-backdrop class="bm-burger-button" variant="info">
      <b-icon icon="list" font-scale="1.3" />
    </b-button>
    <b-sidebar id="sidebar-backdrop" backdrop shadow>
      <b-container>
        <b-row>
          <b-col>
            <span class="text-secondary">Dashboards</span>
          </b-col>
        </b-row>
        <router-link :to="{ name: 'GeneralDashboard' }">
          <b-row class="mt-3">
            <b-col>
              <b-icon
                class="menu-item ml-4 mr-4"
                variant="info"
                icon="clipboard-data"
                font-scale="1.3"
              />
              <span class="text-secondary">General</span>
            </b-col>
          </b-row>
        </router-link>
        <router-link :to="{ name: 'InvestmentsDashboard' }">
          <b-row class="mt-3">
            <b-col>
              <b-icon
                class="menu-item ml-4 mr-4"
                variant="info"
                icon="graph-up"
                font-scale="1.3"
              />
              <span class="text-secondary">Investments</span>
            </b-col>
          </b-row>
        </router-link>
        <hr>
        <b-row>
          <b-col>
            <span class="text-secondary">Analysis</span>
          </b-col>
        </b-row>
        <router-link :to="{ name: 'FIPlanIndex' }">
          <b-row class="mt-3">
            <b-col>
              <b-icon
                class="menu-item ml-4 mr-4"
                variant="info"
                icon="trophy"
                font-scale="1.3"
              />
              <span class="text-secondary">Financial Independence</span>
            </b-col>
          </b-row>
        </router-link>
        <router-link :to="{ name: 'QueryTool' }">
          <b-row class="mt-3">
            <b-col>
              <b-icon
                class="menu-item ml-4 mr-4"
                variant="info"
                icon="search"
                font-scale="1.3"
              />
              <span class="text-secondary">Query Tool</span>
            </b-col>
          </b-row>
        </router-link>
        <hr>
        <b-row>
          <b-col>
            <span class="text-secondary">My Data</span>
          </b-col>
        </b-row>
        <router-link :to="{ name: 'IncomeManagement', params: { recordType: 'income' } }">
          <b-row class="mt-3">
            <b-col>
              <b-icon
                class="menu-item ml-4 mr-4"
                variant="info"
                icon="gift"
                font-scale="1.3"
              />
              <span class="text-secondary">Income</span>
            </b-col>
          </b-row>
        </router-link>
        <router-link
          :to="{ name: 'InvestmentsManagement', params: { recordType: 'saving' } }"
        >
          <b-row class="mt-3">
            <b-col>
              <b-icon
                class="menu-item ml-4 mr-4"
                variant="info"
                icon="graph-up"
                font-scale="1.3"
              />
              <span class="text-secondary">Investments</span>
            </b-col>
          </b-row>
        </router-link>
        <router-link
          :to="{ name: 'TransactionManagement', params: { recordType: 'transaction' } }"
        >
          <b-row class="mt-3">
            <b-col>
              <b-icon
                class="menu-item ml-4 mr-4"
                variant="info"
                icon="credit-card"
                font-scale="1.3"
              />
              <span class="text-secondary">Transactions</span>
            </b-col>
          </b-row>
        </router-link>
        <router-link :to="{ name: 'TagManager' }">
          <b-row class="mt-3">
            <b-col>
              <b-icon
                class="menu-item ml-4 mr-4"
                variant="info"
                icon="tag"
                font-scale="1.3"
              />
              <span class="text-secondary">Tags</span>
            </b-col>
          </b-row>
        </router-link>
        <router-link :to="{ name: 'AccountManager' }">
          <b-row class="mt-3">
            <b-col>
              <b-icon
                class="menu-item ml-4 mr-4"
                variant="info"
                icon="building"
                font-scale="1.3"
              />
              <span class="text-secondary">Accounts</span>
            </b-col>
          </b-row>
        </router-link>
        <router-link :to="{ name: 'BulkImport' }">
          <b-row class="mt-3">
            <b-col>
              <b-icon
                class="menu-item ml-4 mr-4"
                variant="info"
                icon="upload"
                font-scale="1.3"
              />
              <span class="text-secondary">Bulk Import</span>
            </b-col>
          </b-row>
        </router-link>
        <hr>
        <router-link :to="{ name: 'Settings' }">
          <b-row class="mt-3">
            <b-col>
              <b-icon
                class="menu-item ml-4 mr-4"
                variant="info"
                icon="gear-fill"
                font-scale="1.3"
              />
              <span class="text-secondary">Settings</span>
            </b-col>
          </b-row>
        </router-link>
        <router-link v-if="displayMembershipPlanLink" :to="{ name: 'Signup' }">
          <b-row class="mt-3">
            <b-col>
              <b-icon
                class="menu-item ml-4 mr-4"
                variant="info"
                icon="person-fill"
                font-scale="1.3"
              />
              <span class="text-secondary">Membership Plans</span>
            </b-col>
          </b-row>
        </router-link>        
        <b-row class="mt-3">
          <b-col>
            <a href="https://www.moneyfi.app/support/" target="_blank">
              <b-icon icon="book" class="menu-item ml-4 mr-4" variant="info" font-scale="1.3" />
              <span class="text-secondary">Support</span>
              <b-icon icon="box-arrow-up-right" variant="secondary" class="ml-2" />
            </a>
          </b-col>
        </b-row>        
        <hr>
        <b-row class="mt-4 mb-4" align-h="center">
          <b-button size="sm" variant="info" @click="logout">
            Logout
          </b-button>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>

<script>
import { AUTH_LOGOUT } from "@/store/actions/auth";

export default {
  computed: {
    displayMembershipPlanLink() {
      return this.$store.state.signup.active_status.user_is_within_trial && !this.$store.state.signup.active_status.user_is_paidup;
    },
  },
  methods: {
      /*
        * dispatch AUTH_LOGOUT to logout and navigate to login route
        */
      logout() {
          this.$store
              .dispatch(AUTH_LOGOUT)
              .then(() => {
                  this.$router.push({ name: "Login" });
              })
              .catch((err) => {});
      },
  },
};
</script>

<style>
.bm-burger-button {
    z-index: 1000;
    position: fixed;
    left: 10px;
    top: 20px;
}
</style>
