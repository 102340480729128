const timeSeries2AxisChartMixin = {
  data() {
    return {
      timeSeries2AxisChartMixinUpdateArgs: [true, true, { duration: 3000 }],
      timeSeries2AxisChartMixinOptions: {
        chart: {
          zoomType: 'x',
          type: 'line'
        },
        credits: {
          enabled: false
        },
        title: {
          text: ""
        },
        subtitle: {
          text: ""
        },
        plotOptions: {
          series: {
            lineWidth: 4,
            label: {
              enabled: false
            },
            marker: {
              enabled: false
            }
          },
          xrange: {
            pointWidth: 20,
            yAxis: 2,
            dataLabels: {
              enabled: true,
              format: '{point.name}',
              style: {
                textOutline: 0,
                fontWeight: 100,
                fontSize: '1rem',
                color: '#000',
              },
            },
            tooltip: {
              headerFormat: '{point.key}<br/>',
              pointFormat: '{point.start_date} to {point.end_date}',
            },
          }
        },
        tooltip: {
          shared: true,
          /*
           * sort items from highest to lowest
           */
          formatter: function (tooltip) {
            var items = this.points || splat(this),
              series = items[0].series,
              s;

            // sort the values
            items.sort(function (a, b) {
              return ((a.y < b.y) ? -1 : ((a.y > b.y) ? 1 : 0));
            });
            items.reverse();

            return tooltip.defaultFormatter.call(this, tooltip);
          },
        },
        loading: {
          style: {
            backgroundColor: '#242F39'
          }
        },
        xAxis: {
          type: "datetime",
          crosshair: true,
        },
        yAxis: [{ // Primary yAxis
          startOnTick: false,
          title: {
            text: "",
            style: {
              color: "#ffffff"
            }
          }
        }, { // Secondary yAxis
          startOnTick: false,
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          title: {
            text: "",
            style: {
              color: "#ffffff"
            }
          },
          opposite: true
        }, { // Tertiary yAxis (ie: xrange used for events on FI chart)
          startOnTick: false,
          opposite: true,
          labels: {
            enabled: false
          },
          title: {
            text: "",
            style: {
              color: "#ffffff"
            }
          },
        }],
        series: []
      }
    }
  }
}
export default timeSeries2AxisChartMixin;