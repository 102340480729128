<template>
  <div class="mb-5">
    <b-button variant="info" class="back-button" :to="{ name: 'FIPlanIndex' }">
      <b-icon icon="arrow90deg-left" font-scale="1.3" />
    </b-button>
    <b-container fluid>
      <b-row align-h="center">
        <b-col cols="8" sm="10" lg="8" xl="6">
          <PlanForm :plan-type="planType" @runPlan="runPlan" />
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="12" md="8" lg="5" class="mt-4">
          <b-card>
            <Events event-type="recurring" />
          </b-card>
        </b-col>
        <b-col cols="12" md="8" lg="5" class="mt-4">
          <b-card>
            <Events event-type="once_off" />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <Results
      class="mt-5"
      :class="Object.keys(resultsData).length !== 0 ? '' : 'd-none'"
      :plan-type="planType"
      :results-data="resultsData"
    />
  </div>
</template>

<script>
import Api from "@/api";
import PlanForm from "./forms/PlanForm.vue";
import Events from "./Events.vue";
import Results from "./Results.vue";

export default {
    components: { PlanForm, Events, Results },
    props: { planType: String, planId: Number },
    data() {
        return {
            resultsData: {},
        };
    },
    /*
     * Reset store state. If a planId is provided, get plan data and save to store.
     */
    created() {
        this.$store.commit("fi_plan/resetState");

        if (this.planId !== undefined) {
            this.$store.commit("fi_plan/updateFetching", { fetching: true });
            Api.getPlan(this.planId, this.planType)
                .then((res) => {
                    this.$store.commit("fi_plan/updateFetching", { fetching: false });
                    const { once_off_events, recurring_events, ...plan_params } = res.data;
                    this.$store.commit("fi_plan/initOnceOffEvents", once_off_events);
                    this.$store.commit("fi_plan/initRecurringEvents", recurring_events);
                    this.$store.commit("fi_plan/initPlanParams", plan_params);
                })
                .catch((err) => {
                    this.$store.commit("fi_plan/updateFetching", { fetching: false });
                    console.log(err.response);
                });
        }
    },
    methods: {
        runPlan() {
            this.$store.commit("fi_plan/updateFetching", { fetching: true });
            const data = {
                ...this.$store.state.fi_plan.plan_params,
                once_off_events: this.$store.getters["fi_plan/onceOffEventList"],
                recurring_events: this.$store.getters["fi_plan/recurringEventList"],
            };
            Api.runPlan(this.planType, data)
                .then((res) => {
                    this.$store.commit("fi_plan/updateFetching", { fetching: false });
                    this.resultsData = res.data;
                })
                .catch((err) => {
                    this.$store.commit("fi_plan/updateFetching", { fetching: false });
                    console.log(err.response);
                    this.$bvToast.toast(err.response.data[0], {
                        title: "Failed to run plan",
                        variant: "danger",
                        solid: true,
                        autoHideDelay: 6000,
                    });
                });
        },
    },
};
</script>