// Used to display a fixed alert (bottom right) with a link to a specific page in the knowledge base

<template>
  <b-alert
    show
    class="position-fixed m-0 rounded-0"
    style="z-index: 2000; right: 0; bottom: 0"
    dismissible
    variant="info"
  >
    <a :href="link" target="_blank">
      <b-icon icon="book" variant="dark" />
      <span class="ml-2 mr-2 text-dark">
        The knowledge base has an article covering this section
      </span>
      <b-icon icon="box-arrow-up-right" variant="dark" />
    </a>
  </b-alert>
</template>

<script>
export default {
    props: { link: String },
};
</script>