import Vue from 'vue'

const getDefaultState = () => {
    return {
        fetching: false,
        accounts: {},
        income_categories: {},
        saving_categories: {},
        transaction_categories: {},
        tags: {},
        selected_display_option: 'table',
        params: {
            id: null,
            name: '',
            record_type: 'transaction',
            data_field: 'amount',
            timespan: 12,
            group_by: 'category',
            accounts: [],
            categories: [],
            payee: '',
            payee_filter_type: '',
            tags: [],
            notes: '',
            notes_filter_type: '__icontains',
            include_excluded_records: false
        },
        results: {
            table_headers: [],
            table_data: [],
            table_month_totals: [],
            chart_pie: { data: [] },
            chart_timeseries: []
        }
    }
}

const state = getDefaultState();

const getters = {
    // list of account objects
    accountList: state => {
        return Object.values(state.accounts)
    },
    // list of category objects based on record_type
    categoryList: (state) => (record_type) => {
        return Object.values(state[`${record_type}_categories`])
    },
    // list of tag objects
    tagList: state => {
        return Object.values(state.tags)
    },
}

const mutations = {
    resetState: (state) => {
        Object.assign(state, getDefaultState());
    },
    // Update fetching to provided value
    updateFetching: (state, fetching) => {
        state.fetching = fetching;
    },
    // init accounts data as key:value pairs
    initAccounts: (state, accounts) => {
        accounts.forEach(account => {
            Vue.set(state.accounts, account.id, account);
        })
    },
    // init category data for specified record_type as key:value pairs
    initCategories: (state, { record_type, categories }) => {
        categories.forEach(category => {
            Vue.set(state[`${record_type}_categories`], category.id, category);
        })
    },
    // init tags data as key:value pairs
    initTags: (state, tags) => {
        tags.forEach(tag => {
            Vue.set(state.tags, tag.id, tag);
        })
    },
    // Update selected_display_option
    updateSelectedDisplayOption: (state, display_option) => {
        state.selected_display_option = display_option
    },
    // Update state params
    updateParams: (state, data) => {
        state.params = { ...state.params, ...data }
    },
    // Update table_headers
    updateTableHeaders: (state, data) => {
        state.results.table_headers = data
    },
    // Update table_data
    updateTableData: (state, data) => {
        state.results.table_data = data
    },
    // Update table_month_totals
    updateTableMonthTotals: (state, data) => {
        state.results.table_month_totals = data
    },
    // Update chart_pie
    updateChartPie: (state, data) => {
        state.results.chart_pie = data
    },
    // Update chart_timeseries
    updateChartTimeseries: (state, data) => {
        state.results.chart_timeseries = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}