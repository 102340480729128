const pieChartMixin = {
    data() {
        return {
            pieChartMixinOptions: {
                chart: {
                    type: 'pie'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                tooltip: {
                    pointFormat: '{point.y:,.0f} ({point.percentage:.1f} %)'
                },
                loading: {
                    style: {
                        backgroundColor: '#242F39'
                    }
                },
                plotOptions: {
                    pie: {
                        showInLegend: true,
                        borderWidth: 0,
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                series: []
            }
        }
    }
}

export default pieChartMixin;
