<template>
  <b-container fluid class="pt-5 pt-sm-0 mb-5">
    <b-row align-h="center">
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="check-circle" variant="info" />
              <strong class="ml-2 text-info">Step 1</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">csv file selection</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="check-circle" variant="info" />
              <strong class="ml-2 text-info">Step 2</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Column mapping</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="circle" variant="secondary" />
              <strong class="ml-2">Step 3</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Classification and rules</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="circle" variant="secondary" />
              <strong class="ml-2">Step 4</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Confirm and import</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col class="text-center">
        <h5>Column Mapping</h5>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="10" sm="12" class="text-center">
        <span class="text-secondary">
          Map the Money FI data attributes to the corresponding source columns in your csv
          file.
        </span>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-3">
      <b-col class="text-center">
        <b-form-checkbox
          v-model="save_settings"
          value="true"
          size="sm"
          unchecked-value
          switch
          class="text-secondary"
        >
          Save these settings for future imports (specific to this account)
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-5">
      <b-col cols="5" md="4" class="text-center">
        <h4 class="text-secondary">
          <u>Money FI attribute</u>
        </h4>
      </b-col>
      <b-col cols="1" class="text-center" />
      <b-col cols="5" md="4" class="text-center">
        <h4 class="text-secondary">
          <u>CSV column</u>
        </h4>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center" class="mt-3">
      <b-col cols="5" md="4">
        <b-card>
          <h5>Date</h5>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Example result: </span><br>
              <span>{{ formattedDate }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="1" class="text-center">
        <b-icon icon="arrow-left" font-scale="1.5" variant="secondary" />
      </b-col>
      <b-col cols="5" md="4">
        <b-card>
          <b-form-select
            v-model="csv_date_header"
            :options="csvHeaderOptions"
            size="sm"
            @change="onChangeSetting"
          />
          <b-form-select
            v-model="csv_date_format"
            :options="csv_date_format_options"
            value-field="id"
            text-field="date_string"
            size="sm"
            class="mt-2"
            @change="onChangeSetting"
          />
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Example input: </span><br>
              <span>{{ data[0][csv_date_header] }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center" class="mt-2">
      <b-col cols="5" md="4">
        <b-card>
          <h5>Payee</h5>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Example result: </span><br>
              <span>{{ formattedPayee }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="1" class="text-center">
        <b-icon icon="arrow-left" font-scale="1.5" variant="secondary" />
      </b-col>
      <b-col cols="5" md="4">
        <b-card>
          <b-form-select
            v-model="csv_payee_header"
            :options="csvHeaderOptions"
            size="sm"
            @change="onChangeSetting"
          />
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Example input: </span><br>
              <span>{{ data[0][csv_payee_header] }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center" class="mt-2">
      <b-col cols="5" md="4">
        <b-card>
          <h5>Amount</h5>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Example result: </span><br>
              <span>{{ formattedAmount }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="1" class="text-center">
        <b-icon icon="arrow-left" font-scale="1.5" variant="secondary" />
      </b-col>
      <b-col cols="5" md="4">
        <b-card>
          <b-form-select
            v-model="csv_amount_header"
            :options="csvHeaderOptions"
            size="sm"
            @change="onChangeSetting"
          />
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Example input: </span><br>
              <span>{{ data[0][csv_amount_header] }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center" class="mt-2">
      <b-col cols="5" md="4">
        <b-card>
          <h5>Balance</h5>
          <span class="text-secondary">
            * Only required when importing investment data
          </span>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Example result: </span><br>
              <span>{{ formattedBalance }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="1" class="text-center">
        <b-icon icon="arrow-left" font-scale="1.5" variant="secondary" />
      </b-col>
      <b-col cols="5" md="4">
        <b-card>
          <b-form-select
            v-model="csv_balance_header"
            :options="csvHeaderOptions"
            size="sm"
            @change="onChangeSetting"
          />
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Example input: </span><br>
              <span>{{ data[0][csv_balance_header] }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center" class="mt-2">
      <b-col cols="5" md="4">
        <b-card>
          <h5>Notes</h5>
          <span class="text-secondary"> * Optional </span>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Example result: </span><br>
              <span>{{ formattedNotes }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="1" class="text-center">
        <b-icon icon="arrow-left" font-scale="1.5" variant="secondary" />
      </b-col>
      <b-col cols="5" md="4">
        <b-card>
          <b-form-select
            v-model="csv_notes_header"
            :options="csvHeaderOptions"
            size="sm"
            @change="onChangeSetting"
          />
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Example input: </span><br>
              <span>{{ data[0][csv_notes_header] }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col class="text-center">
        <b-button size="sm" variant="info" @click="onClickNext">
          <b-spinner v-if="fetching" variant="info" small />
          <span v-else>Next</span>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Api from "../../api";
import { formatCurrency } from "@/utils/general.js";

export default {
    props: { data: Array, csvHeaderOptions: Array, account: Object },
    data() {
        return {
            fetching: false,
            csv_date_format_options: [],
            csv_date_format: this.account.csv_date_format,
            csv_date_header: this.account.csv_date_header,
            csv_payee_header: this.account.csv_payee_header,
            csv_amount_header: this.account.csv_amount_header,
            csv_balance_header: this.account.csv_balance_header,
            csv_notes_header: this.account.csv_notes_header,
            save_settings: "true",
            settings_changed: false,
        };
    },
    computed: {
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
        /*
         * Format the date column based on the selected date format
         * if csv_date_format_options exists (ie: have been downloaded from server) and
         * the saved date header name for this account exists in the list of csv
         * column headers
         */
        formattedDate() {
            if (
                this.csv_date_format_options.length &&
                Object.keys(this.data[0]).includes(this.csv_date_header)
            ) {
                const date = this.parseDate(this.data[0][this.csv_date_header]);
                return date.toDateString();
            }
            return "";
        },
        /*
         * If the saved payee header name for this account exists in the list of csv
         * column headers, return the payee
         */
        formattedPayee() {
            if (Object.keys(this.data[0]).includes(this.csv_payee_header)) {
                return this.data[0][this.csv_payee_header];
            }
            return "";
        },
        /*
         * If the saved amount header name for this account exists in the list of csv
         * column headers, format and return the payee
         */
        formattedAmount() {
            if (Object.keys(this.data[0]).includes(this.csv_amount_header)) {
                return this.formatCurrency(
                    parseFloat(this.data[0][this.csv_amount_header]),
                    this.currency
                );
            }
            return "";
        },
        /*
         * If the saved balance header name for this account exists in the list of csv
         * column headers, format and return the balance
         */
        formattedBalance() {
            if (Object.keys(this.data[0]).includes(this.csv_balance_header)) {
                return this.formatCurrency(
                    parseFloat(this.data[0][this.csv_balance_header]),
                    this.currency
                );
            }
            return "";
        },
        /*
         * If the saved notes header name for this account exists in the list of csv
         * column headers, return the notes
         */
        formattedNotes() {
            if (Object.keys(this.data[0]).includes(this.csv_notes_header)) {
                return this.data[0][this.csv_notes_header];
            }
            return "";
        },
    },
    created() {
        Api.getCSVDateFormats()
            .then((res) => {
                this.csv_date_format_options = res.data;
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
    methods: {
        formatCurrency,
        /*
         * if a setting is changed, set settings_changed=true
         */
        onChangeSetting() {
            this.settings_changed = true;
        },
        /*
         * Update account settings on server
         */
        saveAccountSettings() {
            const data = {
                ...this.account,
                csv_date_format: this.csv_date_format,
                csv_date_header: this.csv_date_header,
                csv_payee_header: this.csv_payee_header,
                csv_amount_header: this.csv_amount_header,
                csv_balance_header: this.csv_balance_header,
                csv_notes_header: this.csv_notes_header,
            };
            Api.updateAccount(data)
                .then((res) => {
                    this.$bvToast.toast("Column mappings saved", {
                        title: "Success",
                        variant: "success",
                        autoHideDelay: 2500,
                    });
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        /*
         * Parse date based on this.csv_date_format and return date object
         */
        parseDate(date) {
            const date_format_code = this.csv_date_format_options.find(
                (obj) => obj.id === this.csv_date_format
            )["date_code"];
            const day = date.slice(
                parseInt(date_format_code[0]),
                parseInt(date_format_code[0]) + 2
            );
            const month = date.slice(
                parseInt(date_format_code[1]),
                parseInt(date_format_code[1]) + 2
            );
            const year = date.slice(
                parseInt(date_format_code[2]),
                parseInt(date_format_code[2]) + 4
            );
            return new Date(year, month - 1, day);
        },
        /*
         * Apply column mappings and date format
         */
        parseData() {
            const parsed_data = this.data.map((el) => {
                const date = this.parseDate(el[this.csv_date_header]);
                return {
                    account: this.account.id,
                    date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                    payee: el[this.csv_payee_header],
                    amount: el[this.csv_amount_header],
                    balance: el[this.csv_balance_header],
                    notes: el[this.csv_notes_header],
                };
            });
            return parsed_data;
        },
        /*
         * save settings to server if required then parse data and nav to Classify
         */
        onClickNext() {
            this.fetching = true;
            const data = this.parseData();
            Api.applyRules(data)
                .then((res) => {
                    if (this.settings_changed && this.save_settings === "true") {
                        this.saveAccountSettings();
                    }
                    const res_data = res.data.map((el) => {
                        return { ...el, _showDetails: false };
                    });
                    this.$store.commit("importer/setData", res_data);
                    this.$router.push({ name: "CSVClassify" });
                })
                .catch((err) => {
                    console.log(err.response);
                    this.fetching = false;
                    this.$bvToast.toast("Please ensure you have mapped your columns correctly", {
                        title: "Failed",
                        variant: "danger",
                        autoHideDelay: 2500,
                    });
                });
        },
    },
};
</script>
