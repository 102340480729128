<template>
  <b-container fluid>
    <b-card v-for="plan in plans" :key="plan.id" bg-variant="light" class="mt-2">
      <h5>Saved Plan: {{ plan.name }}</h5>
      <b-row>
        <b-col>
          <span class="text-secondary">Once off events: </span>
          <span>{{ plan.num_once_off_events }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span class="text-secondary">Recurring events: </span>
          <span>{{ plan.num_recurring_events }}</span>
        </b-col>
      </b-row>
      <b-row align-h="end" class="mt-2">
        <b-button size="sm" variant="danger" @click="onClickDelete(plan)">
          <b-icon icon="x-square" />
        </b-button>
        <router-link
          :to="{
            name: 'FIPlanner',
            params: { planType: planType, planId: plan.id },
          }"
        >
          <b-button size="sm" variant="info" class="ml-2">
            <b-icon icon="pencil-square" />
          </b-button>
        </router-link>
      </b-row>
    </b-card>
    <div v-if="plans.length === 0 && !fetching">
      <b-row align-h="center" class="mt-3">
        <b-icon icon="exclamation-octagon" variant="warning" font-scale="3" />
      </b-row>
      <h5 class="text-center mt-2">
        No Saved Plans
      </h5>
    </div>
    <b-row v-if="fetching" align-h="center" class="mt-3">
      <b-spinner small class="text-center" variant="info" />
    </b-row>

    <!--
            Modals 
        -->
    <b-modal ref="delete_modal" hide-footer>
      <DeleteForm
        :plan-type="planType"
        :plan-name="selectedPlan.name"
        :plan-id="selectedPlan.id"
        @planDeleted="planDeleted"
      />
    </b-modal>
  </b-container>
</template>

<script>
import Api from "@/api";
import DeleteForm from "./forms/PlanDeleteForm.vue";

export default {
    components: { DeleteForm },
    props: { planType: String },
    data() {
        return {
            plans: [],
            selectedPlan: { name: "", id: 0 },
        };
    },
    computed: {
        fetching() {
            return this.$store.state.fi_plan.fetching;
        },
    },
    created() {
        this.$store.commit("fi_plan/updateFetching", { fetching: true });
        Api.getPlans(this.planType)
            .then((res) => {
                this.$store.commit("fi_plan/updateFetching", { fetching: false });
                this.plans = res.data;
            })
            .catch((err) => {
                this.$store.commit("fi_plan/updateFetching", { fetching: false });
                console.log(err.response);
            });
    },
    methods: {
        onClickDelete(plan) {
            this.selectedPlan = { ...plan };
            this.$refs["delete_modal"].show();
        },
        /*
         * hide delete modal and remove deleted plan from this.plans
         */
        planDeleted(planId) {
            this.$refs["delete_modal"].hide();
            this.$bvToast.toast("Plan Deleted", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
            this.plans = this.plans.filter((plan) => plan.id !== planId);
        },
    },
};
</script>