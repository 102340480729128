<template>
  <b-container fluid class="pt-5 pt-sm-0">
    <b-row>
      <b-col class="text-center">
        <span class="text-secondary h5">Welcome. It's a great day for FI.</span>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12" lg="6">
        <StatsCards />
      </b-col>
      <b-col cols="12" lg="6" class="mt-4 mt-lg-0">
        <BarChart />
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-4">
      <ChartsHistorical />
    </b-row>
  </b-container>
</template>

<script>
import StatsCards from "@/blocks/investments_stats_cards/Index.vue";
import ChartsHistorical from "@/blocks/investments_charts_historical/Index.vue";
import BarChart from "@/blocks/investments_bar_chart_month/Index.vue";

export default {
    components: { StatsCards, ChartsHistorical, BarChart },
};
</script>