<template>
  <b-container fluid>
    <b-row align-h="center" class="mt-2">
      <h5 class="text-secondary">
        Open an existing query
      </h5>
    </b-row>
    <b-row align-h="center" class="mt-2">
      <b-spinner v-if="fetching" variant="info" small />
      <b-dropdown
        v-if="!fetching && queries.length > 0"
        size="sm"
        variant="info"
        split
        split-variant="outline-info"
      >
        <template v-slot:button-content>
          <span>{{ selectedQueryName }}</span>
        </template>
        <b-dropdown-item-button
          v-for="query in queries"
          :key="query.id"
          @click="onSelectQuery(query.id)"
        >
          {{ query.name }}
        </b-dropdown-item-button>
      </b-dropdown>
      <span v-if="!fetching && queries.length == 0">No saved queries available</span>
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-button
        v-if="!fetching && queries.length > 0"
        size="sm"
        variant="info"
        :disabled="selected_query_id === null"
        @click="onClickOpen"
      >
        Open
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
    data() {
        return {
            fetching: false,
            queries: [],
            selected_query_id: null,
        };
    },
    computed: {
        /*
         * Return name of selected query, else default string
         */
        selectedQueryName() {
            if (this.selected_query_id === null) {
                return "Saved queries";
            }
            return this.queries.find((el) => el.id === this.selected_query_id).name;
        },
    },
    /*
     * Retrieve saved queries from server
     */
    created() {
        this.fetching = true;
        Api.getQueries()
            .then((res) => {
                this.fetching = false;
                this.queries = res.data;
            })
            .catch((err) => {
                this.fetching = false;
                console.log(err.response);
            });
    },
    methods: {
        /*
         * When a query is selected, update this.selected_query_id
         */
        onSelectQuery(query_id) {
            this.selected_query_id = query_id;
        },
        /*
         * When open button is clicked, save selected query to store and emit queryOpened
         */
        onClickOpen() {
            const selected_query = this.queries.find((el) => el.id === this.selected_query_id);
            this.$store.commit("query_tool/updateParams", selected_query);
            this.$emit("queryOpened");
        },
    },
};
</script>