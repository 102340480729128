const splineMixin = {
    data() {
        return {
            splineChartMixinOptions: {
                chart: {
                    zoomType: 'x',
                    type: 'spline',
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                },
                plotOptions: {
                    series: {
                        label: {
                            enabled: false
                        },
                        marker: {
                            enabled: false
                        },
                    }
                },
                tooltip: {
                    shared: true,
                    /*
                     * sort items from highest to lowest
                     */
                    formatter: function (tooltip) {
                        var items = this.points || splat(this),
                            series = items[0].series,
                            s;

                        // sort the values
                        items.sort(function (a, b) {
                            return ((a.y < b.y) ? -1 : ((a.y > b.y) ? 1 : 0));
                        });
                        items.reverse();

                        return tooltip.defaultFormatter.call(this, tooltip);
                    },
                },
                xAxis: {
                    type: "datetime",
                    crosshair: true,
                },
                series: []
            }
        }
    }
}
export default splineMixin;