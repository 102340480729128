import Api from '@/api/';


const state = {
    currency: 'USD',
    stats_default_month: 'current'
}

const getters = {
    currency: state => {
        return state.currency;
    },
}

const actions = {
    async getAppSettings({ commit }) {
        const data = await Api.getAppSettings();
        commit('setAppSettings', data);
    }
}

const mutations = {
    setAppSettings(state, appSettings) {
        Object.assign(state, appSettings);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}