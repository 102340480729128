<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col class="text-center">
        <span class="text-secondary h4">Welcome to Money FI.</span>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col class="text-center">
        <span>
          Money FI provides you with the tools, resources and analytics to reach financial
          independence.
        </span>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col class="text-center">
        <span>First things first, we need to set up your account...</span>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col md="4">
        <StepCard />
      </b-col>
      <b-col md="8" class="mt-3 mt-md-0">
        <Landing v-if="onboarding_step === 1" />
        <Currency v-if="onboarding_step === 2" />
        <Accounts v-if="onboarding_step === 3" />
        <IncomeCategories v-if="onboarding_step === 4" />
        <InvestmentCategories v-if="onboarding_step === 5" />
        <TransactionCategories v-if="onboarding_step === 6" />
        <NextSteps v-if="onboarding_step === 7" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import StepCard from "./StepCard.vue";
import Landing from "./Landing.vue";
import Currency from "./Currency.vue";
import Accounts from "./Accounts.vue";
import IncomeCategories from "./IncomeCategories.vue";
import InvestmentCategories from "./InvestmentCategories.vue";
import TransactionCategories from "./TransactionCategories.vue";
import NextSteps from "./NextSteps.vue";

export default {
    components: {
        Currency,
        Landing,
        StepCard,
        Accounts,
        IncomeCategories,
        InvestmentCategories,
        TransactionCategories,
        NextSteps,
    },
    computed: {
        onboarding_step() {
            return this.$store.state.onboarding.onboarding_step;
        },
    },
};
</script>