import axios from "axios";

export default {
    getBlockAccountStatsMonth: (month) => {
        let url = `/api/tracker/block-account-stats-month/?month=${month}`
        return axios.get(url)
    },
    getBlockGroupedBarChartMonth: (month_start, record_type, group_by, data_field) => {
        let url = `/api/tracker/block-grouped-bar-chart-month/?month_start=${month_start}&record_type=${record_type}&group_by=${group_by}&data_field=${data_field}`
        return axios.get(url)
    },
    getBlockStackedChartHistorical: (timespan, record_type, group_by, data_field) => {
        let url = `/api/tracker/block-stacked-chart-historical/?timespan=${timespan}&record_type=${record_type}&group_by=${group_by}&data_field=${data_field}`
        return axios.get(url)
    },
    getBlockBubbleChartMonth: (month_start) => {
        let url = `/api/tracker/block-bubble-chart-month/?month_start=${month_start}`
        return axios.get(url)
    },
    getBlockStatsCards: (month) => {
        let url = `/api/tracker/block-stats-cards/?month=${month}`
        return axios.get(url)
    },
    getBlockInvestmentsChartsHistorical: (timespan) => {
        let url = `/api/tracker/block-investments-charts-historical/?timespan=${timespan}`
        return axios.get(url)
    },
    getBlockInvestmentsBalanceChartMonth: (group_by, data_field, month_start) => {
        let url = `/api/tracker/block-investments-balance-chart-month/?group_by=${group_by}&data_field=${data_field}&month_start=${month_start}`
        return axios.get(url)
    },
    getBlockInvestmentsStatsCards: (month) => {
        let url = `/api/tracker/block-investments-stats-cards/?month=${month}`
        return axios.get(url)
    },
}