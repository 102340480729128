import Vue from 'vue'

const getDefaultState = () => {
    return {
        tags: {},
        transactionCategories: {},
        incomeCategories: {},
        savingCategories: {},
        data: {}
    }
}

const state = getDefaultState();

const getters = {
    /*
     * List getters
     */
    tagList: (state) => (tagIds) => {
        if (tagIds === undefined) {
            return Object.values(state.tags)
        }
        return tagIds.map(tagId => state.tags[tagId])
    },
    transactionCategoryList: state => {
        return Object.values(state.transactionCategories)
    },
    incomeCategoryList: state => {
        return Object.values(state.incomeCategories)
    },
    savingCategoryList: state => {
        return Object.values(state.savingCategories)
    },
    dataList: state => {
        return Object.values(state.data)
    },
    incomeRecords: state => {
        return Object.values(state.data).filter(
            record => record.record_type === 'income'
        )
    },
    savingRecords: state => {
        return Object.values(state.data).filter(
            record => record.record_type === 'saving'
        )
    },
    transactionRecords: state => {
        return Object.values(state.data).filter(
            record => record.record_type === 'transaction'
        )
    },
    unclassifedRecords: state => {
        return Object.values(state.data).filter(
            record => record.record_type === '' || record.category === ''
        )
    },
    // check if any incomes are negative
    negativeIncomesExist: state => {
        return Object.values(state.data).some(
            record => record.record_type === 'income' && record.amount < 0
        )
    },

    /*
     * Get by Id 
     */
    tag: (state) => (id) => {
        return state.tags[id];
    },
    transactionCategory: (state) => (id) => {
        return state.transactionCategories[id];
    },
    incomeCategory: (state) => (id) => {
        return state.incomeCategories[id];
    },
    savingCategory: (state) => (id) => {
        return state.savingCategories[id];
    },
    record: (state) => (id) => {
        return state.data[id];
    },
}

const mutations = {
    resetState: (state) => {
        Object.assign(state, getDefaultState());
    },

    /*
     * set multiple (overwrite if already exists)
     */
    setData: (state, data) => {
        data.forEach(record => {
            Vue.set(state.data, record.index, record);
        })
    },
    setTags: (state, tags) => {
        tags.forEach(tag => {
            Vue.set(state.tags, tag.id, tag);
        })
    },
    setTransactionCategories: (state, transactionCategories) => {
        transactionCategories.forEach(transactionCategory => {
            Vue.set(state.transactionCategories, transactionCategory.id, transactionCategory);
        })
    },
    setIncomeCategories: (state, incomeCategories) => {
        incomeCategories.forEach(incomeCategory => {
            Vue.set(state.incomeCategories, incomeCategory.id, incomeCategory);
        })
    },
    setSavingCategories: (state, savingCategories) => {
        savingCategories.forEach(savingCategory => {
            Vue.set(state.savingCategories, savingCategory.id, savingCategory);
        })
    },

    /*
     * Update
     */
    updateRecord: (state, record) => {
        Vue.set(state.data, record.index, { ...record, tags: [...record.tags] });
    },
    flipDebitCredit: (state, records) => {
        records.forEach(record => {
            state.data[record.index].amount *= -1;
        })
    },
    createUpdateIncomeCategory: (state, category) => {
        Vue.set(state.incomeCategories, category.id, category);
    },
    createUpdateSavingCategory: (state, category) => {
        Vue.set(state.savingCategories, category.id, category);
    },
    createUpdateTransactionCategory: (state, category) => {
        Vue.set(state.transactionCategories, category.id, category);
    },
    createUpdateTag: (state, tag) => {
        Vue.set(state.tags, tag.id, tag);
    },
    // used to show/hide the notes (ie: detail) section of each table row in ImportTable
    toggleShowRowDetail: (state, record_index) => {
        state.data[record_index]._showDetails = !state.data[record_index]._showDetails;
    },
    /*
     * Remove single record from state
     */
    removeRecord: (state, index) => {
        Vue.delete(state.data, index)
    },

    /*
     * Remove all records of record_type from state
     */
    deleteRecordsByType: (state, record_type) => {
        const records = Object.values(state.data).filter(
            record => record.record_type === record_type
        )
        records.forEach(record => {
            Vue.delete(state.data, record.index)
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}