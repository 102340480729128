<template>
  <datepicker
    :input-class="computedInputClasses"
    :minimum-view="minimumView"
    :format="dateFormatter"
    :value="selectedDate"
    :bootstrap-styling="true"
    :disabled-dates="disabledDates"
    :use-utc="use_utc"
    @selected="dateSelected($event)"
  />
</template>

<style>
.custom-size {
    width: 10ch;
}
.vdp-datepicker__calendar {
    position: fixed !important;
    top: 30%;
    left: 10%;
}
@media (min-width: 900px) {
    .vdp-datepicker__calendar {
        position: fixed !important;
        left: 40%;
    }
}
@media (min-width: 600px) {
    .vdp-datepicker__calendar {
        position: fixed !important;
        left: 30%;
    }
}
</style>

<script>
import Datepicker from "vuejs-datepicker";
import format from "date-fns/format";

export default {
    components: { Datepicker },
    props: {
        selectedDate: { type: String },
        minimumView: { type: String },
        disabledDates: { type: Object },
        inputClasses: { type: String, default: "bg-light" },
    },
    data() {
        return {
            use_utc: true,
        };
    },
    computed: {
        computedInputClasses() {
            return `form-control form-control-sm custom-size text-center ${this.inputClasses}`;
        },
    },
    methods: {
        /*
         * Display selected date as 'MM YYYY' if using month view, else 'dd MMM yyyy'
         */
        dateFormatter(date) {
            if (this.minimumView === "month") {
                return format(date, "MMM yyyy");
            }
            return format(date, "dd MMM yyyy");
        },
        /*
         * emit parent onDateSelected with date arg
         */
        dateSelected(date) {
            this.$emit("onDateSelected", date);
        },
    },
};
</script>