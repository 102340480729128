<template>
  <div>
    <supportAlert link="https://www.moneyfi.app/support/#i-just-signed-up-what-now" />
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <p class="text-secondary">
                Money FI can broadly be split into two components:
              </p>
              <ol>
                <li class="text-secondary">
                  Tracking your spending, income and investments along with
                  analytics to understand your financial habits.
                </li>
                <li class="text-secondary">
                  Creating a plan to reach financial independence along with
                  future life events taken into account.
                </li>
              </ol>
              <p class="text-secondary">
                By entering your Income, Investment and Transaction (spending) data
                each month, Money FI gives you deep insight into your financial
                position while being able to create an accurate FI Plan.
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-alert variant="success" show class="text-secondary mt-3">
      Your initial account setup simply ensures you have data to start working with. You can
      always change or add to this data at a later point.
    </b-alert>
    <b-button size="sm" variant="info" @click="onClickNext">
      Start setting up my account
    </b-button>
  </div>
</template>

<script>
import supportAlert from "@/components/support_alert/Index.vue";
export default {
    components: { supportAlert },
    methods: {
        onClickNext() {
            this.$store.commit("onboarding/incrementOnboardingStep");
        },
    },
};
</script>