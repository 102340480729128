<template>
  <b-container fluid>
    <b-row>
      <b-col>Are you sure you want to delete this event?</b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <h4 class="text-secondary">
          {{ eventName }}
        </h4>
      </b-col>
    </b-row>
    <hr>
    <b-row align-h="end" class="mt-1">
      <b-button size="sm" variant="danger" @click="deleteEvent">
        Delete Event
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
export default {
    props: { eventType: String, eventName: String },
    methods: {
        deleteEvent() {
            this.$store.commit("fi_plan/deleteEvent", {
                eventType: this.eventType,
                eventName: this.eventName,
            });
            this.$emit("eventDeleted");
        },
    },
};
</script>