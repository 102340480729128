import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import appSettings from "./modules/appSettings";
import importer from "@/components/csv_import/store.js";
import record_creator from "@/components/record_creator/store.js";
import fi_plan from "@/components/fi_plan/store.js";
import records from "@/components/records/store.js";
import query_tool from "@/components/query_tool/store.js";
import onboarding from "@/components/onboarding/store.js";
import signup from "@/components/signup/store.js";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    importer,
    appSettings,
    record_creator,
    fi_plan,
    records,
    query_tool,
    onboarding,
    signup,
  },
  strict: debug,
});
