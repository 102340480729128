<template>
  <b-container fluid>
    <b-button
      variant="info"
      class="back-button"
      :to="{ name: routerDestination, params: { recordType: recordType } }"
    >
      <b-icon icon="arrow90deg-left" font-scale="1.3" />
    </b-button>
    <CreateForm :record-type="recordType" />
    <Records :record-type="recordType" />
  </b-container>
</template>

<script>
import Api from "@/api";
import CreateForm from "./CreateForm.vue";
import Records from "./Records.vue";

export default {
    components: { CreateForm, Records },
    props: { recordType: { type: String, default: "transaction" } },
    computed: {
        /*
         * Used for the back button to determine where to return to
         */
        routerDestination() {
            if (this.recordType === "saving") {
                return "InvestmentsManagement";
            } else if (this.recordType === "transaction") {
                return "TransactionManagement";
            }
            return "IncomeManagement";
        },
    },
    /*
     * Reset store state, download accounts, tags and categories. Update store state
     * with response.
     */
    created() {
        this.$store.commit("record_creator/resetState");
        Api.getAccounts()
            .then((res) => {
                this.$store.commit("record_creator/initAccounts", res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getTags()
            .then((res) => {
                this.$store.commit("record_creator/initTags", res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
        let categoriesPromise = null;
        switch (this.recordType) {
            case "transaction":
                categoriesPromise = Api.getTransactionCategories();
                break;
            case "income":
                categoriesPromise = Api.getIncomeCategories();
                break;
            case "saving":
                categoriesPromise = Api.getSavingCategories();
                break;
        }
        categoriesPromise
            .then((res) => {
                this.$store.commit("record_creator/initCategories", res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
};
</script>