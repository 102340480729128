<template>
  <b-container fluid>
    <b-row align-h="center" class="mt-2">
      <h5 class="text-secondary text-center">
        Are you sure you want to delete this account?
      </h5>
    </b-row>
    <b-row align-h="center" class="mt-2">
      <span>{{ account.institution }}: {{ account.name }}</span>
    </b-row>
    <b-alert
      variant="danger"
      :show="numIncomeRecords > 0 || numSavingRecords > 0 || numTransactionRecords > 0"
      class="mt-3"
    >
      <h4 class="text-danger">
        Warning
      </h4>
      <strong class="text-danger">You have data linked to this account</strong>
      <b-row class="mt-3">
        <b-col>
          <span>{{ numIncomeRecords }} </span>
          <span class="text-secondary">Income records</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span>{{ numSavingRecords }} </span>
          <span class="text-secondary">Investment records</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span>{{ numTransactionRecords }} </span>
          <span class="text-secondary">Transaction records</span>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          By deleting this account all linked income, investment and transaction data will
          also be deleted.
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col> This action cannot be undone. </b-col>
      </b-row>
    </b-alert>
    <hr>
    <b-row align-h="end">
      <b-spinner v-if="fetching" small variant="info" />
      <b-button v-else size="sm" variant="danger" @click="deleteAccount">
        <span>Delete</span>
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
    props: { account: Object },
    data() {
        return {
            fetching: false,
            numIncomeRecords: 0,
            numTransactionRecords: 0,
            numSavingRecords: 0,
        };
    },
    created() {
        this.fetching = true;
        Api.getRecordCountsForAccount(this.account.id)
            .then((res) => {
                this.fetching = false;
                this.numIncomeRecords = res.data.num_income_records;
                this.numSavingRecords = res.data.num_saving_records;
                this.numTransactionRecords = res.data.num_transaction_records;
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
    methods: {
        /*
         * Delete account on server then emit accountDeleted in parent
         */
        deleteAccount() {
            this.fetching = true;
            Api.deleteAccount(this.account.id)
                .then((res) => {
                    this.fetching = false;
                    this.$emit("accountDeleted");
                })
                .catch((err) => {
                    this.fetching = false;
                    this.$bvToast.toast("Unable to delete Account.", {
                        title: "Account not deleted",
                        variant: "danger",
                        autoHideDelay: 2500,
                    });
                    console.log(err.response);
                });
        },
    },
};
</script>