const stackedColumnMixin = {
    data() {
        return {
            stackedColumnChartMixinOptions: {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    type: 'datetime',
                    minPadding: 0.05, // Add space on left of chart so columns don't get cut off
                    maxPadding: 0.05 // Add space on right of chart so columns don't get cut off
                },
                yAxis: {
                    title: {
                        enabled: false
                    },
                },
                tooltip: {
                    shared: true,
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                    }
                },
                series: []
            }
        }
    }
}
export default stackedColumnMixin;