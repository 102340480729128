<template>
  <b-form-checkbox v-model="excludeFromComparison" switch>
    Generate comparison
  </b-form-checkbox>
</template>

<script>
export default {
    props: { eventType: String, eventName: String },
    computed: {
        /*
         * Get and set (toggle) event.excludeFromComparison in the store
         */
        excludeFromComparison: {
            get() {
                const stored_event = this.$store.getters["fi_plan/getEvent"]({
                    eventType: this.eventType,
                    eventName: this.eventName,
                });
                return stored_event.exclude_from_comparison;
            },
            set(value) {
                return this.$store.commit("fi_plan/toggleExcludeFromComparison", {
                    eventType: this.eventType,
                    eventName: this.eventName,
                });
            },
        },
    },
};
</script>