<template>
  <b-container fluid>
    <!-- 
            Record Type
        -->
    <b-row>
      <b-col cols="6">
        <b-form-select
          v-model="params.record_type"
          :options="record_type_options"
          size="sm"
          @change="onChangeRecordType"
        />
      </b-col>
    </b-row>
    <!-- 
            Data Field
        -->
    <b-row v-if="params.record_type == 'saving'" class="mt-3">
      <b-col cols="6">
        <b-form-select
          v-model="params.data_field"
          :options="data_field_options"
          size="sm"
        />
      </b-col>
    </b-row>
    <!-- 
            include_excluded_records
        -->
    <b-row class="mt-3">
      <b-col>
        <b-form-checkbox v-model="params.include_excluded_records" switch>
          Include <i class="text-secondary">Exclude from totals</i> records
        </b-form-checkbox>
      </b-col>
    </b-row>
    <!-- 
            Accounts
        -->
    <b-row class="mt-3">
      <b-col>
        <b-form-checkbox v-model="accounts_checkbox" switch>
          Filter by Account
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="accounts_checkbox" class="mt-2">
      <b-col>
        <multiselect
          v-model="params.accounts"
          placeholder="Select accounts"
          label="full_name"
          track-by="id"
          :options="accounts"
          :multiple="true"
          :close-on-select="false"
          hide-selected
        />
      </b-col>
    </b-row>
    <!-- 
            Categories
        -->
    <b-row class="mt-3">
      <b-col>
        <b-form-checkbox v-model="categories_checkbox" switch>
          Filter by Category
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="categories_checkbox" class="mt-2">
      <b-col>
        <multiselect
          v-model="params.categories"
          placeholder="Select categories"
          label="name"
          track-by="id"
          :options="categories"
          :multiple="true"
          :close-on-select="false"
          hide-selected
        />
      </b-col>
    </b-row>
    <!-- 
            Payee 
        -->
    <b-row class="mt-3">
      <b-col>
        <b-form-checkbox v-model="payee_checkbox" switch>
          Filter by Payee
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="payee_checkbox" class="mt-2">
      <b-col>
        <b-input-group size="sm">
          <template v-slot:prepend>
            <b-dropdown size="sm" variant="info">
              <template v-slot:button-content>
                <span>{{ filter_type_label("payee") }}</span>
              </template>
              <b-dropdown-item-button
                v-for="option in filter_type_options"
                :key="option.key"
                @click="onSelectFilterType(option.key, 'payee')"
              >
                {{ option.label }}
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
          <b-form-input v-model="params.payee" size="sm" />
        </b-input-group>
      </b-col>
    </b-row>
    <!-- 
            Tags
        -->
    <b-row class="mt-3">
      <b-col>
        <b-form-checkbox v-model="tags_checkbox" switch>
          Filter by Tag
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="tags_checkbox" class="mt-2">
      <b-col>
        <multiselect
          v-model="params.tags"
          placeholder="Select tags"
          label="name"
          track-by="id"
          :options="tags"
          :multiple="true"
          :close-on-select="false"
          hide-selected
        />
      </b-col>
    </b-row>
    <!-- 
            Notes 
        -->
    <b-row class="mt-3">
      <b-col>
        <b-form-checkbox v-model="notes_checkbox" switch>
          Filter by Notes
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="notes_checkbox" class="mt-2">
      <b-col>
        <b-input-group size="sm">
          <template v-slot:prepend>
            <b-dropdown size="sm" variant="info">
              <template v-slot:button-content>
                <span>{{ filter_type_label("notes") }}</span>
              </template>
              <b-dropdown-item-button
                v-for="option in filter_type_options"
                :key="option.key"
                @click="onSelectFilterType(option.key, 'notes')"
              >
                {{ option.label }}
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
          <b-form-input v-model="params.notes" size="sm" />
        </b-input-group>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col class="text-right">
        <b-button size="sm" variant="info" class="ml-2" @click="onClickApply">
          Apply
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    components: { Multiselect },
    data() {
        return {
            params: {
                record_type: "transaction",
                data_field: "amount",
                include_excluded_records: false,
                accounts: [],
                categories: [],
                tags: [],
                payee: "",
                payee_filter_type: "exact",
                notes: "",
                notes_filter_type: "exact",
            },
            accounts_checkbox: false,
            categories_checkbox: false,
            tags_checkbox: false,
            payee_checkbox: false,
            notes_checkbox: false,
            record_type_options: [
                { value: "transaction", text: "Transactions" },
                { value: "saving", text: "Investments" },
                { value: "income", text: "Income" },
            ],
            data_field_options: [
                { value: "amount", text: "Contribution" },
                { value: "dividends", text: "Dividends" },
                { value: "balance", text: "Balance" },
            ],
            filter_type_options: [
                { key: "", label: "Exact match" },
                { key: "__icontains", label: "Contains" },
                { key: "__startswith", label: "Starts with" },
                { key: "__endswith", label: "Ends with" },
            ],
        };
    },
    computed: {
        accounts() {
            return this.$store.getters["query_tool/accountList"];
        },
        categories() {
            return this.$store.getters["query_tool/categoryList"](this.params.record_type);
        },
        tags() {
            return this.$store.getters["query_tool/tagList"];
        },
    },
    /*
     * Copy store params to local data so that all changes are done locally until
     * user clicks Apply. Toggle checkboxes if store params have data.
     */
    created() {
        const store_data = {
            record_type: this.$store.state.query_tool.params.record_type,
            data_field: this.$store.state.query_tool.params.data_field,
            include_excluded_records: this.$store.state.query_tool.params.include_excluded_records,
            accounts: this.$store.state.query_tool.params.accounts,
            categories: this.$store.state.query_tool.params.categories,
            tags: this.$store.state.query_tool.params.tags,
            payee: this.$store.state.query_tool.params.payee,
            payee_filter_type: this.$store.state.query_tool.params.payee_filter_type,
            notes: this.$store.state.query_tool.params.notes,
            notes_filter_type: this.$store.state.query_tool.params.notes_filter_type,
        };
        this.params = { ...store_data };
        if (store_data.accounts.length) {
            this.accounts_checkbox = true;
        }
        if (store_data.categories.length) {
            this.categories_checkbox = true;
        }
        if (store_data.tags.length) {
            this.tags_checkbox = true;
        }
        if (store_data.payee.length) {
            this.payee_checkbox = true;
        }
        if (store_data.notes.length) {
            this.notes_checkbox = true;
        }
    },
    methods: {
        /*
         * return text for filter type button of param based on selected filter type
         */
        filter_type_label(param) {
            const option = this.filter_type_options.find(
                (obj) => obj.key === this.params[`${param}_filter_type`]
            );
            return option.label;
        },
        /*
         * Update filter_type for param to key
         */
        onSelectFilterType(key, param) {
            this.params[`${param}_filter_type`] = key;
        },
        /*
         * When record type is changed, clear any selected categories.
         * Reset data_field if record_type !== saving
         */
        onChangeRecordType() {
            this.params.categories = [];
            if (this.params.record_type !== "saving") {
                this.params.data_field = "amount";
            }
        },
        /*
         * Update store from local params and emit filterParamsUpdated in parent
         */
        onClickApply() {
            const data = {
                record_type: this.params.record_type,
                data_field: this.params.data_field,
                include_excluded_records: this.params.include_excluded_records,
                accounts: this.accounts_checkbox ? this.params.accounts : [],
                categories: this.categories_checkbox ? this.params.categories : [],
                tags: this.tags_checkbox ? this.params.tags : [],
                payee: this.payee_checkbox ? this.params.payee : "",
                payee_filter_type: this.params.payee_filter_type,
                notes: this.notes_checkbox ? this.params.notes : "",
                notes_filter_type: this.params.notes_filter_type,
            };
            this.$store.commit("query_tool/updateParams", data);
            this.$emit("filterParamsUpdated");
        },
    },
};
</script>