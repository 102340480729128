const bar_current_and_average_mixin = {
    data() {
        return {
            bar_current_and_average_options: {
                chart: {
                    type: 'bar'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: []
                },
                legend: {
                    shadow: false
                },
                tooltip: {
                    shared: true
                },
                plotOptions: {
                    bar: {
                        grouping: false,
                        shadow: false,
                        groupPadding: 0,
                        borderWidth: 0,
                        pointPadding: 0
                    }
                },
                series: []
            }
        }
    }
}

export default bar_current_and_average_mixin;