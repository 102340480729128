<template>
  <b-container fluid>
    <b-card no-body>
      <b-tabs card small>
        <template v-slot:tabs-end>
          <b-dropdown
            size="sm"
            variant="info"
            class="ml-2"
            no-caret
            dropleft
          >
            <template v-slot:button-content>
              <b-icon icon="calendar" />
              <span> {{ timespanString }}</span>
              <b-icon icon="caret-down" class="float-right" />
            </template>
            <b-dropdown-item-button @click="changeTimespan(3)">
              3 Months
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeTimespan(6)">
              6 Months
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeTimespan(12)">
              12 Months
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeTimespan(36)">
              3 Years
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeTimespan(60)">
              5 Years
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
        <b-tab class="p-0" title="Portfolio">
          <h5 class="text-center text-secondary mt-2">
            Portfolio Balance vs Profit
          </h5>
          <b-row v-if="displayInsufficientDataWarning" align-h="center" align-v="center">
            <b-col cols="10" md="8" lg="6">
              <b-alert variant="danger" show size="sm" class="text-center mt-2">
                <span class="text-danger">Insufficient data. </span>
                <span class="text-secondary">
                  At least 2 months of consecutive data is required.
                </span>
              </b-alert>
            </b-col>
          </b-row>
          <highcharts
            ref="portfolioChartRef"
            :options="timeSeries2AxisChartMixinOptions"
            :update-args="timeSeries2AxisChartMixinUpdateArgs"
          />
        </b-tab>
        <b-tab class="p-0" title="Profit">
          <h5 class="text-center text-secondary mt-2">
            Profit by Category
          </h5>
          <b-row v-if="displayInsufficientDataWarning" align-h="center" align-v="center">
            <b-col cols="10" md="8" lg="6">
              <b-alert variant="danger" show size="sm" class="text-center mt-2">
                <span class="text-danger">Insufficient data. </span>
                <span class="text-secondary">
                  At least 2 months of consecutive data is required.
                </span>
              </b-alert>
            </b-col>
          </b-row>
          <b-row v-else align-h="center" class="mb-1">
            <span class="text-secondary">
              Categories with less than 2 months of consecutive data are not shown.
            </span>
          </b-row>
          <highcharts
            ref="categoryProfitChartRef"
            :options="timeSeriesChartMixinOptions"
            :update-args="timeSeriesChartMixinUpdateArgs"
          />
        </b-tab>
        <b-tab class="p-0" title="Profit %">
          <h5 class="text-center text-secondary mt-2">
            Percentage Profit by Category
          </h5>
          <b-row v-if="displayInsufficientDataWarning" align-h="center" align-v="center">
            <b-col cols="10" md="8" lg="6">
              <b-alert variant="danger" show size="sm" class="text-center mt-2">
                <span class="text-danger">Insufficient data. </span>
                <span class="text-secondary">
                  At least 2 months of consecutive data is required.
                </span>
              </b-alert>
            </b-col>
          </b-row>
          <b-row v-else align-h="center" class="mb-1">
            <span class="text-secondary">
              Categories with less than 2 months of consecutive data are not shown.
            </span>
          </b-row>
          <highcharts
            ref="categoryPctChartRef"
            :options="timeSeriesChartMixinOptions"
            :update-args="timeSeriesChartMixinUpdateArgs"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-container>
</template>

<script>
import Api from "@/api";
import timeSeries2AxisChartMixin from "@/highcharts/time_series_2_axis_chart_mixin.js";
import timeSeriesChartMixin from "@/highcharts/time_series_chart_mixin.js";

export default {
    mixins: [timeSeries2AxisChartMixin, timeSeriesChartMixin],
    data() {
        return {
            fetching: false,
            timespan: 12,
            loadingSpinner: '<div class="spinner-border text-info"></div>',
            category_profit_chart_data: [],
            category_pct_return_chart_data: [],
            portfolio_chart_data: [{ data: [] }],
        };
    },
    computed: {
        /*
         * Return timespan string based on selected timespan for display in dropdown
         */
        timespanString() {
            const options = {
                3: "3 Months",
                6: "6 Months",
                12: "12 Months",
                36: "3 Years",
                60: "5 Years",
            };
            return options[this.timespan];
        },
        /*
         * Return true if not fetching and either portfolio_chart_data has no data or
         * portfolio_chart_data first series has less than 2 months of data.
         */
        displayInsufficientDataWarning() {
            return (
                !this.fetching &&
                (this.portfolio_chart_data.length == 0 ||
                    this.portfolio_chart_data[0].data.length < 2)
            );
        },
    },
    /*
     * Fetch data with initial params once component has been mounted
     */
    mounted: function () {
        this.fetchData();
    },
    methods: {
        changeTimespan(timespan) {
            this.timespan = timespan;
            this.fetchData();
        },
        /*
         * remove existing chart data, create new series for each series in seriesData,
         * set chart title
         */
        populateChartData(chartInstance, seriesData) {
            chartInstance.hideLoading();
            while (chartInstance.series.length > 0) {
                chartInstance.series[0].remove(false);
            }
            seriesData.forEach((data) => {
                chartInstance.addSeries(data, false);
            });
            chartInstance.redraw();
        },
        /*
         * show chart loading spinners, fetch server data, update charts with response.
         */
        fetchData() {
            this.fetching = true;
            let portfolioChartInstance = this.$refs.portfolioChartRef.chart;
            portfolioChartInstance.showLoading(this.loadingSpinner);
            let categoryProfitChartInstance = this.$refs.categoryProfitChartRef.chart;
            categoryProfitChartInstance.showLoading(this.loadingSpinner);
            let categoryPctChartInstance = this.$refs.categoryPctChartRef.chart;
            categoryPctChartInstance.showLoading(this.loadingSpinner);
            Api.getBlockInvestmentsChartsHistorical(this.timespan)
                .then((res) => {
                    this.fetching = false;
                    portfolioChartInstance.yAxis[0].setTitle({
                        text: "Balance & Cumulative Contribution",
                    });
                    portfolioChartInstance.yAxis[1].setTitle({
                        text: "Profit",
                    });
                    this.portfolio_chart_data = res.data.portfolio_chart_data;
                    this.populateChartData(portfolioChartInstance, res.data.portfolio_chart_data);

                    this.category_profit_chart_data = res.data.category_profit_chart_data;
                    this.populateChartData(
                        categoryProfitChartInstance,
                        res.data.category_profit_chart_data
                    );

                    this.category_pct_return_chart_data = res.data.category_pct_return_chart_data;
                    this.populateChartData(
                        categoryPctChartInstance,
                        res.data.category_pct_return_chart_data
                    );
                })
                .catch((err) => {
                    this.fetching = false;
                    console.log(err.response);
                });
        },
    },
};
</script>