<template>
  <b-container fluid>
    <b-row align-h="center">
      <b-col class="text-center">
        <h4>Tags</h4>
        <p class="text-secondary">
          Tags can be applied to transactions, investments or income records.
        </p>
        <p class="text-secondary">
          Tags allow you to group and query related items, even if they have different
          categories.
        </p>
      </b-col>
    </b-row>
    <div v-if="fetching" class="text-center mt-4">
      <b-spinner small label="Small Spinner" variant="info" />
    </div>
    <b-row v-if="!fetching" align-h="center" class="mt-3">
      <b-form inline>
        <b-col>
          <b-button size="sm" variant="info" @click="displayCreateModal()">
            Create
          </b-button>
        </b-col>
        <b-col>
          <b-form-input
            v-model="filter_by"
            size="sm"
            type="search"
            placeholder="Filter..."
            class="ml-2"
          />
        </b-col>
      </b-form>
    </b-row>
    <b-row v-if="!fetching && tags.length === 0" class="mt-5" align-h="center">
      <b-col sm="10" md="6" lg="4" class="text-center">
        <b-alert variant="info" show>
          No data available.
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="!fetching" align-h="center" class="mt-3 mb-5">
      <b-col
        v-for="tag in filteredTags()"
        :key="tag.id"
        cols="12"
        sm="10"
        md="8"
      >
        <b-card class="mt-2">
          <b-row>
            <b-col cols="9">
              {{ tag.name }}
            </b-col>
            <b-col cols="3">
              <b-row align-h="end">
                <b-button
                  size="sm"
                  variant="transparent"
                  @click="displayUpdateModal(tag)"
                >
                  <b-icon
                    icon="pencil-square"
                    font-scale="1.3"
                    variant="info"
                  />
                </b-button>
                <b-button
                  size="sm"
                  variant="transparent"
                  @click="displayDeleteModal(tag)"
                >
                  <b-icon
                    icon="x-square"
                    font-scale="1.3"
                    variant="danger"
                  />
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!--
            Modals 
        -->
    <b-modal ref="delete_modal" size="sm" hide-footer static>
      <DeleteForm :tag="selected_tag" @tagDeleted="tagDeleted" />
    </b-modal>
    <b-modal ref="update_modal" hide-footer static>
      <UpdateForm :tag="selected_tag" @tagUpdated="tagUpdated" />
    </b-modal>
    <b-modal ref="create_modal" hide-footer>
      <CreateForm @tagCreated="tagCreated" />
    </b-modal>
  </b-container>
</template>

<script>
import Api from "@/api";
import DeleteForm from "./DeleteForm.vue";
import UpdateForm from "./UpdateForm.vue";
import CreateForm from "./CreateForm.vue";

export default {
    components: { DeleteForm, UpdateForm, CreateForm },
    data() {
        return {
            fetching: false,
            tags: [],
            selected_tag: {},
            filter_by: "",
        };
    },
    mounted: function () {
        this.fetching = true;
        Api.getTags()
            .then((res) => {
                this.fetching = false;
                this.tags = res.data;
            })
            .catch((err) => {
                this.fetching = false;
                console.log(err.response);
            });
    },
    methods: {
        filteredTags() {
            if (this.filter_by === "") {
                return this.tags;
            } else {
                return this.tags.filter((obj) =>
                    obj.name.toLowerCase().includes(this.filter_by.toLowerCase())
                );
            }
        },
        /*
         * set the selected_tag and display the delete modal
         */
        displayDeleteModal(tag) {
            this.selected_tag = tag;
            this.$refs["delete_modal"].show();
        },
        /*
         * emitted via DeleteForm when tag deleted on server
         */
        tagDeleted() {
            this.tags.splice(this.tags.indexOf(this.selected_tag), 1);
            this.$refs["delete_modal"].hide();
        },
        /*
         * set the selected_tag and display the update modal
         */
        displayUpdateModal(tag) {
            this.selected_tag = tag;
            this.$refs["update_modal"].show();
        },
        /*
         * emitted via Form when tag updated on server
         */
        tagUpdated(tag) {
            const index = this.tags.indexOf(this.selected_tag);
            this.$set(this.tags, index, { ...tag });
            this.$refs["update_modal"].hide();
        },
        /*
         * Display the create modal
         */
        displayCreateModal() {
            this.$refs["create_modal"].show();
        },
        /*
         * emitted via Form when tag created on server
         */
        tagCreated(tag) {
            this.tags.unshift(tag);
            this.$refs["create_modal"].hide();
            this.$bvToast.toast("Tag Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
    },
};
</script>