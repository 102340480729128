<template>
  <div>
    <b-row align-h="end">
      <b-col cols="4" md="2">
        <DatePicker
          :selected-date="selected_month"
          :minimum-view="'month'"
          :disabled-dates="disabled_dates"
          input-classes="bg-info text-light"
          @onDateSelected="onDateSelected"
        />
      </b-col>
    </b-row>
    <b-row v-if="!fetching && balance === ''" class="mt-1">
      <b-col>
        <b-card header-class="bg-transparent">
          <template v-slot:header>
            <span class="text-secondary">Overview stats for {{ formatted_date }}</span>
          </template>
          <b-alert variant="danger" show>
            <span class="text-danger">Insufficient data: </span>
            <span class="text-secondary">
              To display this widget for {{ formatted_date }} you need Income,
              Investment and Transaction data for {{ formatted_date }}.
            </span>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <div v-else>
      <b-row class="mt-1">
        <b-col cols="12" sm="6">
          <b-card
            class="text-center"
            style="position: relative; height: 200px; overflow-y: auto"
          >
            <div v-if="fetching" class="text-center">
              <b-spinner small label="Small Spinner" variant="info" />
            </div>
            <div v-else>
              <span class="text-secondary">
                {{ selected_month_string }} Investment Balance
              </span>
              <h5>{{ formatCurrency(balance, currency) }}</h5>
              <hr>
              <span class="text-secondary">Your balance has grown by</span>
              <h5>{{ formatCurrency(balance_growth, currency) }}</h5>
              <span class="text-secondary">in the last 12 months.</span>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" sm="6" class="mt-4 mt-sm-0">
          <b-card
            class="text-center"
            style="position: relative; height: 200px; overflow-y: auto"
          >
            <div v-if="fetching" class="text-center">
              <b-spinner small label="Small Spinner" variant="info" />
            </div>
            <div v-else>
              <span class="text-secondary">
                Invested in {{ selected_month_string }}
              </span>
              <h5>{{ formatCurrency(invested_in, currency) }}</h5>
              <hr>
              <span class="text-secondary">Monthly median</span>
              <h5>{{ formatCurrency(invested_in_median, currency) }}</h5>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" sm="6" class="mt-4">
          <b-card
            class="text-center"
            style="position: relative; height: 200px; overflow-y: auto"
          >
            <div v-if="fetching" class="text-center">
              <b-spinner small label="Small Spinner" variant="info" />
            </div>
            <div v-else>
              <span class="text-secondary">
                {{ selected_month_string }} savings rate
              </span>
              <h5>{{ savings_rate }}%</h5>
              <hr>
              <span class="text-secondary">Monthly median</span>
              <h5>{{ savings_rate_median }}%</h5>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" sm="6" class="mt-4">
          <b-card
            class="text-center"
            style="position: relative; height: 200px; overflow-y: auto"
          >
            <div v-if="fetching" class="text-center">
              <b-spinner small label="Small Spinner" variant="info" />
            </div>
            <div v-else>
              <span class="text-secondary">
                {{ selected_month_string }} saving-to-expense rate
              </span>
              <h5>{{ se_rate }}%</h5>
              <hr>
              <span class="text-secondary">Monthly median</span>
              <h5>{{ se_rate_median }}%</h5>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import format from "date-fns/format";
import Api from "@/api";
import { formatCurrency } from "@/utils/general.js";
import DatePicker from "@/components/date_picker/Index.vue";

export default {
    components: { DatePicker },
    data() {
        return {
            fetching: false,
            selected_month: "",
            selected_month_string: "",
            balance: "",
            balance_growth: "",
            invested_in: "",
            invested_in_median: "",
            savings_rate: "",
            savings_rate_median: "",
            se_rate: "",
            se_rate_median: "",
            disabled_dates: {
                from: new Date(),
            },
        };
    },
    computed: {
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
        formatted_date() {
            if (this.selected_month !== "") {
                return format(new Date(this.selected_month), "MMM yyyy");
            }
            return "";
        },
    },
    mounted: function () {
        this.fetchData();
    },
    methods: {
        formatCurrency,
        onDateSelected(date) {
            this.selected_month = date.toISOString().substr(0, 10);
            this.selected_month_string = date.toLocaleString("default", { month: "long" });
            this.fetchData();
        },
        fetchData() {
            this.fetching = true;
            Api.getBlockInvestmentsStatsCards(this.selected_month)
                .then((res) => {
                    this.fetching = false;
                    this.balance = res.data.balance;
                    this.balance_growth = res.data.balance_growth;
                    this.invested_in = res.data.invested_in;
                    this.invested_in_median = res.data.invested_in_median;
                    this.savings_rate = res.data.savings_rate;
                    this.savings_rate_median = res.data.savings_rate_median;
                    this.se_rate = res.data.se_rate;
                    this.se_rate_median = res.data.se_rate_median;
                    this.selected_month = res.data.month;
                    this.selected_month_string = new Date(res.data.month).toLocaleString(
                        "default",
                        { month: "long" }
                    );
                })
                .catch((err) => {
                    this.fetching = false;
                    console.log(err.response);
                });
        },
    },
};
</script>