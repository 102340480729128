<template>
  <b-card
    header-bg-variant="transparent"
    header-border-variant="transparent"
    border-variant="info"
    body-class="p-0"
  >
    <template v-slot:header>
      <b-row>
        <b-col cols="12" sm="6" class="mt-1">
          <b-dropdown size="sm" variant="info" no-caret class="w-100">
            <template v-slot:button-content>
              <span>Records: </span>
              <span>{{ recordTypeString }}</span>
              <b-icon icon="caret-down" class="float-right" />
            </template>
            <b-dropdown-item-button @click="changeRecordType('income')">
              Income
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeRecordType('transaction')">
              Transactions
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeRecordType('saving')">
              Investments
            </b-dropdown-item-button>
          </b-dropdown>
        </b-col>
        <b-col cols="12" sm="6" class="mt-1">
          <b-dropdown
            size="sm"
            variant="info"
            no-caret
            dropleft
            class="w-75"
          >
            <template v-slot:button-content>
              <b-icon icon="calendar" />
              <span> {{ timespanString }}</span>
              <b-icon icon="caret-down" class="float-right" />
            </template>
            <b-dropdown-item-button @click="changeTimespan(3)">
              3 Months
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeTimespan(6)">
              6 Months
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeTimespan(12)">
              12 Months
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeTimespan(36)">
              3 Years
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeTimespan(60)">
              5 Years
            </b-dropdown-item-button>
          </b-dropdown>
          <b-dropdown
            size="sm"
            variant="info"
            no-caret
            dropleft
            class="ml-2"
          >
            <template v-slot:button-content>
              <b-icon
                v-if="selected_chart_type == 'spline_chart'"
                icon="graph-up"
              />
              <b-icon
                v-else-if="selected_chart_type == 'stacked_column_chart'"
                icon="bar-chart"
              />
              <b-icon v-else icon="soundwave" />
            </template>
            <b-dropdown-item-button @click="populateChartData('stream_chart')">
              <b-icon
                icon="soundwave"
                variant="info"
                class="mr-1"
                rotate="90"
              />
              Stream
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="populateChartData('stacked_column_chart')">
              <b-icon icon="bar-chart" variant="info" class="mr-1" />
              Stacked Column
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="populateChartData('spline_chart')">
              <b-icon icon="graph-up" variant="info" class="mr-1" />
              Spline
            </b-dropdown-item-button>
          </b-dropdown>
        </b-col>
        <b-col cols="12" sm="6" class="mt-1">
          <b-dropdown size="sm" variant="info" no-caret class="w-100">
            <template v-slot:button-content>
              <span>Group By: </span>
              <span>
                {{ group_by.charAt(0).toUpperCase() + group_by.slice(1) }}
              </span>
              <b-icon icon="caret-down" class="float-right" />
            </template>
            <b-dropdown-item-button @click="changeGroupBy('account')">
              Account
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeGroupBy('category')">
              Category
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeGroupBy('none')">
              None
            </b-dropdown-item-button>
          </b-dropdown>
        </b-col>
        <b-col v-if="record_type === 'saving'" cols="12" sm="6" class="mt-1">
          <b-dropdown size="sm" variant="info" no-caret class="w-100">
            <template v-slot:button-content>
              <span>Data: </span>
              <span>{{ dataFieldString }} </span>
              <b-icon icon="caret-down" class="float-right" />
            </template>
            <b-dropdown-item-button @click="changeDataField('amount')">
              Contribution
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeDataField('balance')">
              Balance
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeDataField('dividends')">
              Dividends
            </b-dropdown-item-button>
          </b-dropdown>
        </b-col>
      </b-row>
    </template>
    <b-row v-if="!fetching && chart_series_data.length === 0" align-h="center" align-v="center">
      <b-col cols="10" sm="8" md="6" lg="4">
        <b-alert variant="danger" show size="sm" class="text-center mt-2">
          <span class="text-danger">Insufficient data</span>
        </b-alert>
      </b-col>
    </b-row>
    <div :class="displayClass('stream_chart')">
      <highcharts
        ref="stream_chart"
        :options="stream_options"
        :update-args="chart_update_args"
      />
    </div>
    <div :class="displayClass('stacked_column_chart')">
      <highcharts
        ref="stacked_column_chart"
        :options="stacked_column_options"
        :update-args="chart_update_args"
      />
    </div>
    <div :class="displayClass('spline_chart')">
      <highcharts
        ref="spline_chart"
        :options="spline_options"
        :update-args="chart_update_args"
      />
    </div>
  </b-card>
</template>

<script>
import Api from "@/api";
import stream_mixin from "./chart_mixins/stream.js";
import stacked_column_mixin from "./chart_mixins/stacked_column.js";
import spline_mixin from "./chart_mixins/spline.js";

export default {
    mixins: [stream_mixin, stacked_column_mixin, spline_mixin],
    data() {
        return {
            fetching: false,
            group_by: "account",
            record_type: "transaction",
            timespan: 12,
            data_field: "amount",
            selected_chart_type: "stacked_column_chart",
            chart_update_args: [true, true, { duration: 3000 }],
            categories: [],
            chart_series_data: [],
        };
    },
    computed: {
        /*
         * Return timespan string based on selected timespan for display in dropdown
         */
        timespanString() {
            const options = {
                3: "3 Months",
                6: "6 Months",
                12: "12 Months",
                36: "3 Years",
                60: "5 Years",
            };
            return options[this.timespan];
        },
        /*
         * Return record type string based on selected record_type for display in dropdown
         */
        recordTypeString() {
            const options = {
                transaction: "Transactions",
                income: "Income",
                saving: "Investments",
            };
            return options[this.record_type];
        },
        /*
         * Return data field string based on selected data_field for display in dropdown
         */
        dataFieldString() {
            const options = {
                amount: "Contribution",
                balance: "Balance",
                dividends: "Dividends",
            };
            return options[this.data_field];
        },
    },
    mounted: function () {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.fetching = true;
            this.$refs[this.selected_chart_type].chart.showLoading(
                '<div class="spinner-border text-info"></div>'
            );
            Api.getBlockStackedChartHistorical(
                this.timespan,
                this.record_type,
                this.group_by,
                this.data_field
            )
                .then((res) => {
                    this.fetching = false;
                    this.categories = res.data.categories;
                    this.chart_series_data = res.data.series;
                    this.$refs[this.selected_chart_type].chart.hideLoading();
                    this.populateChartData(this.selected_chart_type);
                })
                .catch((err) => {
                    this.fetching = false;
                    console.log(err.response);
                });
        },
        displayClass(chart_type) {
            if (this.selected_chart_type === chart_type) {
                return [];
            }
            return ["d-none"];
        },
        changeGroupBy(group_type) {
            this.group_by = group_type;
            this.fetchData();
        },
        changeTimespan(timespan) {
            this.timespan = timespan;
            this.fetchData();
        },
        changeRecordType(record_type) {
            this.record_type = record_type;
            if (record_type !== "saving") {
                this.data_field = "amount";
            }
            this.fetchData();
        },
        changeDataField(data_field) {
            this.data_field = data_field;
            this.fetchData();
        },
        populateChartData(chart_type) {
            while (this.$refs[this.selected_chart_type].chart.series.length > 0) {
                this.$refs[this.selected_chart_type].chart.series[0].remove(false);
            }
            this.chart_series_data.forEach((el) => {
                this.$refs[chart_type].chart.addSeries(el, false);
            });
            this.selected_chart_type = chart_type;
            this.$refs[chart_type].chart.redraw();
        },
    },
};
</script>