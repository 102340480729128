const spline_mixin = {
    data() {
        return {
            spline_options: {
                chart: {
                    type: 'spline',
                    zoomType: 'x',
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                    crosshair: true,
                },
                yAxis: {
                    title: {
                        enabled: false
                    },
                },
                tooltip: {
                    shared: true,
                    /*
                     * sort items from highest to lowest
                     */
                    formatter: function (tooltip) {
                        var items = this.points || splat(this),
                            series = items[0].series,
                            s;

                        // sort the values
                        items.sort(function (a, b) {
                            return ((a.y < b.y) ? -1 : ((a.y > b.y) ? 1 : 0));
                        });
                        items.reverse();

                        return tooltip.defaultFormatter.call(this, tooltip);
                    },
                },
                plotOptions: {
                    series: {
                        lineWidth: 4,
                        turboThreshold: 0,
                        label: {
                            enabled: false
                        },
                        marker: {
                            enabled: false
                        },
                    },
                },
                series: []
            }
        }
    }
}

export default spline_mixin;