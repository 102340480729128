const solid_gauge_mixin = {
    data() {
        return {
            solid_gauge_options: {
                chart: {
                    type: 'solidgauge',
                    width: 200,
                    height: 200,
                    margin: [0, 0, 0, 0]
                },
                title: {
                    text: 'Progress to FI',
                    verticalAlign: 'bottom',
                    style: {
                        color: '#fff'
                    }
                },
                credits: {
                    enabled: false
                },
                pane: {
                    startAngle: -90,
                    endAngle: 90,
                    background: {
                        backgroundColor: '#EEE',
                        innerRadius: '60%',
                        outerRadius: '100%',
                        shape: 'arc'
                    }
                },
                exporting: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                yAxis: {
                    stops: [
                        [0, '#55BF3B'],
                    ],
                    min: 0,
                    max: 100,
                    lineWidth: 0,
                    tickWidth: 0,
                    minorTickInterval: null,
                    tickAmount: 2,
                    labels: {
                        y: 16
                    }
                },
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            y: 10,
                            borderWidth: 0,
                            format: '{point.y}%',
                            style: {
                                fontSize: '20px',
                                color: '#fff'
                            }
                        },
                    }
                },
                series: [{
                    name: 'FI',
                    data: [],
                }]
            }
        }
    }
}

export default solid_gauge_mixin;