<template>
  <b-container fluid class="pt-5 pt-sm-0 mb-5">
    <b-row align-h="center">
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="check-circle" variant="info" />
              <strong class="ml-2 text-info">Step 1</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">csv file selection</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="check-circle" variant="info" />
              <strong class="ml-2 text-info">Step 2</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Column mapping</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="check-circle" variant="info" />
              <strong class="ml-2 text-info">Step 3</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Classification and rules</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="check-circle" variant="info" />
              <strong class="ml-2 text-info">Step 4</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Confirm and import</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col class="text-center">
        <h5>Confirm and Import</h5>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="10" sm="12" class="text-center">
        <span class="text-secondary">
          The below data is ready to be imported. If any final changes are required you
          can make them now before running the import.
        </span>
      </b-col>
    </b-row>
    <b-row v-if="num_income_records_imported > 0" align-h="center" class="mt-2">
      <b-col cols="10" sm="6">
        <b-alert
          variant="success"
          show
        >
          {{ num_income_records_imported }} Income records imported
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="num_saving_records_imported > 0" align-h="center" class="mt-2">
      <b-col cols="10" sm="6">
        <b-alert
          variant="success"
          show
        >
          {{ num_saving_records_imported }} Saving records imported
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="num_transaction_records_imported > 0" align-h="center" class="mt-2">
      <b-col cols="10" sm="6">
        <b-alert
          variant="success"
          show
        >
          {{ num_transaction_records_imported }} Transaction records imported
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="negativeIncomesExist" align-h="center" class="mt-4">
      <b-col cols="10" sm="8">
        <b-alert variant="danger" show>
          <span class="text-danger">Warning:</span>
          <span>
            Income data must be positive. Please update your income data to ensure all
            amounts are positive.
          </span>
        </b-alert>
      </b-col>
    </b-row>
    <b-row
      v-if="transactionRecords.length || incomeRecords.length || savingRecords.length"
      align-h="center"
      class="mt-3"
    >
      <b-button variant="info" size="sm" @click="onClickImport">
        <b-spinner v-if="fetching > 0" variant="info" small />
        <span v-else>I've checked my data. Run the import.</span>
      </b-button>
    </b-row>
    <b-tabs active-tab-class="p-0" class="mt-4">
      <b-tab v-if="transactionRecords.length" class="pt-3" title="Transactions">
        <b-row class="mt-2 mb-2">
          <b-col>
            <b-form-checkbox
              value="true"
              size="sm"
              unchecked-value
              switch
              @change="flipTransactionsDebitCredit()"
            >
              Flip debits/credits
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-table
          :items="transactionRecords"
          :fields="table_fields"
          :tbody-tr-class="rowClass"
          bordered
          outlined
          striped
          responsive
          small
        >
          <template v-slot:cell(buttons)="data">
            <b-button-group>
              <b-button
                v-if="data.item.notes.length > 0"
                size="sm"
                variant="outline-info"
                @click="toggleShowRowDetail(data.item)"
              >
                <b-icon
                  v-if="!data.detailsShowing"
                  icon="caret-down"
                  font-scale="1.3"
                />
                <b-icon v-else icon="caret-right" font-scale="1.3" />
              </b-button>
              <b-button
                size="sm"
                variant="outline-info"
                @click="displayEditModal(data.item)"
              >
                <b-icon
                  icon="pencil-square"
                  font-scale="1.3"
                  variant="info"
                />
              </b-button>
              <b-button
                size="sm"
                variant="outline-info"
                @click="displayDeleteModal(data.item)"
              >
                <b-icon icon="x-square" font-scale="1.3" variant="danger" />
              </b-button>
            </b-button-group>
          </template>
          <template v-slot:row-details="data">
            <b-card bg-variant="info">
              <b-row>
                <b-col>
                  <strong>Notes:</strong>
                  <span class="text-light ml-2">{{ data.item.notes }}</span>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-slot:cell(applied_rule_id)="data">
            <b-row align-h="center">
              <b-icon
                v-if="data.item.applied_rule_id !== ''"
                icon="check-circle"
                variant="info"
              />
              <b-icon v-else icon="circle" variant="info" />
            </b-row>
          </template>
          <template v-slot:cell(date)="data">
            {{
              new Date(data.value).toDateString()
            }}
          </template>
          <template v-slot:cell(amount)="data">
            {{ formatCurrency(data.value, currency) }}
            <span v-if="data.value < 0" class="text-danger float-right">debit</span>
            <span v-else class="text-success float-right">credit</span>
          </template>
          <template v-slot:cell(category)="data">
            {{
              getCategoryName(data.value, data.item.record_type)
            }}
          </template>
          <template v-slot:cell(tags)="data">
            <b-badge
              v-for="tag_id in data.value"
              :key="tag_id"
              variant="transparent"
              class="border border-secondary ml-1 mr-1"
            >
              <b-icon icon="tag" font-scale="1.3" class="mr-1" />
              {{ getTagName(tag_id) }}
            </b-badge>
          </template>
        </b-table>
      </b-tab>
      <b-tab v-if="savingRecords.length" class="pt-3" title="Investments">
        <b-row class="mt-2 mb-2">
          <b-col>
            <b-form-checkbox
              value="true"
              size="sm"
              unchecked-value
              switch
              @change="flipSavingsDebitCredit()"
            >
              Flip debits/credits
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-table
          :items="savingRecords"
          :fields="saving_table_fields"
          thead-tr-class="table-light"
          table-class="border border-info"
          :tbody-tr-class="rowClass"
          bordered
          hover
          responsive
          small
        >
          <template v-slot:cell(buttons)="data">
            <b-button-group>
              <b-button
                v-if="data.item.notes.length > 0"
                size="sm"
                variant="outline-info"
                @click="toggleShowRowDetail(data.item)"
              >
                <b-icon
                  v-if="!data.detailsShowing"
                  icon="caret-down"
                  font-scale="1.3"
                />
                <b-icon v-else icon="caret-right" font-scale="1.3" />
              </b-button>
              <b-button
                size="sm"
                variant="outline-info"
                @click="displayEditModal(data.item)"
              >
                <b-icon
                  icon="pencil-square"
                  font-scale="1.3"
                  variant="info"
                />
              </b-button>
              <b-button
                size="sm"
                variant="outline-info"
                @click="displayDeleteModal(data.item)"
              >
                <b-icon icon="x-square" font-scale="1.3" variant="danger" />
              </b-button>
            </b-button-group>
          </template>
          <template v-slot:row-details="data">
            <b-card bg-variant="info">
              <b-row>
                <b-col>
                  <strong>Notes:</strong>
                  <span class="text-light ml-2">{{ data.item.notes }}</span>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-slot:cell(applied_rule_id)="data">
            <b-row align-h="center">
              <b-icon
                v-if="data.item.applied_rule_id !== ''"
                icon="check-circle"
                variant="info"
              />
              <b-icon v-else icon="circle" variant="info" />
            </b-row>
          </template>
          <template v-slot:cell(date)="data">
            {{
              new Date(data.value).toDateString()
            }}
          </template>
          <template v-slot:cell(amount)="data">
            {{ formatCurrency(data.value, currency) }}
            <span v-if="data.value < 0" class="text-danger float-right">debit</span>
            <span v-else class="text-success float-right">credit</span>
          </template>
          <template v-slot:cell(balance)="data">
            {{
              formatCurrency(data.value, currency)
            }}
          </template>
          <template v-slot:cell(category)="data">
            {{
              getCategoryName(data.value, data.item.record_type)
            }}
          </template>
          <template v-slot:cell(tags)="data">
            <b-badge
              v-for="tag_id in data.value"
              :key="tag_id"
              variant="transparent"
              class="border border-secondary ml-1 mr-1"
            >
              <b-icon icon="tag" font-scale="1.3" class="mr-1" />
              {{ getTagName(tag_id) }}
            </b-badge>
          </template>
        </b-table>
      </b-tab>
      <b-tab v-if="incomeRecords.length" class="pt-3" title="Income">
        <b-row class="mt-2 mb-2">
          <b-col>
            <b-form-checkbox
              value="true"
              size="sm"
              unchecked-value
              switch
              @change="flipIncomesDebitCredit()"
            >
              Flip debits/credits
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-table
          :items="incomeRecords"
          :fields="table_fields"
          thead-tr-class="table-light"
          table-class="border border-info"
          :tbody-tr-class="rowClass"
          bordered
          hover
          responsive
          small
        >
          <template v-slot:cell(buttons)="data">
            <b-button-group>
              <b-button
                v-if="data.item.notes.length > 0"
                size="sm"
                variant="outline-info"
                @click="toggleShowRowDetail(data.item)"
              >
                <b-icon
                  v-if="!data.detailsShowing"
                  icon="caret-down"
                  font-scale="1.3"
                />
                <b-icon v-else icon="caret-right" font-scale="1.3" />
              </b-button>
              <b-button
                size="sm"
                variant="outline-info"
                @click="displayEditModal(data.item)"
              >
                <b-icon
                  icon="pencil-square"
                  font-scale="1.3"
                  variant="info"
                />
              </b-button>
              <b-button
                size="sm"
                variant="outline-info"
                @click="displayDeleteModal(data.item)"
              >
                <b-icon icon="x-square" font-scale="1.3" variant="danger" />
              </b-button>
            </b-button-group>
          </template>
          <template v-slot:row-details="data">
            <b-card bg-variant="info">
              <b-row>
                <b-col>
                  <strong>Notes:</strong>
                  <span class="text-light ml-2">{{ data.item.notes }}</span>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-slot:cell(applied_rule_id)="data">
            <b-row align-h="center">
              <b-icon
                v-if="data.item.applied_rule_id !== ''"
                icon="check-circle"
                variant="info"
              />
              <b-icon v-else icon="circle" variant="info" />
            </b-row>
          </template>
          <template v-slot:cell(date)="data">
            {{
              new Date(data.value).toDateString()
            }}
          </template>
          <template v-slot:cell(amount)="data">
            {{ formatCurrency(data.value, currency) }}
            <span v-if="data.value < 0" class="text-danger float-right">debit</span>
            <span v-else class="text-success float-right">credit</span>
          </template>
          <template v-slot:cell(category)="data">
            {{
              getCategoryName(data.value, data.item.record_type)
            }}
          </template>
          <template v-slot:cell(tags)="data">
            <b-badge
              v-for="tag_id in data.value"
              :key="tag_id"
              variant="transparent"
              class="border border-secondary ml-1 mr-1"
            >
              <b-icon icon="tag" font-scale="1.3" class="mr-1" />
              {{ getTagName(tag_id) }}
            </b-badge>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
    <!-- 
            Modals
        -->
    <b-modal ref="editRecordModal" hide-footer @hidden="onModalHidden">
      <b-row>
        <b-col>
          <EditRecordForm
            :record-index="selected_record_index"
            @hideEditModal="hideEditModal"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="deleteRecordModal" hide-footer @hidden="onModalHidden">
      <b-row>
        <b-col>
          <DeleteRecordForm
            :record-index="selected_record_index"
            @recordDeleted="recordDeleted"
          />
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import Api from "@/api";
import { formatCurrency } from "@/utils/general.js";
import EditRecordForm from "./EditRecordForm.vue";
import DeleteRecordForm from "./DeleteRecordForm.vue";

export default {
    components: { EditRecordForm, DeleteRecordForm },
    data() {
        return {
            fetching: 0,
            num_income_records_imported: 0,
            num_saving_records_imported: 0,
            num_transaction_records_imported: 0,
            selected_record_index: "",
            table_fields: [
                { key: "buttons", label: "", stickyColumn: true },
                { key: "applied_rule_id", label: "Rule Applied" },
                { key: "date", label: "Date", sortable: true },
                { key: "category", label: "Category", sortable: true },
                { key: "payee", label: "Payee", sortable: true },
                { key: "amount", label: "Amount", sortable: true },
                { key: "tags", label: "Tags" },
            ],
            saving_table_fields: [
                { key: "buttons", label: "", stickyColumn: true },
                { key: "applied_rule_id", label: "Rule Applied" },
                { key: "date", label: "Date", sortable: true },
                { key: "category", label: "Category", sortable: true },
                { key: "payee", label: "Payee", sortable: true },
                { key: "balance", label: "Balance", sortable: true },
                { key: "amount", label: "Contribution", sortable: true },
                { key: "tags", label: "Tags" },
            ],
        };
    },
    computed: {
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
        incomeRecords() {
            return this.$store.getters["importer/incomeRecords"];
        },
        savingRecords() {
            return this.$store.getters["importer/savingRecords"];
        },
        transactionRecords() {
            return this.$store.getters["importer/transactionRecords"];
        },
        unclassifedRecords() {
            return this.$store.getters["importer/unclassifedRecords"];
        },
        negativeIncomesExist() {
            return this.$store.getters["importer/negativeIncomesExist"];
        },
    },
    methods: {
        formatCurrency,
        /*
         * Apply row styling if exclude_from_totals is true for the record
         */
        rowClass(item, type) {
            if (!item || type !== "row") return;
            if (item.exclude_from_totals) return "table-warning";
        },
        /*
         * show/hide selected row details by updating store
         */
        toggleShowRowDetail(record) {
            this.$store.commit("importer/toggleShowRowDetail", record.index);
        },
        /*
         * clear the selected_record_index when the edit modal is closed
         */
        onModalHidden() {
            this.selected_record_index = "";
        },
        /*
         * set the selected_record_index and display the edit modal
         */
        displayEditModal(record) {
            this.selected_record_index = record.index;
            this.$refs["editRecordModal"].show();
        },
        /*
         * hide edit modal
         */
        hideEditModal() {
            this.$refs["editRecordModal"].hide();
        },
        /*
         * set the selected_record_index and display the delete modal
         */
        displayDeleteModal(record) {
            this.selected_record_index = record.index;
            this.$refs["deleteRecordModal"].show();
        },
        /*
         * emited via delete record modal
         */
        recordDeleted() {
            this.$refs["deleteRecordModal"].hide();
        },
        /*
         * Based on categoryID and record_type, return the category name
         */
        getCategoryName(category_id, record_type) {
            switch (record_type) {
                case "income":
                    return this.$store.getters["importer/incomeCategory"](category_id).name;
                    break;
                case "saving":
                    return this.$store.getters["importer/savingCategory"](category_id).name;
                    break;
                case "transaction":
                    return this.$store.getters["importer/transactionCategory"](category_id).name;
                    break;
                default:
                    return "";
                    break;
            }
        },
        /*
         * Bulk create records on server for each record_type
         */
        onClickImport() {
            if (this.incomeRecords.length) {
                this.fetching += 1;
                Api.createRecords("income", this.incomeRecords)
                    .then((res) => {
                        this.fetching -= 1;
                        this.num_income_records_imported = res.data.length;
                        this.$store.commit("importer/deleteRecordsByType", "income");
                        this.$bvToast.toast(`${res.data.length} Income records imported`, {
                            title: "Import successful",
                            variant: "success",
                            autoHideDelay: 2500,
                        });
                    })
                    .catch((err) => {
                        this.fetching -= 1;
                        this.$bvToast.toast("Error importing Income data", {
                            title: "Failed",
                            variant: "danger",
                            autoHideDelay: 2500,
                        });
                    });
            }
            if (this.transactionRecords.length) {
                this.fetching += 1;
                Api.createRecords("transaction", this.transactionRecords)
                    .then((res) => {
                        this.fetching -= 1;
                        this.num_transaction_records_imported = res.data.length;
                        this.$store.commit("importer/deleteRecordsByType", "transaction");
                        this.$bvToast.toast(`${res.data.length} Transaction records imported`, {
                            title: "Import successful",
                            variant: "success",
                            autoHideDelay: 2500,
                        });
                    })
                    .catch((err) => {
                        this.fetching -= 1;
                        this.$bvToast.toast("Error importing Transaction data", {
                            title: "Failed",
                            variant: "danger",
                            autoHideDelay: 2500,
                        });
                    });
            }
            if (this.savingRecords.length) {
                this.fetching += 1;
                Api.createRecords("saving", this.savingRecords)
                    .then((res) => {
                        this.fetching -= 1;
                        this.num_saving_records_imported = res.data.length;
                        this.$store.commit("importer/deleteRecordsByType", "saving");
                        this.$bvToast.toast(`${res.data.length} Saving records imported`, {
                            title: "Import successful",
                            variant: "success",
                            autoHideDelay: 2500,
                        });
                    })
                    .catch((err) => {
                        this.fetching -= 1;
                        this.$bvToast.toast("Error importing Saving data", {
                            title: "Failed",
                            variant: "danger",
                            autoHideDelay: 2500,
                        });
                    });
            }
        },
        /*
         * Based on tagID, return the name from tagOptions
         */
        getTagName(tag_id) {
            return this.$store.getters["importer/tag"](tag_id).name;
        },
        flipTransactionsDebitCredit() {
            this.$store.commit("importer/flipDebitCredit", this.transactionRecords);
        },
        flipSavingsDebitCredit() {
            this.$store.commit("importer/flipDebitCredit", this.savingRecords);
        },
        flipIncomesDebitCredit() {
            this.$store.commit("importer/flipDebitCredit", this.incomeRecords);
        },
    },
};
</script>