<template>
  <b-container fluid class="pt-5 pt-sm-0 mb-5">
    <b-row align-h="center">
      <h4>Settings</h4>
    </b-row>
    <b-row align-h="center">
      <b-col cols="10" sm="12" class="text-center">
        <span class="text-secondary"> Manager your global account settings. </span>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-5">
      <b-col cols="8" sm="6" md="4">
        <b-card header-class="bg-transparent">
          <template v-slot:header>
            <span>Currency</span>
          </template>
          <b-form-select
            v-model="currency"
            :options="currencyOptions"
            size="sm"
            @change="saveSettings"
          />
          <b-row class="mt-2">
            <b-col>
              <p class="text-secondary">
                This currency will be used throughout Money FI.
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-3">
      <b-col cols="8" sm="6" md="4">
        <b-card header-class="bg-transparent">
          <template v-slot:header>
            <span>Default Dashboard Month</span>
          </template>
          <b-form-select
            v-model="stats_default_month"
            :options="stats_default_month_options"
            size="sm"
            @change="saveSettings"
          />
          <b-row class="mt-2">
            <b-col>
              <p class="text-secondary">
                On both the <i>General</i> and <i>Investment</i> dashboards there
                are several stats which display data for a specific month.
              </p>
              <p class="text-secondary">
                This setting ensures either the <i>Current</i> or <i>Prior</i> month
                is displayed by default when opening either dashboard.
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
    data() {
        return {
            currency: "USD",
            currencyOptions: [
                { value: "USD", text: "$ (Dollar)" },
                { value: "EUR", text: "€ (Euro)" },
                { value: "JPY", text: "¥ (Yen / Yuan)" },
                { value: "GBP", text: "£ (Pound)" },
                { value: "INR", text: "₹ (Rupee)" },
                { value: "ZAR", text: "ZAR (Rand)" },
            ],
            stats_default_month: "current",
            stats_default_month_options: [
                { value: "current", text: "Current Month" },
                { value: "prior", text: "Prior Month" },
            ],
        };
    },
    created() {
        this.currency = this.$store.getters["appSettings/currency"];
        this.stats_default_month = this.$store.state.appSettings.stats_default_month;
    },
    methods: {
        saveSettings() {
            const data = { currency: this.currency, stats_default_month: this.stats_default_month };
            Api.updateAppSettings(data)
                .then((res) => {
                    this.$store.commit("appSettings/setAppSettings", res.data);
                    this.$bvToast.toast(`Settings saved`, {
                        title: "Success",
                        variant: "success",
                        autoHideDelay: 2500,
                    });
                })
                .catch((err) => {
                    console.log(err.response);
                    this.$bvToast.toast(`Failed to save settings`, {
                        title: "Failure",
                        variant: "danger",
                        autoHideDelay: 2500,
                    });
                });
        },
    },
};
</script>