import Vue from 'vue'

const getDefaultState = () => {
    return {
        accounts: {},
        categories: {},
        tags: {},
    }
}

const state = getDefaultState();

const getters = {
    /*
     * List getters
     */
    accountList: state => {
        return Object.values(state.accounts)
    },
    categoryList: state => {
        return Object.values(state.categories)
    },
    tagList: state => {
        return Object.values(state.tags)
    },

    /*
     * Get by ID
     */
    account: (state) => (id) => {
        return state.accounts[id];
    },
    category: (state) => (id) => {
        return state.categories[id];
    },
    tag: (state) => (id) => {
        return state.tags[id];
    },
}

const mutations = {
    resetState: (state) => {
        Object.assign(state, getDefaultState());
    },

    /*
     * initialise state data
     */
    initAccounts: (state, accounts) => {
        accounts.forEach(account => {
            Vue.set(state.accounts, account.id, account);
        })
    },
    initCategories: (state, categories) => {
        categories.forEach(category => {
            Vue.set(state.categories, category.id, category);
        })
    },
    initTags: (state, tags) => {
        tags.forEach(tag => {
            Vue.set(state.tags, tag.id, tag);
        })
    },

    /*
     * Create or update individual data
     */
    createUpdateAccount: (state, account) => {
        Vue.set(state.accounts, account.id, account);
    },
    createUpdateCategory: (state, category) => {
        Vue.set(state.categories, category.id, category);
    },
    createUpdateTag: (state, tag) => {
        Vue.set(state.tags, tag.id, tag);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}