const stacked_column_mixin = {
    data() {
        return {
            stacked_column_options: {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    type: 'datetime',
                },
                yAxis: {
                    title: {
                        enabled: false
                    },
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                    }
                },
                series: []
            }
        }
    }
}

export default stacked_column_mixin;