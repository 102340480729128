<template>
  <b-container fluid>
    <b-button
      variant="info"
      class="back-button"
      :to="{ name: routerDestination, params: { recordType: recordType } }"
    >
      <b-icon icon="arrow90deg-left" font-scale="1.3" />
    </b-button>
    <b-row align-h="center">
      <b-col v-if="recordType === 'saving'" class="text-center">
        <h4>Investment Categories</h4>
        <span
          class="text-secondary"
        >Classify your investments, for example: ETF's, 401k, Brokerage etc.</span>
      </b-col>
      <b-col v-else-if="recordType === 'income'" class="text-center">
        <h4>Income Categories</h4>
        <span
          class="text-secondary"
        >Classify your income streams, for example: salary, rental income etc.</span>
      </b-col>
      <b-col v-else class="text-center">
        <h4>Transaction Categories</h4>
        <span class="text-secondary">
          Classify your transactions, for example: groceries, mortgage payments, insurance
          etc.
        </span>
      </b-col>
    </b-row>
    <div v-if="fetching" class="text-center mt-4">
      <b-spinner small label="Small Spinner" variant="info" />
    </div>
    <b-row v-if="!fetching" align-h="center" class="mt-5">
      <b-form inline>
        <b-col>
          <b-button
            size="sm"
            variant="info"
            @click="displayCreateModal()"
          >
            Create
          </b-button>
        </b-col>
        <b-col>
          <b-form-input
            v-model="filterBy"
            size="sm"
            type="search"
            placeholder="Filter..."
            class="ml-2"
          />
        </b-col>
      </b-form>
    </b-row>
    <b-row v-if="!fetching && categories.length === 0" class="mt-5" align-h="center">
      <b-col sm="10" md="6" lg="4" class="text-center">
        <b-alert variant="info" show>
          No data available.
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="!fetching" align-h="center" class="mt-4 mb-5">
      <b-col
        v-for="category in filteredCategories()"
        :key="category.id"
        cols="12"
        sm="10"
        md="8"
      >
        <b-card class="mt-2">
          <b-row>
            <b-col cols="9">
              {{ category.name }}
            </b-col>
            <b-col cols="3">
              <b-row align-h="end">
                <b-button
                  size="sm"
                  variant="transparent"
                  @click="displayUpdateModal(category)"
                >
                  <b-icon
                    icon="pencil-square"
                    font-scale="1.3"
                    variant="info"
                  />
                </b-button>
                <b-button
                  size="sm"
                  variant="transparent"
                  @click="displayDeleteModal(category)"
                >
                  <b-icon
                    icon="x-square"
                    font-scale="1.3"
                    variant="danger"
                  />
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!--
            Modals 
        -->
    <b-modal ref="delete_modal" size="sm" hide-footer static>
      <DeleteForm
        :category="selectedCategory"
        :record-type="recordType"
        @categoryDeleted="categoryDeleted"
      />
    </b-modal>
    <b-modal ref="update_modal" hide-footer static>
      <UpdateForm
        :category="selectedCategory"
        :record-type="recordType"
        @categoryUpdated="categoryUpdated"
      />
    </b-modal>
    <b-modal ref="create_modal" hide-footer>
      <CreateForm :record-type="recordType" @categoryCreated="categoryCreated" />
    </b-modal>
  </b-container>
</template>

<script>
import Api from "@/api";
import DeleteForm from "./DeleteForm.vue";
import UpdateForm from "./UpdateForm.vue";
import CreateForm from "./CreateForm.vue";

export default {
    components: { DeleteForm, UpdateForm, CreateForm },
    props: { recordType: { type: String, default: "transaction" } },
    data() {
        return {
            fetching: false,
            categories: [],
            selectedCategory: {},
            filterBy: "",
        };
    },
    computed: {
        /*
         * Used for the back button to determine where to return to
         */
        routerDestination() {
            if (this.recordType === "saving") {
                return "InvestmentsManagement";
            } else if (this.recordType === "transaction") {
                return "TransactionManagement";
            }
            return "IncomeManagement";
        },
    },
    mounted: function () {
        this.fetching = true;
        let categoriesPromise = null;
        switch (this.recordType) {
            case "transaction":
                categoriesPromise = Api.getTransactionCategories("name", "");
                break;
            case "income":
                categoriesPromise = Api.getIncomeCategories("name", "");
                break;
            case "saving":
                categoriesPromise = Api.getSavingCategories("name", "");
                break;
        }
        categoriesPromise
            .then((res) => {
                this.fetching = false;
                this.categories = res.data;
            })
            .catch((err) => {
                this.fetching = false;
                console.log(err.response);
            });
    },
    methods: {
        filteredCategories() {
            if (this.filterBy === "") {
                return this.categories;
            } else {
                return this.categories.filter((obj) =>
                    obj.name.toLowerCase().includes(this.filterBy.toLowerCase())
                );
            }
        },
        /*
         * set the selectedCategory and display the delete modal
         */
        displayDeleteModal(category) {
            this.selectedCategory = category;
            this.$refs["delete_modal"].show();
        },
        /*
         * emitted via DeleteForm when category deleted on server
         */
        categoryDeleted() {
            this.categories.splice(this.categories.indexOf(this.selectedCategory), 1);
            this.$refs["delete_modal"].hide();
        },
        /*
         * set the selectedCategory and display the update modal
         */
        displayUpdateModal(category) {
            this.selectedCategory = category;
            this.$refs["update_modal"].show();
        },
        /*
         * emitted via Form when category updated on server
         */
        categoryUpdated(category) {
            const index = this.categories.indexOf(this.selectedCategory);
            this.$set(this.categories, index, { ...category });
            this.$refs["update_modal"].hide();
        },
        /*
         * Display the create modal
         */
        displayCreateModal() {
            this.$refs["create_modal"].show();
        },
        /*
         * emitted via Form when category created on server
         */
        categoryCreated(category) {
            this.categories.unshift(category);
            this.$refs["create_modal"].hide();
            this.$bvToast.toast("Category Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
    },
};
</script>