<template>
  <b-card header-bg-variant="transparent" header-border-variant="info" border-variant="info" body-class="p-0">
    <template v-slot:header>
      <b-row align-h="end">
        <b-dropdown size="sm" variant="info" no-caret class="mt-1 mt-sm-0">
          <template v-slot:button-content>
            <span>Group By: </span>
            <span>
              {{ group_by.charAt(0).toUpperCase() + group_by.slice(1) }}
            </span>
            <b-icon icon="caret-down" class="float-right" />
          </template>
          <b-dropdown-item-button @click="changeGroupBy('account')">
            Account
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="changeGroupBy('category')">
            Category
          </b-dropdown-item-button>
        </b-dropdown>
        <b-dropdown size="sm" variant="info" no-caret class="ml-2 mt-1 mt-sm-0">
          <template v-slot:button-content>
            <span>Data: </span>
            <span>{{ dataFieldString }} </span>
            <b-icon icon="caret-down" class="float-right" />
          </template>
          <b-dropdown-item-button @click="changeDataField('amount')">
            Contribution
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="changeDataField('balance')">
            Balance
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="changeDataField('dividends')">
            Dividends
          </b-dropdown-item-button>
        </b-dropdown>
        <span class="ml-2 mt-1 mt-sm-0">
          <DatePicker
            :selected-date="month"
            :minimum-view="'month'"
            :disabled-dates="disabled_dates"
            input-classes="bg-info text-light"
            @onDateSelected="onDateSelected"
          />
        </span>
      </b-row>
    </template>
    <b-row v-if="!fetching && chart_data.data.length === 0" align-h="center" align-v="center">
      <b-col cols="10" sm="8" md="6">
        <b-alert variant="danger" show size="sm" class="text-center mt-2">
          <span class="text-danger">Insufficient data</span>
        </b-alert>
      </b-col>
    </b-row>
    <highcharts
      ref="chart_ref"
      :options="bar_options"
      :update-args="chart_update_args"
    />
  </b-card>
</template>

<script>
import Api from "@/api";
import bar from "@/highcharts/bar.js";
import DatePicker from "@/components/date_picker/Index.vue";

export default {
    components: { DatePicker },
    mixins: [bar],
    data() {
        return {
            fetching: false,
            group_by: "category",
            data_field: "balance",
            month: "",
            chart_update_args: [true, true, { duration: 3000 }],
            chart_data: { data: [], name: "" },
            disabled_dates: {
                from: new Date(),
            },
        };
    },
    computed: {
        /*
         * Return data field string based on selected data_field for display in dropdown
         */
        dataFieldString() {
            const options = {
                amount: "Contribution",
                balance: "Balance",
                dividends: "Dividends",
            };
            return options[this.data_field];
        },
    },
    mounted: function () {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.fetching = true;
            this.$refs.chart_ref.chart.showLoading('<div class="spinner-border text-info"></div>');
            Api.getBlockInvestmentsBalanceChartMonth(this.group_by, this.data_field, this.month)
                .then((res) => {
                    this.fetching = false;
                    this.month = res.data.month;
                    this.chart_data = res.data.series;
                    this.populateChartData();
                })
                .catch((err) => {
                    this.fetching = false;
                    console.log(err.response);
                });
        },
        populateChartData() {
            let chart_instance = this.$refs.chart_ref.chart;
            chart_instance.hideLoading();
            while (chart_instance.series.length > 0) {
                chart_instance.series[0].remove(false);
            }
            chart_instance.addSeries(this.chart_data, false);
            chart_instance.redraw();
        },
        changeDataField(data_field) {
            this.data_field = data_field;
            this.fetchData();
        },
        changeGroupBy(group_by) {
            this.group_by = group_by;
            this.fetchData();
        },
        onDateSelected(date) {
            this.month = date.toISOString().substr(0, 10);
            this.fetchData();
        },
    },
};
</script>