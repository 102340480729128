<template>
  <b-container fluid class="pt-5 pt-sm-0 mb-5">
    <supportAlert link="https://www.moneyfi.app/support/bulk_csv_import/" />
    <b-button variant="info" class="back-button" :to="{ name: 'BulkImport' }">
      <b-icon icon="arrow90deg-left" font-scale="1.3" />
    </b-button>
    <b-row align-h="center">
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="check-circle" variant="info" />
              <strong class="ml-2 text-info">Step 1</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">csv file selection</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="circle" variant="secondary" />
              <strong class="ml-2">Step 2</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Column mapping</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="circle" variant="secondary" />
              <strong class="ml-2">Step 3</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Classification and rules</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="circle" variant="secondary" />
              <strong class="ml-2">Step 4</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Confirm and import</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col class="text-center">
        <h5>File selection</h5>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="10" sm="12" class="text-center">
        <span class="text-secondary">
          Select a csv file containing historical data from one of your accounts.
        </span>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-5">
      <b-col md="8" lg="6" xl="4">
        <b-card>
          <b-row align-h="center">
            <b-col>
              <label class="text-secondary">
                1. Which account are you uploading data for?
              </label>
              <b-form-select
                v-model="account"
                :options="accounts"
                value-field="id"
                text-field="full_name"
                size="sm"
              />
            </b-col>
          </b-row>
          <b-row align-h="center" class="mt-3">
            <b-col>
              <label class="text-secondary">
                2. Browse for or drag and drop your csv file.
              </label>
              <b-form-file
                v-model="csvFile"
                size="sm"
                accept=".csv"
                :state="Boolean(csvFile)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col class="text-right">
              <b-button
                size="sm"
                variant="info"
                :disabled="!csvFile || account === null"
                @click="parse"
              >
                <b-spinner v-if="fetching" variant="info" small />
                <span v-else>Open</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Papa from "papaparse";
import Api from "../../api";
import supportAlert from "@/components/support_alert/Index.vue";

export default {
    components: { supportAlert },
    data() {
        return {
            fetching: false,
            csvFile: null,
            account: null,
            accounts: [{ id: null, full_name: "Select an account", disabled: true }],
        };
    },
    created() {
        this.$store.commit("importer/resetState");
        Api.getAccounts()
            .then((res) => {
                const accounts = [
                    { id: null, full_name: "Select an account", disabled: true },
                    ...res.data,
                ];
                this.accounts = accounts;
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getTags()
            .then((res) => {
                this.$store.commit("importer/setTags", res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getTransactionCategories()
            .then((res) => {
                this.$store.commit("importer/setTransactionCategories", res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getIncomeCategories()
            .then((res) => {
                this.$store.commit("importer/setIncomeCategories", res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getSavingCategories()
            .then((res) => {
                this.$store.commit("importer/setSavingCategories", res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
    methods: {
        /*
         * Parse csv file and nav to CSVMapColumns when complete
         */
        parse() {
            this.fetching = true;
            Papa.parse(this.csvFile, {
                header: true,
                transformHeader: (header) => header.trim(),
                transform: (value, header) => {
                    // trim and remove any double spaces within string
                    return value.trim().replace(/\s+/g, " ");
                },
                skipEmptyLines: true,
                /*
                 * When parsing is complete, nav to CSVDataImporter
                 */
                complete: (results) => {
                    const account = this.accounts.find((obj) => obj.id == this.account);
                    this.$router.push({
                        name: "CSVMapColumns",
                        params: {
                            data: results.data,
                            csvHeaderOptions: results.meta.fields,
                            account: account,
                        },
                    });
                },
                error: (error, File) => {
                    this.fetching = false;
                    console.log(error);
                },
            });
        },
    },
};
</script>
