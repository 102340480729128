<template>
  <b-container fluid>
    <b-row align-h="center" class="mt-2">
      <h5 class="text-secondary">
        Save your query
      </h5>
    </b-row>
    <b-row align-h="center" class="mt-2">
      <b-form-input v-model="name" type="text" placeholder="Query name" />
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-button size="sm" variant="info" @click="createUpdateQuery">
        <b-spinner v-if="fetching" small />
        <span v-else>Save</span>
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
    data() {
        return {
            name: "",
            id: null,
            fetching: false,
        };
    },
    /*
     * Copy store params to local data so that all changes are done locally until
     * changes are saved to server.
     */
    created() {
        this.name = this.$store.state.query_tool.params.name;
        this.id = this.$store.state.query_tool.params.id;
    },
    methods: {
        createUpdateQuery() {
            this.fetching = true;
            const data = { ...this.$store.state.query_tool.params, name: this.name };
            if (this.id === null || this.name !== this.$store.state.query_tool.params.name) {
                Api.createQuery(data)
                    .then((res) => {
                        this.fetching = false;
                        this.$store.commit("query_tool/updateParams", res.data);
                        this.$emit("querySaved");
                    })
                    .catch((err) => {
                        this.fetching = false;
                        console.log(err.response);
                        this.$bvToast.toast(err.response.data.non_field_errors[0], {
                            title: "Cannot save query",
                            variant: "danger",
                            autoHideDelay: 2500,
                        });
                    });
            } else {
                Api.updateQuery(data)
                    .then((res) => {
                        this.fetching = false;
                        this.$store.commit("query_tool/updateParams", res.data);
                        this.$emit("querySaved");
                    })
                    .catch((err) => {
                        this.fetching = false;
                        console.log(err.response);
                        this.$bvToast.toast("Error saving query", {
                            title: "Cannot save query",
                            variant: "danger",
                            autoHideDelay: 2500,
                        });
                    });
            }
        },
    },
};
</script>