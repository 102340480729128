<template>
  <b-container fluid>
    <b-row class="mt-5" align-h="center" align-v="center">
      <b-col cols="8" sm="6" md="4" lg="2">
        <img
          src="@/assets/img/logo.svg"
          class="img-fluid"
        >
      </b-col>
      <b-col cols="10" sm="6" md="6" class="mt-4 mt-md-0">
        <b-row>
          <b-card>
            <h5 class="text-secondary">
              Money FI membership options
            </h5>
            <p>
              If you wish to continue using Money FI, please select from one of the below
              plans.
            </p>
          </b-card>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-5" align-h="center">
      <b-col cols="10" sm="6" md="4" xl="3">
        <b-card>
          <h4 class="text-info text-center">
            Monthly Subscription
          </h4>
          <h5 class="text-center mt-4">
            $9 per month
          </h5>
          <p class="text-center mt-4">
            Billed monthly
          </p>
          <b-row align-h="center">
            <b-button variant="info" class="mt-4" @click="makePayment(monthly_params)">
              Sign me up
            </b-button>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="10" sm="6" md="4" xl="3">
        <b-card class="mt-4 mt-sm-0">
          <h4 class="text-info text-center">
            Yearly Subscription
          </h4>
          <h5 class="text-center mt-4">
            $7.50 per month
          </h5>
          <p class="text-center mt-4">
            Billed as $90 annually
          </p>
          <b-row align-h="center">
            <b-button variant="info" class="mt-4" @click="makePayment(yearly_params)">
              Sign me up
            </b-button>
          </b-row>
        </b-card>    
      </b-col>  
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
  data() {
    return {
      monthly_params: {},
      yearly_params: {},
    };
  },
  mounted: function() {
    let flw = document.createElement("script");
    flw.setAttribute("src", "https://checkout.flutterwave.com/v3.js");
    flw.async = true;
    document.head.appendChild(flw);
  },
  created() {
    Api.getPaymentParams("Monthly")
      .then((res) => {
        this.monthly_params = res.data;
      })
      .catch((err) => {
        this.fetching = false;
        console.log(err.response);
      });
    Api.getPaymentParams("Yearly")
      .then((res) => {
        this.yearly_params = res.data;
      })
      .catch((err) => {
        this.fetching = false;
        console.log(err.response);
      });
  },
  methods: {
    // Display flutterwave payment modal. If payment is successful, redirect to dashboard
    makePayment(plan_params) {
      FlutterwaveCheckout({
        ...plan_params,
        callback: (data) => {
          if (data.status == 'successful') {
            this.$store.commit("signup/updateUserActiveStatus", {user_is_paidup: true});
            this.$router.push({ name: "GeneralDashboard" });
          } else {
            console.log(data)
          }
        },
        onclose: function() {
          // not used
        },
      });
    },
  },
};
</script>
