<template>
  <highcharts
    ref="chart"
    :update-args="chart_update_args"
    :options="streamChartMixinOptions"
  />
</template>

<script>
import chart from "../chart_mixins/stream.js";

export default {
    mixins: [chart],
    data() {
        return {
            chart_update_args: [true, true, { duration: 3000 }],
        };
    },
    computed: {
        /*
         * Return chart data from store
         */
        chartData() {
            return this.$store.state.query_tool.results.chart_timeseries;
        },
        /*
         * Return fetching from store
         */
        fetching() {
            return this.$store.state.query_tool.fetching;
        },
    },
    watch: {
        /*
         * When chart data in store changes, update and redraw the chart
         */
        chartData() {
            const chartInstance = this.$refs.chart.chart;
            while (chartInstance.series.length > 0) {
                chartInstance.series[0].remove(false);
            }
            this.chartData.forEach((obj) => {
                chartInstance.addSeries(obj, false);
            });
            chartInstance.redraw();
        },
        /*
         * if fetching is true, display chart spinner
         */
        fetching() {
            const chartInstance = this.$refs.chart.chart;
            if (this.fetching) {
                chartInstance.showLoading('<div class="spinner-border text-info"></div>');
            } else {
                chartInstance.hideLoading();
            }
        },
    },
};
</script>