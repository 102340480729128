<template>
  <b-container fluid>
    <b-row align-h="center" class="mt-2">
      <h5 class="text-secondary">
        Update category name
      </h5>
    </b-row>
    <b-row align-h="center" class="mt-2">
      <b-form-input v-model="categoryCopy.name" type="text" placeholder="Category name" />
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-button size="sm" variant="info" @click="updateCategory">
        <b-spinner v-if="fetching" small />
        <span v-else>Update</span>
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
    props: { category: Object, recordType: String },
    data() {
        return {
            fetching: false,
            categoryCopy: {},
        };
    },
    watch: {
        /*
         * if a different category is selected (ie: prop change), update local state
         */
        category() {
            this.categoryCopy = Object.assign({}, this.category);
        },
    },
    methods: {
        /*
         * Update category on server then emit categoryUpdated in parent
         */
        updateCategory() {
            this.fetching = true;
            Api.updateCategory(this.recordType, this.categoryCopy)
                .then((res) => {
                    this.fetching = false;
                    this.$bvToast.toast("Data Updated", {
                        title: "Success",
                        variant: "success",
                        autoHideDelay: 2500,
                    });
                    this.$emit("categoryUpdated", res.data);
                })
                .catch((err) => {
                    this.fetching = false;
                    console.log(err.response);
                });
        },
    },
};
</script>