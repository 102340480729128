<template>
  <b-container fluid>
    <b-card>
      <span class="text-secondary">Payee {{ rule.payee_match_type }}: </span>
      <span>{{ rule.payee }}</span>
    </b-card>
    <b-card v-if="rule.rename_payee !== null" class="mt-2">
      <span class="text-secondary">Rename Payee: </span>
      <span>{{ rule.rename_payee }}</span>
    </b-card>
    <b-card v-if="rule.set_record_type !== null" class="mt-2">
      <span class="text-secondary">Set record type: </span>
      <span>{{ rule.set_record_type }}</span>
    </b-card>
    <b-card v-if="rule.set_category !== null" class="mt-2">
      <span class="text-secondary">Set category: </span>
      <span>{{ category }}</span>
    </b-card>
    <b-card v-if="rule.set_tags !== undefined && rule.set_tags.length > 0" class="mt-2">
      <span class="text-secondary">Add tags: </span>
      <b-badge v-for="tag in tags" :key="tag" variant="secondary" class="m-1">
        <b-icon icon="tag" font-scale="1.3" class="mr-1" />
        {{ tag }}
      </b-badge>
    </b-card>
    <b-card v-if="rule.append_note !== null" class="mt-2">
      <span class="text-secondary">Append Note: </span>
      <span>{{ rule.append_note }}</span>
    </b-card>
    <hr>
    <b-row>
      <b-col class="text-right">
        <b-button size="sm" variant="danger" class="mt-2" @click="onClickDelete">
          <b-spinner v-if="fetching" variant="danger" small />
          <span v-else>Delete</span>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
    props: {
        rule: Object,
        accountFullName: String,
        setTagOptions: Array,
        incomeCategoryOptions: Array,
        transactionCategoryOptions: Array,
        savingCategoryOptions: Array,
        ruleDeleted: Function,
    },
    data() {
        return {
            fetching: false,
        };
    },
    computed: {
        category() {
            switch (this.rule.set_record_type) {
                case "income":
                    return this.incomeCategoryOptions.find(
                        (el) => el.id === this.rule.set_category
                    ).name;
                case "transaction":
                    return this.transactionCategoryOptions.find(
                        (el) => el.id === this.rule.set_category
                    ).name;
                case "saving":
                    return this.savingCategoryOptions.find(
                        (el) => el.id === this.rule.set_category
                    ).name;
                default:
                    return null;
            }
        },
        tags() {
            const tag_objects = this.setTagOptions.filter((tag) =>
                this.rule.set_tags.includes(tag.id)
            );
            return tag_objects.map((tag) => tag.name);
        },
    },
    methods: {
        onClickDelete() {
            this.fetching = true;
            Api.deleteRule(this.rule.id)
                .then((res) => {
                    this.fetching = false;
                    this.$emit("ruleDeleted", this.rule);
                })
                .catch((err) => {
                    console.log(err.response);
                    this.fetching = false;
                    this.$bvToast.toast(`Failed to delete rule`, {
                        title: "Failure",
                        variant: "danger",
                        autoHideDelay: 2500,
                    });
                });
        },
    },
};
</script>