<template>
  <b-container fluid>
    <b-row align-h="center" class="mt-2">
      <h5 class="text-secondary">
        Update Account
      </h5>
    </b-row>
    <b-row align-h="center" class="mt-2">
      <b-form-input
        v-model="accountCopy.institution"
        type="text"
        placeholder="Institution"
      />
    </b-row>
    <b-row align-h="center" class="mt-2">
      <b-form-input v-model="accountCopy.name" type="text" placeholder="Name" />
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-button size="sm" variant="info" @click="updateAccount">
        <b-spinner v-if="fetching" small />
        <span v-else>Update</span>
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
    props: { account: Object },
    data() {
        return {
            fetching: false,
            accountCopy: {},
        };
    },
    created() {
        this.accountCopy = Object.assign({}, this.account);
    },
    methods: {
        /*
         * Update account on server then emit accountUpdated in parent
         */
        updateAccount() {
            this.fetching = true;
            Api.updateAccount(this.accountCopy)
                .then((res) => {
                    this.fetching = false;
                    this.$emit("accountUpdated", res.data);
                })
                .catch((err) => {
                    this.fetching = false;
                    console.log(err.response);
                });
        },
    },
};
</script>