<template>
  <div>
    <b-row v-if="numTableRows > 0">
      <b-col cols="6" md="4" lg="3">
        <b-form-input
          v-model="filterText"
          placeholder="Filter results..."
          size="sm"
          type="search"
          debounce="500"
        />
      </b-col>
      <b-col cols="6" md="8" lg="9">
        <b-pagination
          v-if="numTableRows > 20"
          v-model="pageNum"
          align="fill"
          size="sm"
          class="mb-2"
          first-number
          last-number
          :total-rows="numTableRows"
          :per-page="resultsPerPage"
        />
      </b-col>
    </b-row>
    <b-row v-if="fetching && numTableRows === 0" align-h="center" class="mt-2">
      <b-spinner small variant="info" />
    </b-row>
    <b-row v-if="!fetching && numTableRows === 0" class="mt-5" align-h="center">
      <b-col sm="10" md="6" lg="4" class="text-center">
        <b-alert variant="info" show>
          No data available.
        </b-alert>
      </b-col>
    </b-row>
    <b-table
      ref="transactionTable"
      class="mt-2"
      :class="numTableRows === 0 ? 'd-none' : ''"
      responsive
      bordered
      striped
      outlined
      small
      hover
      sticky-header="500px"
      :items="getData"
      :fields="tableFields"
      :filter="filterText"
      :busy.sync="fetching"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :per-page="resultsPerPage"
      :current-page="pageNum"
      :tbody-tr-class="rowClass"
    >
      <template v-slot:table-busy>
        <div class="text-center text-info my-2">
          <b-spinner small class="align-middle" />
        </div>
      </template>
      <template v-slot:cell(buttons)="data">
        <b-button-group>
          <b-button
            v-if="data.item.notes.length > 0"
            size="sm"
            variant="outline-info"
            @click="data.toggleDetails"
          >
            <b-icon
              v-if="!data.detailsShowing"
              icon="caret-down"
              font-scale="1.3"
            />
            <b-icon v-else icon="caret-right" font-scale="1.3" />
          </b-button>
          <b-button
            size="sm"
            variant="outline-info"
            @click="displayUpdateModal(data.item)"
          >
            <b-icon icon="pencil-square" font-scale="1.3" variant="info" />
          </b-button>
          <b-button
            size="sm"
            variant="outline-info"
            @click="displayDeleteModal(data.item)"
          >
            <b-icon icon="x-square" font-scale="1.3" variant="danger" />
          </b-button>
        </b-button-group>
      </template>
      <template v-slot:row-details="data">
        <b-card>
          <b-row>
            <b-col>
              <span>Notes:</span>
              <span class="text-secondary ml-2">{{ data.item.notes }}</span>
            </b-col>
          </b-row>
        </b-card>
      </template>
      <template v-slot:cell(date)="data">
        <span class="text-nowrap text-secondary">
          {{ new Date(data.value).toDateString() }}
        </span>
      </template>
      <template v-slot:cell(payee)="data">
        <span class="text-nowrap text-secondary">
          {{ data.value }}
        </span>
      </template>
      <template v-slot:cell(account)="data">
        <span class="text-nowrap text-secondary">{{ account(data.value).full_name }}</span>
      </template>
      <template v-slot:cell(category)="data">
        <span class="text-nowrap text-secondary">{{ category(data.value).name }}</span>
      </template>
      <template v-slot:cell(amount)="data">
        <span class="text-nowrap text-secondary">
          {{ formatCurrency(data.value, currency) }}
        </span>
      </template>
      <template v-slot:cell(balance)="data">
        <span class="text-nowrap text-secondary">
          {{ formatCurrency(data.value, currency) }}
        </span>
      </template>
      <template v-slot:cell(dividends)="data">
        <span class="text-nowrap text-secondary">
          {{ formatCurrency(data.value, currency) }}
        </span>
      </template>
      <template v-slot:cell(tags)="data">
        <b-badge
          v-for="tagId in data.value"
          :key="tagId"
          variant="transparent"
          class="border border-secondary"
        >
          <b-icon icon="tag" />
          <span class="text-secondary">
            {{ tag(tagId).name }}
          </span>
        </b-badge>
      </template>
    </b-table>
    <b-modal ref="updateRecordModal" hide-footer>
      <b-row>
        <b-col>
          <UpdateForm
            :record="selectedRecord"
            :record-type="recordType"
            @recordUpdated="recordUpdated"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="deleteRecordModal" hide-footer static>
      <b-row>
        <b-col>
          <DeleteForm
            :record="selectedRecord"
            :record-type="recordType"
            @recordDeleted="recordDeleted"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Api from "@/api";
import UpdateForm from "./UpdateForm.vue";
import DeleteForm from "./DeleteForm.vue";
import { formatCurrency } from "@/utils/general.js";

export default {
    components: { UpdateForm, DeleteForm },
    props: ["recordType"],
    data() {
        return {
            fetching: false,
            filterText: "",
            priorFilterText: "",
            tableFields: this.getTableFields(),
            sortBy: "date",
            sortDesc: true,
            resultsPerPage: 20,
            pageNum: 1,
            numTableRows: 0,
            selectedRecord: {},
        };
    },
    computed: {
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
    },
    watch: {
        /*
         * When recordType prop changes, reset params, get categories and refresh table
         */
        recordType() {
            this.tableFields = this.getTableFields();
            this.filterText = "";
            this.pageNum = 1;
            this.sortBy = "date";
            this.sortDesc = true;
            this.dataField = "amount";
            this.getCategoriesData();
            this.$refs.transactionTable.refresh();
        },
    },
    /*
     * Get accounts, tags and categories from server
     */
    created() {
        this.getCategoriesData();
        Api.getAccounts()
            .then((res) => {
                this.$store.commit("records/initAccounts", res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getTags()
            .then((res) => {
                this.$store.commit("records/initTags", res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
    methods: {
        formatCurrency,
        /*
         * Apply row styling if exclude_from_totals is true for the record
         */
        rowClass(item, type) {
            if (!item || type !== "row") return;
            if (item.exclude_from_totals) return "table-warning";
        },
        /*
         * Get categories data from server based on recordType
         */
        getCategoriesData() {
            let categoriesPromise = null;
            switch (this.recordType) {
                case "transaction":
                    categoriesPromise = Api.getTransactionCategories();
                    break;
                case "income":
                    categoriesPromise = Api.getIncomeCategories();
                    break;
                case "saving":
                    categoriesPromise = Api.getSavingCategories();
                    break;
            }
            categoriesPromise
                .then((res) => {
                    this.$store.commit("records/initCategories", res.data);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        /*
         * emitted via Form when transaction updated on server
         */
        recordUpdated() {
            this.$refs.transactionTable.refresh();
            this.$refs["updateRecordModal"].hide();
            this.$bvToast.toast("Data Updated", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * emitted via Form when transaction deleted on server
         */
        recordDeleted() {
            this.$refs.transactionTable.refresh();
            this.$refs["deleteRecordModal"].hide();
            this.$bvToast.toast("Record Deleted", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * Return account object from store using account id
         */
        account(account_id) {
            return this.$store.getters["records/account"](account_id);
        },
        /*
         * Return tag object from store using tag id
         */
        tag(tag_id) {
            return this.$store.getters["records/tag"](tag_id);
        },
        /*
         * Return record object from store using record id
         */
        category(category_id) {
            return this.$store.getters["records/category"](category_id);
        },
        /*
         * Create sort/filter/page params then get record data from server
         */
        getData(ctx) {
            this.fetching = true;
            if (this.filterText !== this.priorFilterText) {
                this.pageNum = 1;
                this.priorFilterText = this.filterText;
            }
            let sortBy = ctx.sortBy !== "" ? ctx.sortBy : "date";
            if (this.recordType !== "saving" && sortBy === "balance") {
                this.sortBy = "date";
                sortBy = "date";
            }
            let sortDirection = ctx.sortDesc ? "-" : "";
            let orderBy = `${sortDirection}${sortBy}`;
            return Api.getFilteredPaginatedSortedRecords(
                this.recordType,
                this.pageNum,
                orderBy,
                ctx.filter
            )
                .then((res) => {
                    this.fetching = false;
                    this.numTableRows = res.data.count;
                    return res.data.transactions;
                })
                .catch((err) => {
                    this.fetching = false;
                    console.log(err.response);
                    return [];
                });
        },
        /*
         * set the selectedRecord and display the edit modal
         */
        displayUpdateModal(record) {
            this.selectedRecord = record;
            this.$refs["updateRecordModal"].show();
        },
        /*
         * set the selectedRecord and display the delete modal
         */
        displayDeleteModal(record) {
            this.selectedRecord = {
                ...record,
                account: this.account(record.account).full_name,
                category: this.category(record.category).name,
                tags: record.tags.map((id) => this.tag(id).name),
            };
            this.$refs["deleteRecordModal"].show();
        },
        /*
         * return table headers
         */
        getTableFields() {
            const fields = [
                { key: "buttons", label: "", stickyColumn: true },
                { key: "date", label: "Date", sortable: true },
                { key: "account", label: "Account", sortable: true },
                { key: "category", label: "Category", sortable: true },
                { key: "payee", label: "Payee", sortable: true },
            ];
            if (this.recordType === "saving") {
                fields.push(
                    { key: "amount", label: "Contribution", sortable: true },
                    { key: "balance", label: "Balance", sortable: true },
                    { key: "dividends", label: "Dividends", sortable: true }
                );
            } else {
                fields.push({ key: "amount", label: "Amount", sortable: true });
            }
            fields.push({ key: "tags", label: "Tags" });
            return fields;
        },
    },
};
</script>