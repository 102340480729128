<template>
  <b-card header-bg-variant="transparent" border-variant="info" body-class="p-0">
    <template v-slot:header>
      <b-row align-h="end">
        <DatePicker
          :selected-date="month"
          :minimum-view="'month'"
          :disabled-dates="disabled_dates"
          input-classes="bg-info text-light"
          @onDateSelected="onDateSelected"
        />
      </b-row>
    </template>
    <highcharts
      ref="chart_ref"
      :update-args="chart_update_args"
      :options="bubbleChartMixinOptions"
    />
  </b-card>
</template>

<script>
import Api from "@/api";
import bubble_chart_mixin from "./chart.js";
import DatePicker from "@/components/date_picker/Index.vue";

export default {
    components: { DatePicker },
    mixins: [bubble_chart_mixin],
    data() {
        return {
            chart_update_args: [true, true, { duration: 3000 }],
            month: "",
            disabled_dates: {
                from: new Date(),
            },
        };
    },
    mounted: function () {
        this.fetchData();
    },
    methods: {
        onDateSelected(date) {
            this.month = date.toISOString().substr(0, 10);
            this.fetchData();
        },
        fetchData() {
            this.$refs.chart_ref.chart.showLoading('<div class="spinner-border"></div>');
            Api.getBlockBubbleChartMonth(this.month)
                .then((res) => {
                    this.month = res.data.month;
                    this.populateChartData(res.data.series);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        populateChartData(series_data) {
            let chart_instance = this.$refs.chart_ref.chart;
            chart_instance.hideLoading();
            while (chart_instance.series.length > 0) {
                chart_instance.series[0].remove(false);
            }
            Object.keys(series_data).forEach((key) => {
                chart_instance.addSeries(series_data[key], false);
            });
            chart_instance.redraw();
        },
    },
};
</script>