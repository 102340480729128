<template>
  <b-container fluid>
    <b-row align-h="center" class="mt-2">
      <h5 class="text-secondary">
        Update Tag
      </h5>
    </b-row>
    <b-row align-h="center" class="mt-2">
      <b-form-input v-model="tag_copy.name" type="text" placeholder="Name" />
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-button size="sm" variant="info" @click="updateTag">
        <b-spinner v-if="fetching" small />
        <span v-else>Update</span>
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
    props: { tag: Object },
    data() {
        return {
            fetching: false,
            tag_copy: {},
        };
    },
    watch: {
        /*
         * if a different tag is selected (ie: prop change), update local state
         */
        tag() {
            this.tag_copy = Object.assign({}, this.tag);
        },
    },
    methods: {
        /*
         * Update tag on server then emit tagUpdated in parent
         */
        updateTag() {
            this.fetching = true;
            Api.updateTag(this.tag_copy)
                .then((res) => {
                    this.fetching = false;
                    this.$bvToast.toast("Data Updated", {
                        title: "Success",
                        variant: "success",
                        autoHideDelay: 2500,
                    });
                    this.$emit("tagUpdated", res.data);
                })
                .catch((err) => {
                    this.fetching = false;
                    console.log(err.response);
                });
        },
    },
};
</script>