<template>
  <b-container fluid class="pt-5 pt-sm-0">
    <b-row>
      <b-col class="text-center">
        <span class="text-secondary h5">Welcome. It's a great day for FI.</span>
      </b-col>
    </b-row>
    <StatsCards />
    <b-row>
      <b-col cols="12" lg="7" class="pt-4">
        <StackedChartHistorical />
      </b-col>
      <b-col cols="12" lg="5" class="pt-4">
        <GroupedBarChartMonth />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" md="7" class="pt-4">
        <AccountStatsMonth />
      </b-col>
      <b-col cols="12" md="5" class="pt-4">
        <BubbleChartMonth />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import BubbleChartMonth from "@/blocks/bubble_chart_month/Index.vue";
import StatsCards from "@/blocks/stats_cards/Index.vue";
import AccountStatsMonth from "@/blocks/account_stats_month/Index.vue";
import StackedChartHistorical from "@/blocks/stacked_chart_historical/Index.vue";
import GroupedBarChartMonth from "@/blocks/grouped_bar_chart_month/Index.vue";

export default {
    components: {
        BubbleChartMonth,
        StatsCards,
        AccountStatsMonth,
        GroupedBarChartMonth,
        StackedChartHistorical,
    },
};
</script>