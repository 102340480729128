<template>
  <div class="mt-4">
    <b-row align-h="end">
      <b-col cols="4" md="2">
        <DatePicker
          :selected-date="selected_month"
          :minimum-view="'month'"
          :disabled-dates="disabled_dates"
          input-classes="bg-info text-light"
          @onDateSelected="onDateSelected"
        />
      </b-col>
    </b-row>
    <b-row v-if="!fetching && transactions_vs_median === ''" class="mt-1">
      <b-col>
        <b-card header-class="bg-transparent">
          <template v-slot:header>
            <span
              class="text-secondary"
            >Overview stats for {{ formatted_date }}</span>
          </template>
          <b-alert variant="danger" show>
            <span class="text-danger">Insufficient data: </span>
            <span class="text-secondary">
              To display this widget for {{ formatted_date }} you need Income,
              Investment and Transaction data for {{ formatted_date }}.
            </span>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else align-v="center" class="mt-1">
      <b-col cols="12" md="4">
        <b-card
          class="text-center"
          style="position: relative; height: 240px; overflow-y: auto"
        >
          <div v-if="fetching" class="text-center">
            <b-spinner small label="Small Spinner" variant="info" />
          </div>
          <div v-else>
            <div v-if="transactions_vs_median !== 0">
              <span class="text-secondary">
                Your {{ selected_month_string }} expenses were
              </span>
              <h5>{{ transactions_vs_median_string }}</h5>
              <span class="text-secondary">than median.</span>
              <hr>
            </div>
            <span class="text-secondary">
              In {{ selected_month_string }} you had
            </span>
            <h5>
              {{ max_freq_transaction_cat.count }}
              {{ max_freq_transaction_cat.category }}
            </h5>
            <span class="text-secondary">transactions.</span>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" class="mt-4 mt-md-0">
        <b-card
          class="text-center"
          style="position: relative; height: 240px; overflow-y: auto"
        >
          <div v-if="fetching" class="text-center">
            <b-spinner small label="Small Spinner" variant="info" />
          </div>
          <div v-else>
            <span class="text-secondary">
              In {{ selected_month_string }} you invested
            </span>
            <h5>{{ spent_vs_invested_string }}</h5>
            <span class="text-secondary">than you spent.</span>
            <hr>
            <span class="text-secondary">
              Largest {{ selected_month_string }} Expense
            </span>
            <h5>{{ max_value_transaction_cat }}</h5>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" class="mt-4 mt-md-0">
        <b-card
          class="text-center"
          style="position: relative; height: 240px; overflow-y: auto"
        >
          <div v-if="fetching" class="text-center">
            <b-spinner small label="Small Spinner" variant="info" />
          </div>
          <div v-else>
            <span
              class="text-secondary"
            >In {{ selected_month_string }} you had
            </span>
            <h5>{{ annual_expenses_saved }} times</h5>
            <span class="text-secondary">your annual expenses invested.</span>
            <hr>
            <span class="text-secondary">
              {{ selected_month_string }} Investment Balance
            </span>
            <h5>{{ formatCurrency(investment_balance, currency) }}</h5>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import format from "date-fns/format";
import Api from "@/api";
import { formatCurrency } from "@/utils/general.js";
import DatePicker from "@/components/date_picker/Index.vue";

export default {
  components: { DatePicker },
  data() {
    return {
      fetching: true,
      selected_month: "",
      selected_month_string: "",
      transactions_vs_median: "",
      max_freq_transaction_cat: "",
      max_value_transaction_cat: "",
      spent_vs_invested_pct: "",
      investment_balance: "",
      annual_expenses_saved: "",
      disabled_dates: {
        from: new Date(),
      },
    };
  },
  computed: {
    formatted_date() {
      if (this.selected_month !== "") {
        return format(new Date(this.selected_month), "MMM yyyy");
      }
      return "";
    },
    currency() {
      return this.$store.getters["appSettings/currency"];
    },
    spent_vs_invested_string() {
      const num = parseFloat(this.spent_vs_invested_pct);
      if (num > 0) {
        return `${this.formatCurrency(num, this.currency)} more`;
      } else if (num < 0) {
        return `${this.formatCurrency(num * -1, this.currency)} less`;
      }
      return "";
    },
    transactions_vs_median_string() {
      const num = parseFloat(this.transactions_vs_median);
      if (num > 0) {
        return `${this.formatCurrency(num, this.currency)} less`;
      } else if (num < 0) {
        return `${this.formatCurrency(num * -1, this.currency)} more`;
      }
      return "";
    },
  },
  mounted: function () {
    this.fetchData();
  },
  methods: {
    formatCurrency,
    onDateSelected(date) {
      this.selected_month = date.toISOString().substr(0, 10);
      this.selected_month_string = date.toLocaleString("default", {
        month: "long",
      });
      this.fetchData();
    },
    fetchData() {
      this.fetching = true;
      Api.getBlockStatsCards(this.selected_month)
        .then((res) => {
          this.fetching = false;
          this.transactions_vs_median = res.data.transactions_vs_median;
          this.max_freq_transaction_cat = res.data.max_freq_transaction_cat;
          this.max_value_transaction_cat = res.data.max_value_transaction_cat;
          this.spent_vs_invested_pct = res.data.spent_vs_invested_pct;
          this.investment_balance = res.data.investment_balance;
          this.annual_expenses_saved = res.data.annual_expenses_saved;
          this.selected_month = res.data.month;
          this.selected_month_string = new Date(
            res.data.month
          ).toLocaleString("default", { month: "long" });
        })
        .catch((err) => {
          this.fetching = false;
          console.log(err.response);
        });
    },
  },
};
</script>