<template>
  <b-container fluid>
    <b-row align-h="center" class="mt-2">
      <h5 class="text-secondary text-center">
        Are you sure you want to delete this category?
      </h5>
    </b-row>
    <b-row align-h="center" class="mt-2">
      <span>{{ category.name }}</span>
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-button size="sm" variant="danger" @click="deleteCategory">
        <b-spinner v-if="fetching" small />
        <span v-else>Delete</span>
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
    props: { category: Object, recordType: String },
    data() {
        return {
            fetching: false,
        };
    },
    methods: {
        /*
         * Delete category on server then emit categoryDeleted in parent
         */
        deleteCategory() {
            this.fetching = true;
            Api.deleteCategory(this.recordType, this.category.id)
                .then((res) => {
                    this.fetching = false;
                    this.$bvToast.toast("Category Deleted", {
                        title: "Success",
                        variant: "success",
                        autoHideDelay: 2500,
                    });
                    this.$emit("categoryDeleted");
                })
                .catch((err) => {
                    this.fetching = false;
                    this.$bvToast.toast(
                        "Make sure there are no transactions associated with this category before deleting it.",
                        {
                            title: "Category Not Deleted",
                            variant: "danger",
                            autoHideDelay: 2500,
                        }
                    );
                    console.log(err.response);
                });
        },
    },
};
</script>