// vue imports
import Vue from "vue";

// bootstrap-vue plugins
import { LayoutPlugin, ToastPlugin, VBPopover } from "bootstrap-vue";

// bootstrap-vue components
import {
  BTable,
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BTbody,
  BButton,
  BFormInput,
  BCard,
  BFormSelect,
  BNavbar,
  BForm,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BPagination,
  BFormFile,
  BAlert,
  BFormTextarea,
  BFormCheckbox,
  BModal,
  BCardText,
  BProgress,
  BProgressBar,
  BSpinner,
  BSidebar,
  BInputGroup,
  BPopover,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BBadge,
  BDropdownItemButton,
  BCollapse,
  BButtonGroup,
  BDropdownHeader,
  BCardGroup,

  // icons
  BIcon,
  BIconTrophy,
  BIconGraphUp,
  BIconTable,
  BIconHammer,
  BIconUpload,
  BIconCreditCard,
  BIconList,
  BIconTag,
  BIconGift,
  BIconLink45deg,
  BIconPencilSquare,
  BIconXSquare,
  BIconPlusSquare,
  BIconInfoCircle,
  BIconArrowRepeat,
  BIconArrowLeft,
  BIconPlusCircle,
  BIconBarChart,
  BIconSoundwave,
  BIconCalendar,
  BIconCaretDown,
  BIconCaretRight,
  BIconCircle,
  BIconCheckCircle,
  BIconExclamationOctagon,
  BIconBoxArrowUpRight,
  BIconClipboardData,
  BIconSearch,
  BIconArrow90degLeft,
  BIconPieChartFill,
  BIconBarChartFill,
  BIconFunnelFill,
  BIconGearFill,
  BIconFolderFill,
  BIconBuilding,
  BIconBook,
  BIconPersonFill,
} from "bootstrap-vue";

// bootstrap-vue directives
import { VBModal, VBToggle } from "bootstrap-vue";

// highcharts
import Highcharts from "highcharts/highcharts";
import XRange from "highcharts/modules/xrange";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsMore from "highcharts/highcharts-more";
import SeriesLabel from "highcharts/modules/series-label";
import StreamGraph from "highcharts/modules/streamgraph";
import Annotations from "highcharts/modules/annotations";
import Exporting from "highcharts/modules/exporting";
import HighchartsVue from "highcharts-vue";

// project imports
import App from "./App.vue";
import highchartsTheme from "./highcharts_theme.js";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";

Vue.config.productionTip = false;

// bootstrap-vue components
Vue.component("b-form", BForm);
Vue.component("b-form-radio-group", BFormRadioGroup);
Vue.component("b-table", BTable);
Vue.component("b-table-simple", BTableSimple);
Vue.component("b-thead", BThead);
Vue.component("b-th", BTh);
Vue.component("b-tr", BTr);
Vue.component("b-td", BTd);
Vue.component("b-tbody", BTbody);
Vue.component("b-button", BButton);
Vue.component("b-form-input", BFormInput);
Vue.component("b-card", BCard);
Vue.component("b-form-select", BFormSelect);
Vue.component("b-nav-bar", BNavbar);
Vue.component("b-form-radio", BFormRadio);
Vue.component("b-tabs", BTabs);
Vue.component("b-tab", BTab);
Vue.component("b-pagination", BPagination);
Vue.component("b-form-file", BFormFile);
Vue.component("b-alert", BAlert);
Vue.component("b-form-textarea", BFormTextarea);
Vue.component("b-form-checkbox", BFormCheckbox);
Vue.component("b-card-text", BCardText);
Vue.component("b-progress", BProgress);
Vue.component("b-progress-bar", BProgressBar);
Vue.component("b-spinner", BSpinner);
Vue.component("b-sidebar", BSidebar);
Vue.component("b-modal", BModal);
Vue.component("b-input-group", BInputGroup);
Vue.component("b-popover", BPopover);
Vue.component("b-dropdown", BDropdown);
Vue.component("b-dropdown-item", BDropdownItem);
Vue.component("b-form-datepicker", BFormDatepicker);
Vue.component("b-badge", BBadge);
Vue.component("b-dropdown-item-button", BDropdownItemButton);
Vue.component("b-collapse", BCollapse);
Vue.component("b-button-group", BButtonGroup);
Vue.component("b-dropdown-header", BDropdownHeader);
Vue.component("b-card-group", BCardGroup);

// bootstrap-vue directives
Vue.directive("b-modal", VBModal);
Vue.directive("b-toggle", VBToggle);
Vue.directive("b-popover", VBPopover);

// bootstrap-vue plugins
Vue.use(ToastPlugin);
Vue.use(LayoutPlugin);

// bootstrap-vue icons
Vue.component("BIcon", BIcon);
Vue.component("BIconGraphUp", BIconGraphUp);
Vue.component("BIconTable", BIconTable);
Vue.component("BIconHammer", BIconHammer);
Vue.component("BIconUpload", BIconUpload);
Vue.component("BIconCreditCard", BIconCreditCard);
Vue.component("BIconList", BIconList);
Vue.component("BIconTrophy", BIconTrophy);
Vue.component("BIconTag", BIconTag);
Vue.component("BIconGift", BIconGift);
Vue.component("BIconLink45deg", BIconLink45deg);
Vue.component("BIconPencilSquare", BIconPencilSquare);
Vue.component("BIconXSquare", BIconXSquare);
Vue.component("BIconPlusSquare", BIconPlusSquare);
Vue.component("BIconInfoCircle", BIconInfoCircle);
Vue.component("BIconArrowRepeat", BIconArrowRepeat);
Vue.component("BIconArrowLeft", BIconArrowLeft);
Vue.component("BIconPlusCircle", BIconPlusCircle);
Vue.component("BIconBarChart", BIconBarChart);
Vue.component("BIconSoundwave", BIconSoundwave);
Vue.component("BIconCalendar", BIconCalendar);
Vue.component("BIconCaretDown", BIconCaretDown);
Vue.component("BIconCaretRight", BIconCaretRight);
Vue.component("BIconCheckCircle", BIconCheckCircle);
Vue.component("BIconCircle", BIconCircle);
Vue.component("BIconExclamationOctagon", BIconExclamationOctagon);
Vue.component("BIconBoxArrowUpRight", BIconBoxArrowUpRight);
Vue.component("BIconClipboardData", BIconClipboardData);
Vue.component("BIconSearch", BIconSearch);
Vue.component("BIconArrow90degLeft", BIconArrow90degLeft);
Vue.component("BIconPieChartFill", BIconPieChartFill);
Vue.component("BIconBarChartFill", BIconBarChartFill);
Vue.component("BIconFunnelFill", BIconFunnelFill);
Vue.component("BIconGearFill", BIconGearFill);
Vue.component("BIconFolderFill", BIconFolderFill);
Vue.component("BIconBuilding", BIconBuilding);
Vue.component("BIconBook", BIconBook);
Vue.component("BIconPersonFill", BIconPersonFill);

// highcharts
HighchartsMore(Highcharts);
XRange(Highcharts);
SolidGauge(Highcharts);
StreamGraph(Highcharts);
SeriesLabel(Highcharts);
Annotations(Highcharts);
Exporting(Highcharts);
Highcharts.setOptions(highchartsTheme);
Vue.use(HighchartsVue);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
