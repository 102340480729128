<template>
  <b-card header-bg-variant="transparent" header-border-variant="info" border-variant="info" body-class="p-0">
    <template v-slot:header>
      <b-row>
        <b-col cols="12" sm="6" class="mt-1">
          <b-dropdown size="sm" variant="info" no-caret class="w-100">
            <template v-slot:button-content>
              <span>Records: </span>
              <span>{{ recordTypeString }} </span>
              <b-icon icon="caret-down" class="float-right" />
            </template>
            <b-dropdown-item-button @click="changeRecordType('income')">
              Income
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeRecordType('transaction')">
              Transactions
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeRecordType('saving')">
              Investments
            </b-dropdown-item-button>
          </b-dropdown>
        </b-col>
        <b-col cols="12" sm="6" class="mt-1">
          <DatePicker
            :selected-date="month"
            :minimum-view="'month'"
            :disabled-dates="disabled_dates"
            input-classes="bg-info text-light"
            @onDateSelected="onDateSelected"
          />
        </b-col>
        <b-col cols="12" sm="6" class="mt-1">
          <b-dropdown size="sm" variant="info" no-caret class="w-100">
            <template v-slot:button-content>
              <span>Group By: </span>
              <span>
                {{ group_by.charAt(0).toUpperCase() + group_by.slice(1) }}
              </span>
              <b-icon icon="caret-down" class="float-right" />
            </template>
            <b-dropdown-item-button @click="changeGroupBy('account')">
              Account
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeGroupBy('category')">
              Category
            </b-dropdown-item-button>
          </b-dropdown>
        </b-col>
        <b-col v-if="record_type === 'saving'" cols="12" sm="6" class="mt-1">
          <b-dropdown size="sm" variant="info" no-caret class="w-100">
            <template v-slot:button-content>
              <span>Data: </span>
              <span>{{ dataFieldString }} </span>
              <b-icon icon="caret-down" class="float-right" />
            </template>
            <b-dropdown-item-button @click="changeDataField('amount')">
              Contribution
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeDataField('balance')">
              Balance
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="changeDataField('dividends')">
              Dividends
            </b-dropdown-item-button>
          </b-dropdown>
        </b-col>
      </b-row>
    </template>
    <b-row v-if="!fetching && chart_categories.length === 0" align-h="center" align-v="center">
      <b-col cols="10" sm="8" md="6">
        <b-alert variant="danger" show size="sm" class="text-center mt-2">
          <span class="text-danger">Insufficient data</span>
        </b-alert>
      </b-col>
    </b-row>
    <div>
      <highcharts
        ref="chart_ref"
        :options="bar_current_and_average_options"
        :update-args="chart_update_args"
      />
    </div>
  </b-card>
</template>

<script>
import Api from "@/api";
import DatePicker from "@/components/date_picker/Index.vue";
import bar_current_and_average_mixin from "@/highcharts/bar_current_and_average.js";

export default {
    components: { DatePicker },
    mixins: [bar_current_and_average_mixin],
    data() {
        return {
            fetching: false,
            month: "",
            group_by: "category",
            record_type: "transaction",
            data_field: "amount",
            chart_data: [],
            chart_categories: [],
            chart_update_args: [true, true, { duration: 3000 }],
            disabled_dates: {
                from: new Date(),
            },
        };
    },
    computed: {
        /*
         * Return record type string based on selected record_type for display in dropdown
         */
        recordTypeString() {
            const options = {
                transaction: "Transactions",
                income: "Income",
                saving: "Investments",
            };
            return options[this.record_type];
        },
        /*
         * Return data field string based on selected data_field for display in dropdown
         */
        dataFieldString() {
            const options = {
                amount: "Contribution",
                balance: "Balance",
                dividends: "Dividends",
            };
            return options[this.data_field];
        },
    },
    mounted: function () {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.fetching = true;
            this.$refs.chart_ref.chart.showLoading('<div class="spinner-border"></div>');
            Api.getBlockGroupedBarChartMonth(
                this.month,
                this.record_type,
                this.group_by,
                this.data_field
            )
                .then((res) => {
                    this.fetching = false;
                    this.month = res.data.month;
                    this.chart_data = res.data.series;
                    this.chart_categories = res.data.categories;
                    this.populateChartData();
                })
                .catch((err) => {
                    this.fetching = false;
                    console.log(err.response);
                });
        },
        onDateSelected(date) {
            this.month = date.toISOString().substr(0, 10);
            this.fetchData();
        },
        populateChartData() {
            let chart_instance = this.$refs.chart_ref.chart;
            chart_instance.hideLoading();
            while (chart_instance.series.length > 0) {
                chart_instance.series[0].remove(false);
            }
            this.chart_data.forEach((data) => {
                chart_instance.addSeries(data, false);
            });
            chart_instance.xAxis[0].update({ categories: this.chart_categories });
            chart_instance.redraw();
        },
        changeGroupBy(group_by) {
            this.group_by = group_by;
            this.fetchData();
        },
        changeRecordType(record_type) {
            this.record_type = record_type;
            if (record_type !== "saving") {
                this.data_field = "amount";
            }
            this.fetchData();
        },
        changeDataField(data_field) {
            this.data_field = data_field;
            this.fetchData();
        },
    },
};
</script>