<template>
  <div>
    <router-view />
    <SideDrawer v-if="!onboardingIncomplete && userIsActive" />
    <b-toast
      variant="info"
      title="An update is available"
      no-close-button
      no-auto-hide
      :visible="updateExists"
    >
      <b-row>
        <b-col class="text-center">
          An app update is ready for you.
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="text-center">
          <b-button variant="info" size="sm" @click="refreshApp">
            Click here to update
          </b-button>
        </b-col>
      </b-row>
    </b-toast>
  </div>
</template>

<script>
import Api from "@/api";
import AppUpdate from "./mixins/app_update.js";
import SideDrawer from "@/components/side_drawer/Index.vue";
import store from "./store";

export default {
  name: "App",
  components: { SideDrawer },
  mixins: [AppUpdate],
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated;
    },
    onboardingIncomplete() {
      return this.$store.getters["onboarding/onboardingIncomplete"];
    },
    userIsActive() {
      return this.$store.getters['signup/userIsActive'];
    },
  },
  /*
   * Retrieve app settings if user isAuthenticated.
   * Nav to Onboarding if user is missing data.
   * Nav to Signup if user is not active
   */
  created() {
    if (this.isAuthenticated) {
      this.$store.dispatch("appSettings/getAppSettings").catch((err) => {
        this.$bvToast.toast("Failed to download app settings", {
          title: "Failure",
          variant: "danger",
          autoHideDelay: 2500,
        });
      });
      Api.getDataIntegrity()
        .then((res) => {
          this.$store.commit("onboarding/updateDataIntegrity", res.data);
          if (this.onboardingIncomplete && this.$route.name !== "Onboarding") {
            this.$router.push({ name: "Onboarding" });
          } else {
            Api.userActiveStatus()
              .then((res) => {
                this.$store.commit("signup/updateUserActiveStatus", res.data);
                if (
                  (!res.data.user_is_within_trial && !res.data.user_is_paidup)
                  && this.$route.name !== "Signup") {
                    this.$router.push({ name: "Signup" });
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "./assets/scss/custom.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

// global style overrides
body {
  margin-top: 20px;
  background-color: #fafafa;
}
.back-button {
  z-index: 1000;
  position: fixed;
  left: 10px;
  top: 60px;
}
</style>
