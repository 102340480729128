<template>
  <b-container fluid>
    <b-row v-if="auth.status == 'error'" align-h="center" class="mt-4">
      <b-col sm="8" md="6">
        <b-alert variant="danger" show>
          Unable to login. Check your credentials.
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="mt-5" align-h="center" align-v="center">
      <b-col cols="8" sm="6" md="4" xl="2">
        <img src="@/assets/img/logo.svg" class="img-fluid">
      </b-col>
      <b-col cols="10" sm="6" md="4" class="mt-4 mt-md-0">
        <b-card>
          <b-form @submit="login">
            <b-row>
              <b-col>
                <b-form-input
                  v-model="formData.username"
                  placeholder="Email"
                  size="sm"
                />
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-form-input
                  v-model="formData.password"
                  placeholder="Password"
                  type="password"
                  size="sm"
                />
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col class="text-center">
                <b-button
                  variant="info"
                  size="sm"
                  type="submit"
                  :loading="auth.status == 'fetching'"
                  @click="login"
                >
                  <b-spinner v-if="fetching" small />
                  <span v-else>Login</span>
                </b-button>
              </b-col>
            </b-row>
            <hr>
            <b-row class="mt-3">
              <b-col class="text-center">
                Are you a new user? <a href="https://www.moneyfi.app/accounts/signup/">Sign up here.</a>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col class="text-center">
                Forgot your password? <a href="https://www.moneyfi.app/accounts/password/reset/">Reset it here.</a>
              </b-col>
            </b-row>            
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";
import { mapState } from "vuex";
import { AUTH_REQUEST } from "@/store/actions/auth";

export default {
    name: "Login",
    data() {
        return {
            fetching: false,
            formData: {
                username: null,
                password: null,
            },
        };
    },
    computed: {
        ...mapState(["auth"]),
    },
    methods: {
        /*
         * Dispatch AUTH_REQUEST to login. If successful, dispatch appSettings/getAppSettings
         * and getDataIntegrity from server. If user is missing data, nav to onboarding else
         * nav to general dashboard
         */
        login(evt) {
            this.fetching = true;
            evt.preventDefault();
            const { username, password } = this.formData;
            this.$store
                .dispatch(AUTH_REQUEST, { username, password })
                .then(() => {
                    this.$store.dispatch("appSettings/getAppSettings").catch((err) => {
                        this.$bvToast.toast("Failed to download app settings", {
                            title: "Failure",
                            variant: "danger",
                            autoHideDelay: 2500,
                        });
                    });
                    this.$store.commit("onboarding/resetState");
                    Api.getDataIntegrity()
                        .then((res) => {
                            this.fetching = false;
                            this.$store.commit("onboarding/updateDataIntegrity", res.data);
                            if (this.$store.getters["onboarding/onboardingIncomplete"]) {
                                this.$router.push({
                                    name: "Onboarding",
                                });
                            } else {
                                this.$router.push({ name: "GeneralDashboard" }, () => {});
                            }
                        })
                        .catch((err) => {
                            this.fetching = false;
                            console.log(err.response);
                        });
                })
                .catch((err) => {
                    this.fetching = false;
                });
        },
    },
};
</script>