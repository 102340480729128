<template>
  <div>
    <supportAlert link="https://www.moneyfi.app/support/data_integrity/" />
    <b-row>
      <b-col>
        <b-card header-class="bg-transparent">
          <template v-slot:header>
            <span>What now? Next steps.</span>
          </template>
          <b-row>
            <b-col>
              <p class="text-secondary">
                Now that you've got the basics of your account set up, where to
                next?
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" sm="6">
        <b-card header-class="bg-transparent">
          <template v-slot:header>
            <span>Analyze your financial habits</span>
          </template>
          <b-row>
            <b-col>
              <p class="text-secondary">
                Enter your financial data weekly, monthly or however often your
                wish.
              </p>
              <p class="text-secondary">
                Income, investment and transaction data can be entered manually via
                the relevant menu items under <strong>My Data</strong>.
              </p>
              <p class="text-secondary">
                Alternatively a faster method of data entry is via the
                <strong>csv importer</strong>. The importer can initially be used to
                populate your historical data. Subsequently it can be used every
                week or month to keep your data up to date.
              </p>
              <p class="text-secondary">
                The <strong>csv importer</strong> has the added benefit of allowing
                you to create import rules which automatically classify your data
                for future imports thus saving you time.
              </p>
              <hr>
              <b-row align-h="center">
                <router-link :to="{ name: 'BulkImport' }">
                  <b-button variant="info" size="sm">
                    Take me to the csv importer
                  </b-button>
                </router-link>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" class="mt-3 mt-sm-0">
        <b-card header-class="bg-transparent">
          <template v-slot:header>
            <span>Creating your FI plan</span>
          </template>
          <b-row>
            <b-col>
              <p class="text-secondary">
                The financial independence component of Money FI allows you to
                create multiple FI Plans. These can optionally include future life
                events which will impact your path to FI.
              </p>
              <p class="text-secondary">
                A <strong>basic plan</strong> allows you to input your starting
                financial position and future life events. A plan is then generated
                based off of the data you entered.
              </p>
              <p class="text-secondary">
                You're able to get started with a <strong>basic plan</strong> right
                now.
              </p>
              <p class="text-secondary">
                An <strong>integrated plan</strong> hooks into all the financial
                data you have within Money FI. Coupled with the future life events
                you enter, a more accurate FI Plan is then generated.
              </p>
              <p class="text-secondary">
                <strong>Integrated plans</strong> continuously update every time you
                enter additional income, investment or transaction data.
              </p>
              <p class="text-secondary">
                To get started with your <strong>integrated plan</strong> you'll
                need to begin entering some financial data. The fastest way to do
                this is via the csv importer (however you can also do this manually
                via the menu items under <strong>My Data</strong>).
              </p>
              <hr>
              <b-row align-h="center">
                <router-link :to="{ name: 'FIPlanIndex' }">
                  <b-button variant="info" size="sm">
                    Create a Basic FI Plan
                  </b-button>
                </router-link>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import supportAlert from "@/components/support_alert/Index.vue";

export default {
    components: { supportAlert },
};
</script>