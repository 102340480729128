<template>
  <b-form id="form">
    <b-row>
      <b-col class="mt-3">
        <b-card>
          <b-row>
            <b-col>
              <b-form-input
                v-model="event.name"
                size="sm"
                placeholder="Event Name"
                required
              />
            </b-col>
          </b-row>
          <b-row v-if="eventType === 'once_off'" class="mt-3">
            <b-col cols="12">
              <DatePicker
                :selected-date="event.date"
                :minimum-view="'month'"
                :disabled-dates="disabledDates"
                input-classes="bg-info text-light"
                @onDateSelected="onDateSelected"
              />
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <div class="mt-3">
                <span class="text-secondary">Start Date</span>
                <DatePicker
                  :selected-date="event.start_date"
                  :minimum-view="'month'"
                  :disabled-dates="disabledDates"
                  input-classes="bg-info text-light"
                  @onDateSelected="onStartDateSelected"
                />
              </div>
              <div class="mt-3">
                <span class="text-secondary">End Date</span>
                <DatePicker
                  :selected-date="event.end_date"
                  :minimum-view="'month'"
                  :disabled-dates="disabledDates"
                  input-classes="bg-info text-light"
                  @onDateSelected="onEndDateSelected"
                />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
        lg="4"
        xl="3"
        class="mt-3"
      >
        <b-card
          :header-bg-variant="expenseActive === false ? 'secondary' : 'transparent'"
          :header-text-variant="expenseActive === false ? 'light' : 'dark'"
        >
          <template v-slot:header>
            <b-row align-h="between">
              <b-form-checkbox
                v-model="expenseActive"
                switch
                @change="onToggleAdjustExpenses"
              >
                Adjust expenses
              </b-form-checkbox>
            </b-row>
          </template>
          <b-popover
            target="id_expense_amount"
            triggers="hover focus"
            placement="top"
            variant="primary"
          >
            <template v-slot:title>
              Adjust Expenses
            </template>
            <p>
              Enter a
              <strong>positive amount</strong> to increase your expenses.
            </p>
            <p>
              Enter a
              <strong>negative amount</strong> to decrease your expenses.
            </p>
          </b-popover>
          <b-row>
            <b-col>
              <b-icon
                id="id_expense_amount"
                icon="info-circle"
                variant="info"
              />
              <label class="ml-1">Amount</label>
            </b-col>
          </b-row>
          <b-input-group size="sm" :prepend="currency">
            <b-form-input
              v-model="event.expense_amount"
              type="number"
              size="sm"
              :disabled="!expenseActive"
            />
          </b-input-group>
          <b-popover
            v-if="eventType == 'recurring'"
            target="id_expense_annual_escalation"
            triggers="hover focus"
            placement="top"
            variant="primary"
          >
            <template v-slot:title>
              Annual escalation
            </template>
            <p>
              Enter a
              <strong>positive percent</strong> to increase this expense annually.
            </p>
            <p>
              Enter a
              <strong>negative percent</strong> to decrease this expense annually.
            </p>
          </b-popover>
          <b-row v-if="eventType == 'recurring'" class="mt-3">
            <b-col>
              <b-icon
                id="id_expense_annual_escalation"
                icon="info-circle"
                variant="info"
              />
              <label class="ml-1">Annual escalation</label>
            </b-col>
          </b-row>
          <b-input-group v-if="eventType == 'recurring'" size="sm" append="%">
            <b-form-input
              v-model="event.expense_annual_escalation"
              type="number"
              size="sm"
              :disabled="!expenseActive"
            />
          </b-input-group>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
        lg="4"
        xl="3"
        class="mt-3"
      >
        <b-card
          :header-bg-variant="contributionActive === false ? 'secondary' : 'transparent'"
          :header-text-variant="contributionActive === false ? 'light' : 'dark'"
        >
          <template v-slot:header>
            <b-row align-h="between">
              <b-form-checkbox
                v-model="contributionActive"
                switch
                @change="onToggleAdjustContributions"
              >
                Adjust contributions
              </b-form-checkbox>
            </b-row>
          </template>
          <b-popover
            target="id_contribution_amount"
            triggers="hover focus"
            placement="top"
            variant="primary"
          >
            <template v-slot:title>
              Adjust Investment Contributions
            </template>
            <p>
              Enter a
              <strong>positive amount</strong> to increase your investment
              contributions.
            </p>
            <p>
              Enter a
              <strong>negative amount</strong> to decrease your investment
              contributions.
            </p>
          </b-popover>
          <b-row>
            <b-col>
              <b-icon
                id="id_contribution_amount"
                icon="info-circle"
                variant="info"
              />
              <label class="ml-1">Amount</label>
            </b-col>
          </b-row>
          <b-input-group size="sm" :prepend="currency">
            <b-form-input
              v-model="event.contribution_amount"
              type="number"
              size="sm"
              :disabled="!contributionActive"
            />
          </b-input-group>
          <b-popover
            v-if="eventType == 'recurring'"
            target="id_contribution_annual_escalation"
            triggers="hover focus"
            placement="top"
            variant="primary"
          >
            <template v-slot:title>
              Annual escalation
            </template>
            <p>
              Enter a
              <strong>positive percent</strong> to increase this contribution
              annually.
            </p>
            <p>
              Enter a
              <strong>negative percent</strong> to decrease this contribution
              annually.
            </p>
          </b-popover>
          <b-row v-if="eventType == 'recurring'" class="mt-3">
            <b-col>
              <b-icon
                id="id_contribution_annual_escalation"
                icon="info-circle"
                variant="info"
              />
              <label class="ml-1">Annual escalation</label>
            </b-col>
          </b-row>
          <b-input-group v-if="eventType == 'recurring'" size="sm" append="%">
            <b-form-input
              v-model="event.contribution_annual_escalation"
              type="number"
              size="sm"
              :disabled="!contributionActive"
            />
          </b-input-group>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
        lg="4"
        xl="3"
        class="mt-3"
      >
        <b-card
          :header-bg-variant="withdrawalActive === false ? 'secondary' : 'transparent'"
          :header-text-variant="withdrawalActive === false ? 'light' : 'dark'"
        >
          <template v-slot:header>
            <b-row align-h="between">
              <b-form-checkbox
                v-model="withdrawalActive"
                switch
                @change="onToggleAdjustWithdrawal"
              >
                Investment withdrawal
              </b-form-checkbox>
            </b-row>
          </template>
          <b-popover
            target="id_withdrawal_amount"
            triggers="hover focus"
            placement="top"
            variant="primary"
          >
            <template v-slot:title>
              Investment Withdrawal
            </template>
            <p>
              Enter a
              <strong>negative amount</strong> indicating a withdrawal from your total
              investment balance.
            </p>
          </b-popover>
          <b-row>
            <b-col>
              <b-icon
                id="id_withdrawal_amount"
                icon="info-circle"
                variant="info"
              />
              <label class="ml-1">Pre-tax withdrawal</label>
            </b-col>
          </b-row>
          <b-input-group size="sm" :prepend="currency">
            <b-form-input
              v-model="event.withdrawal_amount"
              type="number"
              size="sm"
              max="0"
              :disabled="!withdrawalActive"
            />
          </b-input-group>
          <b-popover
            target="id_withdrawal_tax"
            triggers="hover focus"
            placement="top"
            variant="primary"
          >
            <template v-slot:title>
              Withdrawal Tax
            </template>
            <p>
              Enter a
              <strong>positive percent</strong> indicating the tax payable on this
              withdrawal.
            </p>
          </b-popover>
          <b-row class="mt-3">
            <b-col>
              <b-icon
                id="id_withdrawal_tax"
                icon="info-circle"
                variant="info"
              />
              <label class="ml-1">Withdrawal Tax</label>
            </b-col>
          </b-row>
          <b-input-group size="sm" append="%">
            <b-form-input
              v-model="event.withdrawal_tax"
              type="number"
              size="sm"
              min="0"
              step="0.1"
              :disabled="!withdrawalActive"
            />
          </b-input-group>
          <b-popover
            target="id_withdrawal_post_tax"
            triggers="hover focus"
            placement="top"
            variant="primary"
          >
            <template v-slot:title>
              Post-tax amount
            </template>
            <p>
              This is the final amount available to you after
              <strong>{{ event.withdrawal_tax }}%</strong> tax has been paid on the
              withdrawal.
            </p>
          </b-popover>
          <b-row class="mt-3">
            <b-col>
              <b-icon
                id="id_withdrawal_post_tax"
                icon="info-circle"
                variant="info"
              />
              <label class="ml-1">Post-tax amount</label>
            </b-col>
          </b-row>
          <b-input-group size="sm" :prepend="currency">
            <b-form-input
              :value="calcWithdrawalPostTax"
              type="number"
              size="sm"
              min="0"
              disabled
            />
          </b-input-group>
          <b-popover
            v-if="eventType == 'recurring'"
            target="id_withdrawal_annual_escalation"
            triggers="hover focus"
            placement="top"
            variant="primary"
          >
            <template v-slot:title>
              Annual escalation
            </template>
            <p>
              Enter a
              <strong>positive percent</strong> to increase this withdrawal annually.
            </p>
            <p>
              Enter a
              <strong>negative percent</strong> to decrease this withdrawal annually.
            </p>
          </b-popover>
          <b-row v-if="eventType == 'recurring'" class="mt-3">
            <b-col>
              <b-icon
                id="id_withdrawal_annual_escalation"
                icon="info-circle"
                variant="info"
              />
              <label class="ml-1">Annual escalation</label>
            </b-col>
          </b-row>
          <b-input-group v-if="eventType == 'recurring'" size="sm" append="%">
            <b-form-input
              v-model="event.withdrawal_annual_escalation"
              type="number"
              size="sm"
              :disabled="!withdrawalActive"
            />
          </b-input-group>
        </b-card>
      </b-col>
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-button variant="info" size="sm" @click="updateEvent">
        Update Event
      </b-button>
    </b-row>
  </b-form>
</template>

<script>
import DatePicker from "@/components/date_picker/Index.vue";
import store from "../../../store";

export default {
    components: { DatePicker },
    props: { eventType: String, eventName: String },
    data() {
        return {
            event: {},
            disabledDates: {
                to: new Date(),
            },
            expenseActive: false,
            contributionActive: false,
            withdrawalActive: false,
        };
    },
    computed: {
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
        calcWithdrawalPostTax() {
            let amount =
                this.event.withdrawal_amount -
                this.event.withdrawal_amount * (this.event.withdrawal_tax / 100);
            return (amount * -1).toFixed(2);
        },
    },
    created() {
        const stored_event = this.$store.getters["fi_plan/getEvent"]({
            eventType: this.eventType,
            eventName: this.eventName,
        });
        if (this.eventType === "once_off") {
            this.event = {
                ...stored_event,
                date: new Date(stored_event["date"]).toISOString().substr(0, 10),
            };
        } else {
            this.event = {
                ...stored_event,
                start_date: new Date(stored_event["start_date"]).toISOString().substr(0, 10),
                end_date: new Date(stored_event["end_date"]).toISOString().substr(0, 10),
            };
        }
        if (this.event.expense_amount !== 0) {
            this.expenseActive = true;
        }
        if (this.event.withdrawal_amount !== 0) {
            this.withdrawalActive = true;
        }
        if (this.event.contribution_amount !== 0) {
            this.contributionActive = true;
        }
    },
    methods: {
        /*
         * Emited via DatePicker when a date (ie: once off event) is selected
         */
        onDateSelected(date) {
            this.event.date = date.toISOString().substr(0, 10);
        },
        /*
         * Emited via DatePicker when a start_date (ie: recurring event) is selected
         */
        onStartDateSelected(date) {
            this.event.start_date = date.toISOString().substr(0, 10);
        },
        /*
         * Emited via DatePicker when a end_date (ie: recurring event) is selected
         */
        onEndDateSelected(date) {
            this.event.end_date = date.toISOString().substr(0, 10);
        },
        /*
         * Set expense values to 0 when toggling off 'Adjust expenses'
         */
        onToggleAdjustExpenses() {
            if (this.expenseActive) {
                this.event.expense_amount = 0;
                this.event.expense_annual_escalation = 0;
            }
        },
        /*
         * Set contribution values to 0 when toggling off 'Adjust contributions'
         */
        onToggleAdjustContributions() {
            if (this.contributionActive) {
                this.event.contribution_amount = 0;
                this.event.contribution_annual_escalation = 0;
            }
        },
        /*
         * Set withdrawal values to 0 when toggling off 'Adjust withdrawals'
         */
        onToggleAdjustWithdrawal() {
            if (this.withdrawalActive) {
                this.event.withdrawal_amount = 0;
                this.event.withdrawal_tax = 0;
            } else {
                this.event.withdrawal_amount = -1;
            }
        },
        /*
         * If form is valid and name is unique, update event in store (rename first
         * if name was changed)
         */
        updateEvent() {
            const form = document.getElementById("form");
            const nameExists = this.$store.getters["fi_plan/eventNameExists"]({
                eventType: this.eventType,
                eventName: this.event.name,
            });
            if (nameExists && this.eventName !== this.event.name) {
                this.$bvToast.toast(
                    "Event with this name already exist. Event names must be unique.",
                    {
                        title: "Failure",
                        variant: "danger",
                        autoHideDelay: 2500,
                    }
                );
            } else if (
                form.checkValidity() === true &&
                (this.event.expense_amount !== 0) |
                    (this.event.contribution_amount !== 0) |
                    (this.event.withdrawal_amount !== 0)
            ) {
                const data = {
                    eventType: this.eventType,
                    event: {
                        ...this.event,
                    },
                };
                if (this.eventName !== this.event.name) {
                    this.$store.commit("fi_plan/renameEvent", {
                        eventType: this.eventType,
                        oldName: this.eventName,
                        newName: this.event.name,
                    });
                    this.$store.commit("fi_plan/createUpdateEvent", data);
                } else {
                    this.$store.commit("fi_plan/createUpdateEvent", data);
                }
                this.$emit("eventUpdated");
            } else {
                form.classList.add("was-validated");
                this.$bvToast.toast(
                    "Ensure you have added at least one of: Expense, Contribution or Withdrawal",
                    {
                        title: "Failure",
                        variant: "danger",
                        autoHideDelay: 2500,
                    }
                );
            }
        },
    },
};
</script>