<template>
  <b-card header-class="bg-transparent">
    <template v-slot:header>
      <span>Set up your transaction categories</span>
    </template>
    <b-row class="mb-3">
      <b-col>
        <p class="text-secondary">
          These categories represent your spending and transactions. Any general
          transactions that aren't investments or sources of income should be classified
          here.
        </p>
        <p class="text-secondary">
          You're welcome to change these initial suggestions.
        </p>
      </b-col>
    </b-row>
    <b-form id="form">
      <b-row v-for="(category, index) in categories" :key="index" class="mt-2">
        <b-col cols="2">
          <b-form-checkbox
            v-if="index !== 0"
            v-model="category.is_active"
          />
        </b-col>
        <b-col cols="5">
          <b-form-input
            v-model="category.name"
            size="sm"
            placeholder="Category Name"
            :disabled="!category.is_active"
            :required="category.is_active"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="text-right">
          <i class="text-secondary">
            * You can always add to or change these after the setup process under
            <strong>My Data</strong>.
          </i>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col class="text-right">
          <b-button size="sm" variant="info" @click="onClickNext">
            <b-spinner v-if="fetching" small />
            <span v-else>Save & Next Step</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Api from "@/api";

export default {
    data() {
        return {
            fetching: false,
            categories: [
                {
                    is_active: true,
                    name: "Groceries",
                },
                {
                    is_active: true,
                    name: "Transport",
                },
                {
                    is_active: true,
                    name: "Mortgage",
                },
                {
                    is_active: true,
                    name: "Restaurants",
                },
                {
                    is_active: true,
                    name: "Medical",
                },
                {
                    is_active: true,
                    name: "Utilities",
                },
                {
                    is_active: true,
                    name: "Clothing",
                },
                {
                    is_active: false,
                    name: "",
                },
                {
                    is_active: false,
                    name: "",
                },
            ],
        };
    },
    methods: {
        onClickNext() {
            const form = document.getElementById("form");
            if (form.checkValidity()) {
                this.fetching = true;
                const data = this.categories.filter((el) => el.is_active);
                Api.createCategory("transaction", data)
                    .then((res) => {
                        this.fetching = false;
                        this.$store.commit(
                            "onboarding/updateNumTransactionCategories",
                            res.data.length
                        );
                        this.$store.commit("onboarding/incrementOnboardingStep");
                    })
                    .catch((err) => {
                        this.fetching = false;
                        this.$bvToast.toast("Failed to create categories", {
                            title: "Failed",
                            variant: "danger",
                            autoHideDelay: 2500,
                        });
                        console.log(err.response);
                    });
            } else {
                form.classList.add("was-validated");
            }
        },
    },
};
</script>