<template>
  <b-container fluid>
    <b-card>
      <b-row>
        <b-col>Apply this rule when the payee</b-col>
      </b-row>
      <b-form inline class="mt-2">
        <b-form-select
          v-model="rule.payee_match_type"
          :options="payeeMatchTypeOptions"
          size="sm"
        />
        <b-form-input
          v-model="rule.payee"
          placeholder="Payee"
          size="sm"
          class="mt-2 mt-sm-0 ml-0 ml-sm-2"
        />
      </b-form>
    </b-card>
    <b-card class="mt-3" :bg-variant="rule.set_exclude_from_totals ? 'light' : 'secondary'">
      <b-row>
        <b-col>
          <span :class="rule.set_exclude_from_totals ? 'text-dark' : 'text-light'">
            Exclude from totals
          </span>
        </b-col>
        <b-col class="text-right">
          <b-form-checkbox
            v-model="rule.set_exclude_from_totals"
            switch
          />
        </b-col>
      </b-row>
      <b-row v-if="!rule.set_exclude_from_totals" class="mt-2">
        <b-col>
          <i :class="rule.set_exclude_from_totals ? 'text-secondary' : 'text-light'">
            This record will be <u>included</u> in all calculations.
          </i>
        </b-col>
      </b-row>
      <b-row v-else class="mt-2">
        <b-col>
          <i :class="rule.set_exclude_from_totals ? 'text-secondary' : 'text-light'">
            This record will be <u>excluded</u> from all calculations. Unless explicity
            included in the Query Tool.
          </i>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3" :bg-variant="toggleRenamePayee ? 'light' : 'secondary'">
      <b-row>
        <b-col :class="toggleRenamePayee ? 'text-dark' : 'text-light'">
          Rename Payee
        </b-col>
        <b-col class="text-right">
          <b-form-checkbox
            v-model="toggleRenamePayee"
            switch
            @change="onToggleRenamePayee"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-input
            v-model="rule.rename_payee"
            placeholder="New Payee"
            :disabled="!toggleRenamePayee"
            size="sm"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3" :bg-variant="toggleSetRecordType ? 'light' : 'secondary'">
      <b-row>
        <b-col :class="toggleSetRecordType ? 'text-dark' : 'text-light'">
          Assign record type
        </b-col>
        <b-col class="text-right">
          <b-form-checkbox
            v-model="toggleSetRecordType"
            switch
            @change="onToggleRecordType"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-select
            v-model="rule.set_record_type"
            :options="setRecordTypeOptions"
            size="sm"
            :disabled="!toggleSetRecordType"
            @change="onChangeRecordType"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3" :bg-variant="toggleSetCategory ? 'light' : 'secondary'">
      <b-row>
        <b-col :class="toggleSetCategory ? 'text-dark' : 'text-light'">
          Assign category
        </b-col>
        <b-col class="text-right">
          <b-form-checkbox
            v-model="toggleSetCategory"
            :disabled="rule.set_record_type === null"
            switch
            @change="onToggleSetCategory"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span v-if="rule.set_record_type === null" class="text-light">
            * Record type required
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-select
            v-model="rule.set_category"
            :options="setCategoryOptions"
            :disabled="!toggleSetCategory"
            value-field="id"
            text-field="name"
            size="sm"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3" :bg-variant="toggleSetTags ? 'light' : 'secondary'">
      <b-row>
        <b-col :class="toggleSetTags ? 'text-dark' : 'text-light'">
          Add Tags
        </b-col>
        <b-col class="text-right">
          <b-form-checkbox
            v-model="toggleSetTags"
            switch
            @change="onToggleSetTags"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <multiselect
            v-model="rule.set_tags"
            placeholder="Tags"
            label="name"
            track-by="id"
            :options="setTagOptions"
            :multiple="true"
            :close-on-select="false"
            :disabled="!toggleSetTags"
            hide-selected
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3" :bg-variant="toggleAppendNote ? 'light' : 'secondary'">
      <b-row>
        <b-col :class="toggleAppendNote ? 'text-dark' : 'text-light'">
          Append Note
        </b-col>
        <b-col class="text-right">
          <b-form-checkbox
            v-model="toggleAppendNote"
            switch
            @change="onToggleAppendNote"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-textarea
            v-model="rule.append_note"
            :disabled="!toggleAppendNote"
            placeholder="Notes..."
            rows="3"
            max-rows="3"
          />
        </b-col>
      </b-row>
    </b-card>
    <hr>
    <b-row>
      <b-col class="text-right">
        <b-button
          :disabled="!minOneRuleInForce"
          size="sm"
          variant="info"
          @click="onClickSubmit"
        >
          <b-spinner v-if="fetching" variant="info" small />
          <span v-else>{{ submitButtonText }}</span>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";
import Multiselect from "vue-multiselect";

export default {
    components: { Multiselect },
    props: {
        rule: Object,
        ruleUpdatedOrCreated: Function,
        setTagOptions: Array,
        incomeCategoryOptions: Array,
        transactionCategoryOptions: Array,
        savingCategoryOptions: Array,
    },
    data() {
        return {
            fetching: false,
            payeeMatchTypeOptions: [
                { value: "equals", text: "Equals" },
                { value: "contains", text: "Contains" },
            ],
            setRecordTypeOptions: [
                { value: null, text: "Select a record type", disabled: true },
                { value: "income", text: "Income" },
                { value: "transaction", text: "Transaction" },
                { value: "saving", text: "Investment" },
            ],
            setCategoryOptions: [
                {
                    id: null,
                    name: "Select a category",
                    disabled: true,
                },
            ],
            toggleRenamePayee: false,
            toggleSetRecordType: false,
            toggleSetCategory: false,
            toggleSetTags: false,
            toggleAppendNote: false,
        };
    },
    computed: {
        /*
         * return true if at least one rule has been specified, else false
         */
        minOneRuleInForce() {
            if (
                this.rule.payee !== null &&
                this.rule.payee !== "" &&
                (this.rule.rename_payee !== null ||
                    this.rule.set_exclude_from_totals !== false ||
                    this.rule.set_record_type !== null ||
                    this.rule.set_category !== null ||
                    this.rule.set_tags.length > 0 ||
                    this.rule.append_note !== null)
            ) {
                return true;
            }
            return false;
        },
        /*
         * Determine button text based on if the rule has an id (update) or not (create)
         */
        submitButtonText() {
            if (this.rule.id === null || this.rule.id === undefined) {
                return "Create Rule";
            }
            return "Update Rule";
        },
    },
    /*
     * toggle the toggles based on which rules are in effect
     */
    created() {
        if (this.rule.rename_payee === null) {
            this.toggleRenamePayee = false;
        } else {
            this.toggleRenamePayee = true;
        }
        if (this.rule.set_record_type == null) {
            this.toggleSetRecordType = false;
        } else {
            this.onChangeRecordType();
            this.toggleSetRecordType = true;
        }
        if (this.rule.set_category == null) {
            this.toggleSetCategory = false;
        } else {
            this.toggleSetCategory = true;
        }
        if (this.rule.set_tags.length === 0) {
            this.toggleSetTags = false;
        } else {
            this.toggleSetTags = true;
        }
        if (this.rule.append_note == null) {
            this.toggleAppendNote = false;
        } else {
            this.toggleAppendNote = true;
        }
    },
    methods: {
        /*
         * clear rename_payee
         */
        onToggleRenamePayee() {
            this.rule.rename_payee = null;
        },
        /*
         * clear set_record_type and set_category. Disable toggleSetCategory
         */
        onToggleRecordType() {
            this.rule.set_record_type = null;
            this.rule.set_category = null;
            this.toggleSetCategory = false;
        },
        /*
         * clear set_category
         */
        onToggleSetCategory() {
            this.rule.set_category = null;
        },
        /*
         * clear set_tags
         */
        onToggleSetTags() {
            this.rule.set_tags = [];
        },
        /*
         * clear append_note
         */
        onToggleAppendNote() {
            this.rule.append_note = null;
        },
        /*
         * clear update category options when set_record_type is changed
         */
        onChangeRecordType() {
            let categories = [];
            switch (this.rule.set_record_type) {
                case "transaction":
                    categories = [...this.transactionCategoryOptions];
                    break;
                case "income":
                    categories = [...this.incomeCategoryOptions];
                    break;
                case "saving":
                    categories = [...this.savingCategoryOptions];
                    break;
            }
            categories.unshift({
                id: null,
                name: "Select a category",
                disabled: true,
            });
            this.setCategoryOptions = [...categories];
        },
        /*
         * Update rule on server and emit ruleUpdatedOrCreated in parent
         */
        onClickSubmit() {
            this.fetching = true;
            const data = {
                ...this.rule,
                set_tags: this.rule.set_tags.map((el) => el.id),
            };
            if (this.rule.id === undefined || this.rule.id === null) {
                Api.createRule(data)
                    .then((res) => {
                        this.fetching = false;
                        this.$emit("ruleUpdatedOrCreated", {
                            rule: res.data,
                            action: "created",
                        });
                    })
                    .catch((err) => {
                        console.log(err.response);
                        this.fetching = false;
                        this.$bvToast.toast(`Failed to update rule`, {
                            title: "Failure",
                            variant: "danger",
                            autoHideDelay: 2500,
                        });
                    });
            } else {
                Api.updateRule(data)
                    .then((res) => {
                        this.fetching = false;
                        this.$emit("ruleUpdatedOrCreated", {
                            rule: res.data,
                            action: "updated",
                        });
                    })
                    .catch((err) => {
                        console.log(err.response);
                        this.fetching = false;
                        this.$bvToast.toast(`Failed to update rule`, {
                            title: "Failure",
                            variant: "danger",
                            autoHideDelay: 2500,
                        });
                    });
            }
        },
    },
};
</script>