<template>
  <b-container fluid class="pt-5 pt-sm-0 mb-5">
    <supportAlert link="https://www.moneyfi.app/support/bulk_csv_import/rules.html" />
    <b-button variant="info" class="back-button" :to="{ name: 'BulkImport' }">
      <b-icon icon="arrow90deg-left" font-scale="1.3" />
    </b-button>
    <b-row align-h="center">
      <h4>Import Rules</h4>
    </b-row>
    <b-row class="mt-3">
      <b-col class="text-center">
        <span class="text-secondary"> Create import rules for each of your accounts. </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center">
        <span class="text-secondary">
          Rules save you time by defining how your data is classified during csv uploads.
        </span>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-5">
      <b-col cols="12" sm="10" md="6">
        <b-alert show variant="success">
          <h5 class="text-success">
            Understanding how rules work.
          </h5>
          <ol>
            <li>Rules are defined on a per account basis.</li>
            <li>Rules are matched against csv records based on the <i>"Payee".</i></li>
            <li>
              Payee's are case sensitive (ie: <i>"Amazon"</i> is not the same as
              <i>"AMAZON"</i>).
            </li>
            <li>
              Rules are prioritized from top (highest priority) to bottom (lowest
              priority).
            </li>
            <li>
              Only the first (ie: highest priority) rule which matches a record will
              be applied.
            </li>
            <li>
              Rules can be dragged up or down to increase or decrease their priority.
            </li>
          </ol>
        </b-alert>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-4">
      <b-form inline class="justify-content-center">
        <b-form-select
          v-model="account"
          :options="accounts"
          value-field="id"
          text-field="full_name"
          size="sm"
          @change="refreshDisplayedRules"
        />
        <b-button
          size="sm"
          variant="info"
          class="ml-2 mt-1 mt-sm-0"
          :disabled="account === null"
          @click="displayCreateModal()"
        >
          Create Rule
        </b-button>
      </b-form>
    </b-row>
    <b-row v-if="account !== null && rules_for_selected_account.length === 0" align-h="center">
      <b-col cols="12" sm="10" lg="6">
        <b-alert show variant="danger" class="mt-3 text-center">
          This account doesn't have any import rules.
        </b-alert>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-3">
      <draggable
        v-model="rules_for_selected_account"
        tag="b-col"
        class="col-12 col-sm-10 col-lg-6"
        @change="onReorderRule"
      >
        <transition-group name="rule-list">
          <b-card
            v-for="rule in rules_for_selected_account"
            :key="rule.id"
            class="mt-2 rule-card"
          >
            <b-row>
              <b-col>
                <span class="text-secondary">
                  Payee {{ rule.payee_match_type }}:
                </span>
              </b-col>
              <b-col>
                <span>{{ rule.payee }}</span>
              </b-col>
            </b-row>
            <b-row v-if="rule.set_exclude_from_totals">
              <b-col>
                <span class="text-secondary">Exclude from totals: </span>
              </b-col>
              <b-col>
                <span>Yes</span>
              </b-col>
            </b-row>
            <b-row v-if="rule.rename_payee !== null">
              <b-col>
                <span class="text-secondary">Rename Payee: </span>
              </b-col>
              <b-col>
                <span>{{ rule.rename_payee }}</span>
              </b-col>
            </b-row>
            <b-row v-if="rule.set_record_type !== null">
              <b-col>
                <span class="text-secondary">Record Type: </span>
              </b-col>
              <b-col>
                <span>{{ recordTypeString(rule.set_record_type) }}</span>
              </b-col>
            </b-row>
            <b-row v-if="rule.set_category !== null">
              <b-col>
                <span class="text-secondary">Category: </span>
              </b-col>
              <b-col>
                <span>{{
                  getCategoryName(rule.set_category, rule.set_record_type)
                }}</span>
              </b-col>
            </b-row>
            <b-row v-if="rule.set_tags.length">
              <b-col>
                <span class="text-secondary">Tags: </span>
              </b-col>
              <b-col>
                <b-badge
                  v-for="tag_id in rule.set_tags"
                  :key="tag_id"
                  variant="transparent"
                  class="border border-secondary"
                >
                  <b-icon icon="tag" />
                  <span class="text-secondary">
                    {{ getTagName(tag_id) }}
                  </span>
                </b-badge>
              </b-col>
            </b-row>
            <b-row v-if="rule.append_note !== null">
              <b-col>
                <span class="text-secondary">Append Note: </span>
              </b-col>
              <b-col>
                <span>{{ rule.append_note }}</span>
              </b-col>
            </b-row>
            <b-row align-h="end">
              <b-button class="m-1" size="sm" variant="transparent">
                <b-icon
                  icon="pencil-square"
                  font-scale="1.3"
                  variant="info"
                  @click="displayEditModal(rule)"
                />
              </b-button>
              <b-button class="m-1" size="sm" variant="transparent">
                <b-icon
                  icon="x-square"
                  font-scale="1.3"
                  variant="danger"
                  @click="displayDeleteModal(rule)"
                />
              </b-button>
            </b-row>
          </b-card>
        </transition-group>
      </draggable>
    </b-row>
    <!-- 
            Modals
        -->
    <b-modal ref="editCreateRuleModal" title="Import Rule" scrollable hide-footer>
      <b-row>
        <b-col>
          <EditCreateRuleForm
            :rule="selected_Rule"
            :income-category-options="incomeCategoryOptions"
            :transaction-category-options="transactionCategoryOptions"
            :saving-category-options="savingCategoryOptions"
            :set-tag-options="setTagOptions"
            @ruleUpdatedOrCreated="ruleUpdatedOrCreated"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="deleteRuleModal" title="Delete Rule" hide-footer static>
      <b-row>
        <b-col>
          <DeleteRuleForm
            :rule="rule_to_delete"
            :account-full-name="account_full_name"
            :income-category-options="incomeCategoryOptions"
            :transaction-category-options="transactionCategoryOptions"
            :saving-category-options="savingCategoryOptions"
            :set-tag-options="setTagOptions"
            @ruleDeleted="ruleDeleted"
          />
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<style scoped>
.rule-list-move {
    transition: transform 0.5s;
}
.rule-card {
    cursor: grab;
}
</style>

<script>
import draggable from "vuedraggable";
import Api from "@/api";
import EditCreateRuleForm from "./EditCreateRuleForm.vue";
import DeleteRuleForm from "./DeleteRuleForm.vue";
import supportAlert from "@/components/support_alert/Index.vue";

export default {
    components: {
        EditCreateRuleForm,
        DeleteRuleForm,
        draggable,
        supportAlert,
    },
    data() {
        return {
            incomeCategoryOptions: [],
            transactionCategoryOptions: [],
            savingCategoryOptions: [],
            setTagOptions: [],
            accounts: [{ id: null, full_name: "Select an account", disabled: true }],
            account: null,
            rules: [],
            rules_for_selected_account: [],
            selected_Rule: {},
            rule_to_delete: {},
        };
    },
    computed: {
        account_full_name() {
            if (this.account !== "") {
                const account = this.accounts.find((el) => el.id === this.account);
                return account.full_name;
            }
            return "";
        },
    },
    created() {
        Api.getAccounts()
            .then((res) => {
                const accounts = [
                    { id: null, full_name: "Select an account", disabled: true },
                    ...res.data,
                ];
                this.accounts = accounts;
            })
            .catch((err) => {
                this.$bvToast.toast("Error retrieving accounts", {
                    title: "Failed",
                    variant: "danger",
                    autoHideDelay: 2500,
                });
            });
        Api.getRules()
            .then((res) => {
                this.rules = res.data;
            })
            .catch((err) => {
                this.$bvToast.toast("Error retrieving rules", {
                    title: "Failed",
                    variant: "danger",
                    autoHideDelay: 2500,
                });
            });
        Api.getTags()
            .then((res) => {
                this.setTagOptions = res.data;
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getTransactionCategories()
            .then((res) => {
                this.transactionCategoryOptions = res.data;
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getIncomeCategories()
            .then((res) => {
                this.incomeCategoryOptions = res.data;
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getSavingCategories()
            .then((res) => {
                this.savingCategoryOptions = res.data;
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
    methods: {
        /*
         * Reorder rules on server and update locally with the response. If an error
         * is received, update DOM with existing rule order
         */
        onReorderRule(evt) {
            console.log(evt);
            const data = {
                old_index: evt.moved.oldIndex,
                new_index: evt.moved.newIndex,
                rule_id: evt.moved.element.id,
            };
            Api.reorderRules(data)
                .then((res) => {
                    res.data.forEach((updated_rule) => {
                        const index = this.rules.findIndex((rule) => rule.id == updated_rule.id);
                        this.$set(this.rules, index, { ...updated_rule });
                    });
                    this.refreshDisplayedRules(evt.moved.element.account);
                })
                .catch((err) => {
                    this.refreshDisplayedRules(evt.moved.element.account);
                    this.$bvToast.toast("Error updating rule priorities", {
                        title: "Failed",
                        variant: "danger",
                        autoHideDelay: 2500,
                    });
                });
        },
        /*
         * emited via EditCreateRuleForm. Update/add rule and refresh displayed rules.
         */
        ruleUpdatedOrCreated(data) {
            if (data.action === "created") {
                this.rules.push({ ...data.rule });
                this.refreshDisplayedRules(data.rule.account);
                this.$refs["editCreateRuleModal"].hide();
                this.$bvToast.toast(`Rule Created`, {
                    title: "Success",
                    variant: "success",
                    autoHideDelay: 2500,
                });
            } else {
                const index = this.rules.findIndex((rule) => rule.id === data.rule.id);
                this.$set(this.rules, index, { ...data.rule });
                this.refreshDisplayedRules(data.rule.account);
                this.$refs["editCreateRuleModal"].hide();
                this.$bvToast.toast(`Rule Updated`, {
                    title: "Success",
                    variant: "success",
                    autoHideDelay: 2500,
                });
            }
        },
        /*
         * emited via DeleteRuleForm. Remove rule and refresh displayed rules
         */
        ruleDeleted(deleted_rule) {
            const updated_rules = this.rules.filter((rule) => rule.id !== deleted_rule.id);
            this.rules = [...updated_rules];
            this.refreshDisplayedRules(deleted_rule.account);
            this.$refs["deleteRuleModal"].hide();
            this.$bvToast.toast(`Rule Deleted`, {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * set rules_for_selected_account to rules for selected account ordered by priority
         */
        refreshDisplayedRules(account_id) {
            const rules = this.rules.filter((rule) => rule.account == account_id);
            this.rules_for_selected_account = rules.sort((a, b) => b.priority - a.priority);
        },
        displayCreateModal() {
            this.selected_Rule = {
                account: this.account,
                append_note: null,
                payee: null,
                payee_match_type: "equals",
                rename_payee: null,
                set_category: null,
                set_record_type: null,
                set_tags: [],
                set_exclude_from_totals: false,
            };
            this.$refs["editCreateRuleModal"].show();
        },
        /*
         * set selected_Rule and display the edit modal
         */
        displayEditModal(rule) {
            this.selected_Rule = {
                ...rule,
                set_tags: this.setTagOptions.filter((el) => rule.set_tags.includes(el.id)),
            };
            this.$refs["editCreateRuleModal"].show();
        },
        /*
         * set rule_to_delete and display the edit modal
         */
        displayDeleteModal(rule) {
            this.rule_to_delete = { ...rule };
            this.$refs["deleteRuleModal"].show();
        },
        recordTypeString(record_type) {
            switch (record_type) {
                case "transaction":
                    return "Transaction";
                    break;
                case "income":
                    return "Income";
                    break;
                case "saving":
                    return "Investment";
                    break;
            }
        },
        /*
         * Return category name based on id and record_type
         */
        getCategoryName(category_id, record_type) {
            switch (record_type) {
                case "transaction":
                    return this.transactionCategoryOptions.find((el) => el.id === category_id)
                        .name;
                    break;
                case "income":
                    return this.incomeCategoryOptions.find((el) => el.id === category_id).name;
                    break;
                case "saving":
                    return this.savingCategoryOptions.find((el) => el.id === category_id).name;
                    break;
            }
        },
        /*
         * Return tag name based on tag id
         */
        getTagName(tag_id) {
            return this.setTagOptions.find((el) => el.id === tag_id).name;
        },
    },
};
</script>