<template>
  <b-container fluid>
    <b-row>
      <b-col class="text-center">
        <h3 class="text-secondary">
          Query Tool
        </h3>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-2">
      <b-col cols="10" sm="12" class="text-center">
        <span class="text-secondary">
          Filter, query and analyze your finances. The query tool helps you slice and dice
          your data.
        </span>
      </b-col>
    </b-row>
    <HeaderButtons class="mt-5" />
    <b-row class="mt-3">
      <b-col>
        <span>Filtering for: </span><span class="text-secondary">{{ recordTypeString }}</span>
      </b-col>
    </b-row>
    <b-row v-if="!fetching && !dataIsAvailable" class="mt-5" align-h="center">
      <b-col sm="10" md="6" lg="4" class="text-center">
        <b-alert variant="info" show>
          No data available.
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="mt-3" :class="selectedDisplayOption == 'chart_pie' ? '' : 'd-none'">
      <b-col>
        <b-card>
          <ChartPie />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3" :class="selectedDisplayOption == 'spline' ? '' : 'd-none'">
      <b-col>
        <b-card>
          <ChartSpline />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3" :class="selectedDisplayOption == 'column' ? '' : 'd-none'">
      <b-col>
        <b-card>
          <ChartColumn />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3" :class="selectedDisplayOption == 'stacked_column' ? '' : 'd-none'">
      <b-col>
        <b-card>
          <ChartStackedColumn />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3" :class="selectedDisplayOption == 'stream' ? '' : 'd-none'">
      <b-col>
        <b-card>
          <ChartStream />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3" :class="selectedDisplayOption == 'table' ? '' : 'd-none'">
      <b-col>
        <Table />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import HeaderButtons from "./HeaderButtons.vue";
import Api from "@/api";
import Table from "./results/Table.vue";
import ChartPie from "./results/ChartPie.vue";
import ChartSpline from "./results/ChartSpline.vue";
import ChartColumn from "./results/ChartColumn.vue";
import ChartStackedColumn from "./results/ChartStackedColumn.vue";
import ChartStream from "./results/ChartStream.vue";

export default {
    components: {
        HeaderButtons,
        Table,
        ChartPie,
        ChartSpline,
        ChartColumn,
        ChartStackedColumn,
        ChartStream,
    },
    computed: {
        /*
         * Return selected_display_option from store
         */
        selectedDisplayOption() {
            return this.$store.state.query_tool.selected_display_option;
        },
        /*
         * Return true if table data is available in the store (representative of
         * whether or not any data has been returned from the server)
         */
        dataIsAvailable() {
            return this.$store.state.query_tool.results.table_data.length > 0;
        },
        fetching() {
            return this.$store.state.query_tool.fetching;
        },
        recordTypeString() {
            const record_type = this.$store.state.query_tool.params.record_type;
            switch (record_type) {
                case "transaction":
                    return "Transactions";
                    break;
                case "income":
                    return "Income";
                    break;
                case "saving":
                    return "Investments";
                    break;
                default:
                    return "";
                    break;
            }
        },
    },
    created() {
        Api.getAccounts()
            .then((res) => {
                this.$store.commit("query_tool/initAccounts", res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getTransactionCategories()
            .then((res) => {
                this.$store.commit("query_tool/initCategories", {
                    record_type: "transaction",
                    categories: res.data,
                });
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getIncomeCategories()
            .then((res) => {
                this.$store.commit("query_tool/initCategories", {
                    record_type: "income",
                    categories: res.data,
                });
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getSavingCategories()
            .then((res) => {
                this.$store.commit("query_tool/initCategories", {
                    record_type: "saving",
                    categories: res.data,
                });
            })
            .catch((err) => {
                console.log(err.response);
            });
        Api.getTags()
            .then((res) => {
                this.$store.commit("query_tool/initTags", res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
};
</script>