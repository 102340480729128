<template>
  <b-container fluid class="mb-5">
    <div v-if="recordType == 'income'">
      <h3 class="text-center">
        Income
      </h3>
      <b-row class="mt-3">
        <b-col class="text-center">
          <span class="text-secondary">
            Manage your income related data such as salary and rental income.
          </span>
        </b-col>
      </b-row>
    </div>
    <div v-if="recordType == 'saving'">
      <h3 class="text-center">
        Investments
      </h3>
      <b-row class="mt-3">
        <b-col class="text-center">
          <span class="text-secondary">
            Manage your investing related data and categories.
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col class="text-center">
          <i class="text-secondary">
            NB: Remember to log your investment balances at least once each month.
          </i>
        </b-col>
      </b-row>
    </div>
    <div v-if="recordType == 'transaction'">
      <h3 class="text-center">
        Transactions
      </h3>
      <b-row class="mt-3">
        <b-col class="text-center">
          <span class="text-secondary">
            Manage your transactional data such as groceries, mortgage payments,
            insurance etc.
          </span>
        </b-col>
      </b-row>
    </div>
    <b-row class="mt-5">
      <b-col class="text-right">
        <b-button
          :to="{ name: 'CategoryManager', params: { recordType: recordType } }"
          size="sm"
          variant="info"
        >
          <b-icon icon="folder-fill" />
          <span class="ml-2">Manage Categories</span>
        </b-button>
        <b-button
          :to="{ name: 'RecordCreator', params: { recordType: recordType } }"
          size="sm"
          variant="info"
          class="ml-2"
        >
          <b-icon icon="plus-circle" />
          <span class="ml-2">Log New Data</span>
        </b-button>
      </b-col>
    </b-row>
    <Records class="mt-3" :record-type="recordType" />
  </b-container>
</template>

<script>
import Records from "@/components/records/Index.vue";

export default {
    components: { Records },
    props: { recordType: { type: String, default: "transaction" } },
};
</script>