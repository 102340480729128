<template>
  <div>
    <b-row align-h="center">
      <b-popover
        target="id_recurring_events_header"
        triggers="hover focus"
        placement="top"
        variant="primary"
      >
        <template v-slot:title>
          Recurring Events
        </template>
        <p>These events occur every month during the timeframe that you specify.</p>
        <p>They can be adjusted for inflation/annual escalations.</p>
      </b-popover>
      <b-popover
        target="id_once_off_events_header"
        triggers="hover focus"
        placement="top"
        variant="primary"
      >
        <template v-slot:title>
          Once Off Events
        </template>
        <p>These events occur once on a specific date.</p>
      </b-popover>
      <h5 v-if="eventType === 'recurring'" id="id_recurring_events_header">
        <b-icon icon="info-circle" variant="info" class="mr-2" />Recurring Events
      </h5>
      <h5 v-else id="id_once_off_events_header">
        <b-icon icon="info-circle" variant="info" class="mr-2" />Once Off Events
      </h5>
      <b-button size="sm" variant="info" class="ml-2" @click="displayCreateModal">
        <b-icon variant="light" icon="plus-square" />
      </b-button>
    </b-row>
    <hr>
    <b-container style="position: relative; max-height: 480px; overflow-y: auto">
      <b-row v-if="fetching" align-h="center" class="mt-2">
        <b-spinner small class="text-center" variant="info" />
      </b-row>
      <div v-if="events.length === 0 && !fetching">
        <b-row align-h="center" class="mt-2">
          <b-icon icon="exclamation-octagon" variant="warning" font-scale="3" />
        </b-row>
        <h5 class="text-center mt-2">
          No Events
        </h5>
      </div>
      <b-card
        v-for="event in events"
        :key="event.name"
        class="w-100 mt-3"
        bg-variant="light"
        header-bg-variant="transparent"
        footer-bg-variant="transparent"
      >
        <template v-slot:header>
          <b-row align-h="between">
            <div>
              <h5>{{ event.name }}</h5>
            </div>
            <div>
              <b-button size="sm" variant="danger" @click="displayDeleteModal(event)">
                <b-icon variant="light" icon="x-square" />
              </b-button>
              <b-button size="sm" variant="info" class="ml-1">
                <b-icon
                  variant="light"
                  icon="pencil-square"
                  @click="displayUpdateModal(event)"
                />
              </b-button>
            </div>
          </b-row>
          <b-row v-if="eventType === 'recurring'">
            <span>{{ event.start_date }} to {{ event.end_date }}</span>
          </b-row>
          <b-row v-else>
            <span>{{ event.date }}</span>
          </b-row>
          <b-row
            v-if="
              ((eventType === 'recurring') & isHistoricalEvent(event.start_date)) |
                ((eventType === 'once_off') & isHistoricalEvent(event.date))
            "
            class="mt-2"
          >
            <b-alert
              v-b-popover.hover.top="{
                variant: 'info',
                content:
                  'Events that start in the past are ignored. You can safely delete this event.',
              }"
              show
              variant="danger"
              class="p-1"
            >
              <b-icon icon="exclamation-octagon" variant="danger" />
              <span class="ml-2">Historical event will be ignored</span>
            </b-alert>
          </b-row>
        </template>
        <b-row v-if="event.expense_amount !== 0">
          <b-col cols="2">
            <b-icon variant="warning" icon="credit-card" font-scale="2" />
          </b-col>
          <b-col>
            <span
              v-if="event.expense_amount > 0"
            >Increase expenses by {{ event.expense_amount }}.</span>
            <span v-else>Reduce expenses by {{ event.expense_amount * -1 }}.</span>
            <br>
            <span
              v-if="event.expense_annual_escalation > 0"
            >Increase this by {{ event.expense_annual_escalation }}% annually.</span>
            <span
              v-else-if="event.expense_annual_escalation < 0"
            >Decrease this by {{ event.expense_annual_escalation }}% annually.</span>
          </b-col>
        </b-row>
        <hr v-if="(event.contribution_amount !== 0) & (event.expense_amount !== 0)">
        <b-row v-if="event.contribution_amount !== 0">
          <b-col cols="2">
            <b-icon variant="warning" icon="graph-up" font-scale="2" />
          </b-col>
          <b-col>
            <span
              v-if="event.contribution_amount > 0"
            >Increase contributions by {{ event.contribution_amount }}.</span>
            <span
              v-else
            >Reduce contributions by {{ event.contribution_amount * -1 }}.</span>
            <br>
            <span
              v-if="event.contribution_annual_escalation > 0"
            >Increase this by {{ event.contribution_annual_escalation }}%
              annually.</span>
            <span
              v-else-if="event.contribution_annual_escalation < 0"
            >Decrease this by {{ event.contribution_annual_escalation }}%
              annually.</span>
          </b-col>
        </b-row>
        <hr
          v-if="
            (event.withdrawal_amount !== 0) &
              (event.contribution_amount !== 0 || event.expense_amount !== 0)
          "
        >
        <b-row v-if="event.withdrawal_amount !== 0">
          <b-col cols="2">
            <b-icon variant="warning" icon="gift" font-scale="2" />
          </b-col>
          <b-col>
            <span
              v-if="event.withdrawal_amount < 0"
            >Withdraw {{ event.withdrawal_amount * -1 }} from investments.</span>
            <br>
            <span
              v-if="event.withdrawal_annual_escalation > 0"
            >Increase this by {{ event.withdrawal_annual_escalation }}%
              annually.</span>
            <span
              v-else-if="event.withdrawal_annual_escalation < 0"
            >Decrease this by {{ event.withdrawal_annual_escalation }}%
              annually.</span>
          </b-col>
        </b-row>
        <template v-slot:footer>
          <GenerateComparisonCheckbox :event-type="eventType" :event-name="event.name" />
        </template>
      </b-card>
    </b-container>
    <!--
            Modals 
        -->
    <b-modal
      ref="create_modal"
      hide-footer
      size="xl"
      :title="eventType === 'recurring' ? 'Recurring Event' : 'Once Off Event'"
    >
      <CreateForm :event-type="eventType" @eventCreated="eventCreated" />
    </b-modal>
    <b-modal
      ref="update_modal"
      hide-footer
      size="xl"
      :title="eventType === 'recurring' ? 'Recurring Event' : 'Once Off Event'"
    >
      <UpdateForm
        :event-type="eventType"
        :event-name="selectedEvent.name"
        @eventUpdated="eventUpdated"
      />
    </b-modal>
    <b-modal ref="delete_modal" hide-footer>
      <DeleteForm
        :event-type="eventType"
        :event-name="selectedEvent.name"
        @eventDeleted="eventDeleted"
      />
    </b-modal>
    <b-modal
      ref="trial_modal"
      hide-footer
    >
      <b-row align-h="center">
        <b-alert variant="danger" show>
          Maximum number of events reached
        </b-alert>
      </b-row>
      <b-row class="mt-3" align-h="center">
        <b-col class="text-secondary">
          During the trial period you're able to add a maximum of 2 events per FI Plan.
        </b-col>
      </b-row>
      <b-row class="mt-3" align-h="center">
        <b-col class="text-secondary">
          To create an unlimited amount of events, please
          <router-link :to="{ name: 'Signup' }">
            signup for a Money FI plan.
          </router-link>
        </b-col>
      </b-row>      
    </b-modal>    
  </div>
</template>

<script>
import CreateForm from "./forms/EventCreateForm.vue";
import DeleteForm from "./forms/EventDeleteForm.vue";
import UpdateForm from "./forms/EventUpdateForm.vue";
import GenerateComparisonCheckbox from "./GenerateComparisonCheckbox.vue";

export default {
    components: { CreateForm, DeleteForm, UpdateForm, GenerateComparisonCheckbox },
    props: { eventType: String },
    data() {
        return {
            selectedEvent: {},
        };
    },
    computed: {
        /*
         * Return list of events from store based on eventType
         */
        events() {
            if (this.eventType === "recurring") {
                return this.$store.getters["fi_plan/recurringEventList"];
            }
            return this.$store.getters["fi_plan/onceOffEventList"];
        },
        fetching() {
            return this.$store.state.fi_plan.fetching;
        },
    },
    methods: {
        /*
         * Historical events are those prior to the last day of last month
         */
        isHistoricalEvent(eventDate) {
            let date = new Date();
            return new Date(date.setDate(0)) > new Date(eventDate);
        },
        /*
         * Display trial modal if user is within trial, has not made a payment and already has 2 events
         * else display create event modal
         */
        displayCreateModal() {
            const recurringEvents = this.$store.getters["fi_plan/recurringEventList"];
            const onceOffEvents = this.$store.getters["fi_plan/onceOffEventList"];
            if (
              this.$store.state.signup.active_status.user_is_within_trial
              && !this.$store.state.signup.active_status.user_is_paidup
              && recurringEvents.length + onceOffEvents.length >= 2
            ) {
              this.$refs["trial_modal"].show();
            } else {
              this.$refs["create_modal"].show();
            }
        },
        /*
         * hide create modal
         */
        eventCreated() {
            this.$refs["create_modal"].hide();
        },
        /*
         * display delete modal
         */
        displayDeleteModal(event) {
            this.selectedEvent = { ...event };
            this.$refs["delete_modal"].show();
        },
        /*
         * hide delete modal
         */
        eventDeleted() {
            this.$refs["delete_modal"].hide();
        },
        /*
         * display update modal
         */
        displayUpdateModal(event) {
            this.selectedEvent = { ...event };
            this.$refs["update_modal"].show();
        },
        /*
         * hide update modal
         */
        eventUpdated() {
            this.$refs["update_modal"].hide();
        },
    },
};
</script>