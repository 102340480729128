const bar_mixin = {
    data() {
        return {
            bar_options: {
                chart: {
                    type: 'bar'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'category',
                },
                yAxis: {
                    title: {
                        text: null
                    }
                },
                legend: {
                    enabled: true
                },
                plotOptions: {
                    bar: {
                        shadow: false,
                        borderWidth: 0,
                        pointPadding: 0,
                    }
                },
                series: []
            }
        }
    }
}

export default bar_mixin;