<template>
  <b-container fluid>
    <b-row align-h="center">
      <h5>Enter a name for this plan</h5>
    </b-row>
    <b-row class="mt-3" align-h="center">
      <b-form id="plan_name_form">
        <b-form-input v-model="name" size="sm" placeholder="Plan Name" required />
      </b-form>
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-button size="sm" variant="info" @click="onClickSavePlan">
        Save Plan
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/api";

export default {
    props: { planType: String },
    data() {
        return {
            name: "",
        };
    },
    methods: {
        onClickSavePlan() {
            const form = document.getElementById("plan_name_form");
            if (form.checkValidity() === true) {
                this.createPlan();
            } else {
                form.classList.add("was-validated");
            }
        },
        /*
         * create new plan on server with specified name. Upon response clear store
         * and repopulate it with the res data.
         */
        createPlan() {
            const data = {
                ...this.$store.state.fi_plan.plan_params,
                name: this.name,
                once_off_events: this.$store.getters["fi_plan/onceOffEventList"],
                recurring_events: this.$store.getters["fi_plan/recurringEventList"],
            };
            Api.createPlan(this.planType, data)
                .then((res) => {
                    this.$store.commit("fi_plan/resetState");
                    const {
                        once_off_events,
                        recurring_events,
                        ...plan_params
                    } = res.data;
                    this.$store.commit("fi_plan/initOnceOffEvents", once_off_events);
                    this.$store.commit("fi_plan/initRecurringEvents", recurring_events);
                    this.$store.commit("fi_plan/initPlanParams", plan_params);
                    this.$emit("planCreated");
                })
                .catch((err) => {
                    console.log(err.response);
                    this.$bvToast.toast("Please ensure your plan name is unique", {
                        title: "Failed to create plan",
                        variant: "danger",
                        autoHideDelay: 2500,
                    });
                });
        },
    },
};
</script>