<template>
  <b-container fluid>
    <b-row align-h="center">
      <b-col class="text-center">
        <h4>Accounts</h4>
        <p class="text-secondary">
          Manage the instutions with which you hold accounts.
        </p>
      </b-col>
    </b-row>
    <div v-if="fetching" class="text-center mt-4">
      <b-spinner small label="Small Spinner" variant="info" />
    </div>
    <b-row v-if="!fetching" align-h="center" class="mt-3">
      <b-form inline>
        <b-col>
          <b-button size="sm" variant="info" @click="displayCreateModal()">
            Create
          </b-button>
        </b-col>
        <b-col>
          <b-form-input
            v-model="filterBy"
            size="sm"
            type="search"
            placeholder="Filter..."
            class="ml-2"
          />
        </b-col>
      </b-form>
    </b-row>
    <b-row v-if="!fetching && accounts.length === 0" class="mt-5" align-h="center">
      <b-col sm="10" md="6" lg="4" class="text-center">
        <b-alert variant="info" show>
          No data available.
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="!fetching" align-h="center" class="mt-3 mb-5">
      <b-col
        v-for="account in filteredAccounts()"
        :key="account.id"
        cols="12"
        sm="10"
        md="8"
      >
        <b-card class="mt-2">
          <b-row>
            <b-col cols="9">
              <strong>Institution: </strong>
              <span class="text-secondary">{{ account.institution }}</span><br>
              <strong>Name: </strong>
              <span class="text-secondary">{{ account.name }}</span><br>
            </b-col>
            <b-col cols="3">
              <b-row align-h="end">
                <b-button
                  size="sm"
                  variant="transparent"
                  @click="displayUpdateModal(account)"
                >
                  <b-icon
                    icon="pencil-square"
                    font-scale="1.3"
                    variant="info"
                  />
                </b-button>
                <b-button
                  size="sm"
                  variant="transparent"
                  @click="displayDeleteModal(account)"
                >
                  <b-icon
                    icon="x-square"
                    font-scale="1.3"
                    variant="danger"
                  />
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!--
            Modals 
        -->
    <b-modal ref="create_modal" hide-footer>
      <CreateForm @accountCreated="accountCreated" />
    </b-modal>
    <b-modal ref="update_modal" hide-footer>
      <UpdateForm :account="selectedAccount" @accountUpdated="accountUpdated" />
    </b-modal>
    <b-modal ref="delete_modal" hide-footer>
      <DeleteForm :account="selectedAccount" @accountDeleted="accountDeleted" />
    </b-modal>
  </b-container>
</template>

<script>
import Api from "@/api";
import CreateForm from "./CreateForm.vue";
import UpdateForm from "./UpdateForm.vue";
import DeleteForm from "./DeleteForm.vue";

export default {
    components: { CreateForm, UpdateForm, DeleteForm },
    data() {
        return {
            fetching: false,
            accounts: [],
            selectedAccount: {},
            filterBy: "",
        };
    },
    mounted: function () {
        this.fetching = true;
        Api.getAccounts()
            .then((res) => {
                this.fetching = false;
                this.accounts = res.data;
            })
            .catch((err) => {
                this.fetching = false;
                console.log(err.response);
            });
    },
    methods: {
        filteredAccounts() {
            if (this.filterBy === "") {
                return this.accounts;
            } else {
                return this.accounts.filter((obj) =>
                    obj.full_name.toLowerCase().includes(this.filterBy.toLowerCase())
                );
            }
        },
        /*
         * set the selectedAccount and display the delete modal
         */
        displayDeleteModal(account) {
            this.selectedAccount = account;
            this.$refs["delete_modal"].show();
        },
        /*
         * emitted via DeleteForm when account deleted on server
         */
        accountDeleted() {
            this.accounts.splice(this.accounts.indexOf(this.selectedAccount), 1);
            this.$refs["delete_modal"].hide();
            this.$bvToast.toast("Account Deleted", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * set the selectedAccount and display the update modal
         */
        displayUpdateModal(account) {
            this.selectedAccount = account;
            this.$refs["update_modal"].show();
        },
        /*
         * emitted via Form when account updated on server
         */
        accountUpdated(account) {
            const index = this.accounts.indexOf(this.selectedAccount);
            this.$set(this.accounts, index, { ...account });
            this.$refs["update_modal"].hide();
            this.$bvToast.toast("Account Updated", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * Display the create modal
         */
        displayCreateModal() {
            this.$refs["create_modal"].show();
        },
        /*
         * emitted via Form when account created on server
         */
        accountCreated(account) {
            this.accounts.unshift(account);
            this.$refs["create_modal"].hide();
            this.$bvToast.toast("Account Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
    },
};
</script>