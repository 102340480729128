<template>
  <b-container fluid class="pt-5 pt-sm-0 mb-5">
    <b-row align-h="center">
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="check-circle" variant="info" />
              <strong class="ml-2 text-info">Step 1</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">csv file selection</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="check-circle" variant="info" />
              <strong class="ml-2 text-info">Step 2</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Column mapping</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="check-circle" variant="info" />
              <strong class="ml-2 text-info">Step 3</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Classification and rules</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="10"
        sm="8"
        md="5"
        lg="2"
        class="mt-2 mt-lg-0"
      >
        <b-card>
          <b-row>
            <b-col>
              <b-icon icon="circle" variant="secondary" />
              <strong class="ml-2">Step 4</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="text-secondary">Confirm and import</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col class="text-center">
        <h5>Classification</h5>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-2">
      <b-col cols="10" sm="12" class="text-center">
        <span class="text-secondary">
          Classify your csv data prior to import. Optionally create a rule to
          automatically classify records like this in future imports.
        </span>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-5">
      <b-col cols="12" sm="10" lg="6">
        <b-card>
          <b-row>
            <b-col class="text-center">
              <span class="text-secondary">Total: </span>
              <span>{{ numTotalRecords }}</span>
            </b-col>
            <b-col class="text-center">
              <span class="text-secondary">Classified: </span>
              <span>{{ numTotalRecords - unclassifedRecords.length }}</span>
            </b-col>
            <b-col class="text-center">
              <span class="text-secondary">Remaining: </span>
              <span>{{ unclassifedRecords.length }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="record.applied_rule_id !== ''" align-h="center" class="mt-3">
      <b-alert variant="success" show>
        An import rule has been applied to this record
      </b-alert>
    </b-row>
    <b-row v-if="unclassifedRecords.length">
      <b-col>
        <b-form id="form" class="mt-2">
          <b-row align-h="center">
            <b-col cols="12" sm="8" md="6" xl="4">
              <b-card>
                <b-row align-h="center">
                  <b-form-radio-group
                    v-model="record.record_type"
                    :options="record_type_options"
                    required
                    @change="onChangeRecordType($event)"
                  />
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mt-2" align-h="center">
            <b-col cols="12" sm="6" md="4">
              <b-form-datepicker
                v-model="record.date"
                :max="new Date()"
                size="sm"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2" align-h="center">
            <b-col cols="12" sm="6" md="4" class="form-inline">
              <b-dropdown
                size="sm"
                variant="info"
                class="w-75"
                split
                split-variant="outline-info"
                :disabled="this.record.record_type === ''"
              >
                <template v-slot:button-content>
                  <span class="text-wrap text-dark">{{ categoryName }}</span>
                </template>
                <b-dropdown-item-button
                  v-for="category in category_options"
                  :key="category.id"
                  @click="onSelectCategory(category.id)"
                >
                  {{ category.name }}
                </b-dropdown-item-button>
              </b-dropdown>
              <b-button
                variant="info"
                size="sm"
                class="ml-3"
                :disabled="this.record.record_type === ''"
                @click="displayCreateCategoryModal()"
              >
                <b-icon variant="light" icon="plus-square" />
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2" align-h="center">
            <b-col cols="12" sm="6" md="4">
              <b-form-input
                v-model="record.payee"
                type="text"
                placeholder="Payee"
                size="sm"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2" align-h="center">
            <b-col cols="12" sm="6" md="4">
              <b-popover
                target="id_exclude_from_totals_label"
                triggers="hover focus"
                placement="top"
                variant="info"
              >
                <template v-slot:title>
                  Exclude from totals
                </template>
                <p>
                  If checked, this record will not be included in any data
                  aggregations (eg: Dashboards, FI Plan etc).
                </p>
                <p>
                  You will be able to explicitly include this record in data
                  aggregations within the Query Tool.
                </p>
                <p>
                  This is useful for excluding such things as transferring money
                  from one account to another since this should not be considered
                  an expense.
                </p>
              </b-popover>
              <b-form-checkbox v-model="record.exclude_from_totals" switch>
                <span class="text-secondary">Exclude from totals</span>
                <label id="id_exclude_from_totals_label" class="ml-2">
                  <b-icon icon="info-circle" variant="info" />
                </label>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row v-if="record.record_type === 'saving'" class="mt-2" align-h="center">
            <b-col cols="12" sm="6" md="4">
              <b-input-group size="sm" :prepend="currency">
                <b-form-input
                  v-model="record.balance"
                  type="number"
                  placeholder="Balance"
                  size="sm"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="mt-2" align-h="center">
            <b-col cols="12" sm="6" md="4">
              <b-input-group size="sm" :prepend="currency">
                <template v-slot:append>
                  <div
                    v-if="(record.amount > 0) | (record.amount < 0)"
                    class="input-group-text"
                  >
                    <span v-if="record.amount < 0" class="text-danger">
                      debit
                    </span>
                    <span v-if="record.amount > 0" class="text-success">
                      credit
                    </span>
                  </div>
                </template>
                <b-form-input
                  id="amount"
                  v-model="record.amount"
                  type="number"
                  placeholder="Amount"
                  size="sm"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="mt-2" align-h="center">
            <b-col cols="12" sm="6" md="4" class="form-inline">
              <multiselect
                v-model="record.tags"
                class="w-75"
                placeholder="Tags"
                label="name"
                track-by="id"
                :limit="3"
                :options="tagList()"
                :multiple="true"
                :close-on-select="false"
                hide-selected
              />
              <b-button
                variant="info"
                size="sm"
                class="ml-3"
                @click="displayCreateTagModal()"
              >
                <b-icon variant="light" icon="plus-square" />
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2" align-h="center">
            <b-col cols="12" sm="6" md="4">
              <b-form-textarea
                v-model="record.notes"
                placeholder="Notes..."
                rows="3"
                max-rows="3"
              />
            </b-col>
          </b-row>
          <b-row align-h="center" class="mt-3">
            <b-button
              variant="danger"
              size="sm"
              :disabled="disable_create_and_ignore_buttons"
              @click="onClickIgnoreRecord"
            >
              Ignore Record
            </b-button>
            <b-button
              variant="info"
              size="sm"
              class="ml-1"
              :disabled="disable_create_and_ignore_buttons"
              @click="onClickCreateRule"
            >
              Create Rule
            </b-button>
            <b-button
              variant="info"
              size="sm"
              class="ml-1"
              :disabled="!allRequiredFieldsComplete"
              @click="onClickNext"
            >
              Save & Next
            </b-button>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <!-- 
            Modals
        -->
    <b-modal ref="createRuleModal" title="Create Import Rule" scrollable hide-footer>
      <createRuleForm
        :rule="rule"
        :income-category-options="incomeCategoryOptions"
        :transaction-category-options="transactionCategoryOptions"
        :saving-category-options="savingCategoryOptions"
        :set-tag-options="tagList()"
        @ruleUpdatedOrCreated="applyRule"
      />
    </b-modal>
    <b-modal ref="create_category_modal" hide-footer>
      <CreateCategoryForm
        :record-type="record.record_type"
        @categoryCreated="categoryCreated"
      />
    </b-modal>
    <b-modal ref="create_tag_modal" hide-footer>
      <CreateTagForm :record-type="record.record_type" @tagCreated="tagCreated" />
    </b-modal>
  </b-container>
</template>

<script>
import Multiselect from "vue-multiselect";
import CreateCategoryForm from "@/components/category_manager/CreateForm.vue";
import CreateTagForm from "@/components/tag_manager/CreateForm.vue";
import createRuleForm from "@/components/rules/EditCreateRuleForm.vue";

export default {
    components: { Multiselect, createRuleForm, CreateCategoryForm, CreateTagForm },
    data() {
        return {
            record: {
                tags: [],
            },
            rule: {},
            record_type_options: [
                { value: "transaction", text: "Transaction" },
                { value: "income", text: "Income" },
                { value: "saving", text: "Saving" },
            ],
            category_options: [],
            disable_create_and_ignore_buttons: false,
        };
    },
    computed: {
        /*
         * Return true if all required fields are populated
         */
        allRequiredFieldsComplete() {
            if (this.record.record_type !== "saving") {
                return (
                    this.record.payee !== "" &&
                    this.record.record_type !== "" &&
                    this.record.category !== "" &&
                    this.record.amount !== ""
                );
            } else {
                return (
                    this.record.payee !== "" &&
                    this.record.record_type !== "" &&
                    this.record.category !== "" &&
                    this.record.amount !== "" &&
                    this.record.balance !== ""
                );
            }
        },
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
        tagList() {
            return this.$store.getters["importer/tagList"];
        },
        transactionCategoryOptions() {
            return this.$store.getters["importer/transactionCategoryList"];
        },
        incomeCategoryOptions() {
            return this.$store.getters["importer/incomeCategoryList"];
        },
        savingCategoryOptions() {
            return this.$store.getters["importer/savingCategoryList"];
        },
        unclassifedRecords() {
            return this.$store.getters["importer/unclassifedRecords"];
        },
        numTotalRecords() {
            return Object.keys(this.$store.state.importer.data).length;
        },
        categoryName() {
            const category = this.category_options.find((el) => el.id === this.record.category);
            if (category === undefined) {
                return "Category";
            }
            return category.name;
        },
    },
    /*
     * Display first unclassified record else nav to CSVImportTable if all records
     * are already classified
     */
    created() {
        if (this.unclassifedRecords.length) {
            this.record = {
                ...this.unclassifedRecords[0],
                tags: [...this.unclassifedRecords[0].tags],
            };
            this.onChangeRecordType(this.record.record_type);
        } else {
            this.$router.push({ name: "CSVImportTable" });
        }
    },
    methods: {
        onSelectCategory(category_id) {
            this.record.category = category_id;
        },
        /*
         * Update record in store. If this was the last unclassified record nav to
         * import table else display next record.
         */
        onClickNext() {
            this.disable_create_and_ignore_buttons = false;
            const data = {
                ...this.record,
                tags: this.record.tags.map((el) => el.id),
            };
            this.$store.commit("importer/updateRecord", data);
            if (this.unclassifedRecords.length > 0) {
                this.record = { ...this.unclassifedRecords[0] };
                this.onChangeRecordType(this.record.record_type);
            } else {
                this.$router.push({ name: "CSVImportTable" });
            }
        },
        /*
         * Emitted via create rule modal. Disable create/ignore buttons. Update this.record
         * and display toast
         */
        applyRule(new_rule) {
            this.$refs["createRuleModal"].hide();
            const updated_record = {
                ...this.record,
                applied_rule_id: new_rule.rule.id,
                payee:
                    new_rule.rule.rename_payee !== null
                        ? new_rule.rule.rename_payee
                        : this.record.payee,
                record_type:
                    new_rule.rule.set_record_type !== null
                        ? new_rule.rule.set_record_type
                        : this.record.record_type,
                category:
                    new_rule.rule.set_category !== null
                        ? new_rule.rule.set_category
                        : this.record.category,
                tags:
                    new_rule.rule.set_tags.length > 0
                        ? this.tagList().filter((el) => new_rule.rule.set_tags.includes(el.id))
                        : [...this.record.tags],
                notes:
                    new_rule.rule.append_note !== null
                        ? new_rule.rule.append_note
                        : this.record.notes,
                exclude_from_totals: new_rule.rule.set_exclude_from_totals,
            };
            this.disable_create_and_ignore_buttons = true;
            this.onChangeRecordType(updated_record.record_type);
            this.record = {
                ...updated_record,
                tags: [...updated_record.tags],
            };
            this.$bvToast.toast("New rule created and applied to this record", {
                title: "Rule Created",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * Delete record from store. If this was the last unclassified record nav to
         * import table else display next record.
         */
        onClickIgnoreRecord() {
            this.$store.commit("importer/removeRecord", this.unclassifedRecords[0].index);
            if (this.unclassifedRecords.length > 0) {
                this.record = { ...this.unclassifedRecords[0] };
            } else {
                this.$router.push({ name: "CSVImportTable" });
            }
        },
        /*
         * display create_category_modal
         */
        displayCreateCategoryModal() {
            this.$refs["create_category_modal"].show();
        },
        /*
         * set this.rule as a default/empty rule and display createRuleModal
         */
        onClickCreateRule() {
            this.rule = {
                account: this.record.account,
                append_note: null,
                payee: this.record.payee,
                payee_match_type: "equals",
                rename_payee: null,
                set_category: null,
                set_record_type: null,
                set_tags: [],
                set_exclude_from_totals: false,
            };
            this.$refs["createRuleModal"].show();
        },
        /*
         * emitted via CreateCategoryForm when category created on server
         */
        categoryCreated(category) {
            switch (this.record.record_type) {
                case "income":
                    this.$store.commit("importer/createUpdateIncomeCategory", category);
                    break;
                case "saving":
                    this.$store.commit("importer/createUpdateSavingCategory", category);
                    break;
                case "transaction":
                    this.$store.commit("importer/createUpdateTransactionCategory", category);
                    break;
            }
            this.category_options.unshift(category);
            this.record.category = category.id;
            this.$refs["create_category_modal"].hide();
            this.$bvToast.toast("Category Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * display create_tag_modal
         */
        displayCreateTagModal() {
            this.$refs["create_tag_modal"].show();
        },
        /*
         * emitted via CreatetagForm when tag created on server
         */
        tagCreated(tag) {
            this.$store.commit("importer/createUpdateTag", tag);
            this.record.tags.unshift(tag);
            this.$refs["create_tag_modal"].hide();
            this.$bvToast.toast("Tag Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * when record_type is changed, update categoryOptions
         */
        onChangeRecordType(value) {
            this.record.category = "";
            switch (value) {
                case "transaction":
                    this.category_options = this.transactionCategoryOptions;
                    break;
                case "income":
                    this.category_options = this.incomeCategoryOptions;
                    break;
                case "saving":
                    this.category_options = this.savingCategoryOptions;
                    break;
                default:
                    this.category_options = [];
                    break;
            }
        },
    },
};
</script>