const timeLineMixin = {
    data() {
        return {
            timeLineMixinUpdateArgs: [true, true, { duration: 3000 }],
            timeLineMixinOptions: {
                chart: {
                    zoomType: 'x',
                    type: 'line',
                    height: '600px',
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: "FI Timeline - past and future",
                    style: {
                        color: '#ffffff'
                    }
                },
                legend: {
                    enabled: false
                },
                navigation: {
                    buttonOptions: {  // styling of custom buttons (ie: logScale)
                        theme: {
                            'stroke-width': 1,
                            stroke: 'silver',
                            r: 0,
                            states: {
                                hover: {
                                    fill: '#bada55'
                                },
                                select: {
                                    stroke: '#039',
                                    fill: '#bada55'
                                }
                            }
                        }
                    }
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        },
                        logScaleTimeline: {
                            text: 'Log Scale: OFF',
                            // x: -0,
                            onclick: function () {
                                let btn = this.exportSVGElements[0];
                                if (this.yAxis[0].userOptions.type == 'linear') {
                                    btn.attr({ text: 'Log Scale: ON' })
                                    this.yAxis[0].update({ type: "logarithmic" });
                                } else {
                                    btn.attr({ text: 'Log Scale: OFF' })
                                    this.yAxis[0].update({ type: "linear" });
                                }
                            }
                        },
                        logScaleStats: {
                            text: 'Log Scale: OFF',
                            verticalAlign: 'middle',
                            y: 30,
                            x: 0,
                            onclick: function () {
                                let btn = this.exportSVGElements[2];
                                if (this.yAxis[2].userOptions.type == 'linear') {
                                    btn.attr({ text: 'Log Scale: ON' })
                                    this.yAxis[2].update({ type: "logarithmic" });
                                    this.yAxis[3].update({ type: "logarithmic" });
                                } else {
                                    btn.attr({ text: 'Log Scale: OFF' })
                                    this.yAxis[2].update({ type: "linear" });
                                    this.yAxis[3].update({ type: "linear" });
                                }
                            }
                        },
                    }
                },
                plotOptions: {
                    series: {
                        label: {
                            enabled: false
                        },
                        marker: {
                            enabled: false
                        },
                        states: {
                            inactive: {
                                enabled: false,
                            },
                            select: {
                                enabled: true,
                                lineWidth: 2,
                                lineWidthPlus: 0
                            },
                            hover: {
                                enabled: true,
                                lineWidth: 2,
                                lineWidthPlus: 0
                            }
                        }
                    },
                    xrange: {
                        yAxis: 1,
                        groupPadding: 0,
                        pointPadding: 0,
                        maxPointWidth: 20,
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            padding: 0,
                            format: '{point.name}',
                            style: {
                                textOutline: 0,
                                fontWeight: 100,
                                fontSize: '12px',
                                color: '#fff',
                            },
                        },
                        enableMouseTracking: false,
                    }
                },
                tooltip: {
                    shared: true,
                    /*
                     * sort items from highest to lowest
                     */
                    formatter: function (tooltip) {
                        var items = this.points || splat(this),
                            series = items[0].series,
                            s;

                        // sort the values
                        items.sort(function (a, b) {
                            return ((a.y < b.y) ? -1 : ((a.y > b.y) ? 1 : 0));
                        });
                        items.reverse();

                        return tooltip.defaultFormatter.call(this, tooltip);
                    },
                },
                loading: {
                    style: {
                        backgroundColor: '#242F39'
                    }
                },
                xAxis: {
                    type: "datetime",
                    crosshair: true,
                },
                yAxis: [{ // Primary yAxis - balance, target balance and comparison
                    type: 'linear',
                    crosshair: { snap: false },
                    startOnTick: false,
                    title: {
                        text: "Balance vs Target Balance",
                        style: {
                            color: '#ffffff',
                            fontSize: '18px'
                        }
                    },
                    height: '50%',
                }, { // xrange - recurring events
                    crosshair: { snap: false, width: 1 },
                    visible: false,
                    type: 'category',
                    gridLineWidth: 0,
                    minorGridLineWidth: 0,
                    startOnTick: false,
                    labels: {
                        enabled: true
                    },
                    title: {
                        text: "",
                    },
                    top: '50%',
                    height: '50%',
                }, { // stats
                    type: 'linear',
                    opposite: true,
                    crosshair: { snap: false, width: 1 },
                    visible: false,
                    startOnTick: false,
                    title: {
                        text: "",
                    },
                    top: '50%',
                    height: '50%',
                }, { // expenses and contributions
                    type: 'linear',
                    crosshair: { snap: false, width: 1 },
                    visible: true,
                    startOnTick: false,
                    title: {
                        text: "Expenses vs Contributions",
                        style: {
                            color: '#ffffff',
                            fontSize: '18px'
                        }
                    },
                    top: '50%',
                    height: '50%',
                }],
                series: []
            }
        }
    }
}
export default timeLineMixin;