const timeSeriesChartMixin = {
  data() {
    return {
      timeSeriesChartMixinUpdateArgs: [true, true, { duration: 3000 }],
      timeSeriesChartMixinOptions: {
        chart: {
          zoomType: "x",
          type: 'line'
        },
        credits: {
          enabled: false
        },
        title: {
          text: ""
        },
        subtitle: {
          text: ""
        },
        tooltip: {
          shared: true,
          /*
           * sort items from highest to lowest
           */
          formatter: function (tooltip) {
            var items = this.points || splat(this),
              series = items[0].series,
              s;

            // sort the values
            items.sort(function (a, b) {
              return ((a.y < b.y) ? -1 : ((a.y > b.y) ? 1 : 0));
            });
            items.reverse();

            return tooltip.defaultFormatter.call(this, tooltip);
          },
        },
        loading: {
          style: {
            backgroundColor: '#242F39'
          }
        },
        xAxis: {
          type: "datetime",
          crosshair: true,
        },
        yAxis: {
          startOnTick: false,
          title: {
            text: "",
            style: {
              color: "#ffffff"
            }
          },

        },
        plotOptions: {
          series: {
            lineWidth: 4,
            turboThreshold: 0,
            label: {
              enabled: false
            },
            marker: {
              enabled: false
            },
          },
          column: {
            borderWidth: 0
          }
        },
        series: []
      }
    }
  }
}

export default timeSeriesChartMixin;