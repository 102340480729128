<template>
  <b-card header-class="bg-transparent">
    <template v-slot:header>
      <span>Select your currency</span>
    </template>
    <span class="text-secondary">
      This is the currency that will be used throughout Money FI.
    </span>
    <b-form-select
      v-model="currency"
      :options="currencyOptions"
      size="sm"
      class="mt-3"
      @change="saveSettings"
    />
    <hr>
    <b-row>
      <b-col class="text-right">
        <b-button size="sm" variant="info" @click="onClickNext">
          Save & Next Step
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Api from "@/api";

export default {
    data() {
        return {
            currency: "USD",
            currencyOptions: [
                { value: "USD", text: "$ (Dollar)" },
                { value: "EUR", text: "€ (Euro)" },
                { value: "JPY", text: "¥ (Yen / Yuan)" },
                { value: "GBP", text: "£ (Pound)" },
                { value: "INR", text: "₹ (Rupee)" },
                { value: "ZAR", text: "ZAR (Rand)" },
            ],
        };
    },
    created() {
        this.currency = this.$store.getters["appSettings/currency"];
    },
    methods: {
        onClickNext() {
            this.$store.commit("onboarding/incrementOnboardingStep");
        },
        saveSettings() {
            const data = { currency: this.currency };
            Api.updateAppSettings(data)
                .then((res) => {
                    this.$store.commit("appSettings/setAppSettings", res.data);
                    this.$bvToast.toast(`Settings saved`, {
                        title: "Success",
                        variant: "success",
                        autoHideDelay: 2500,
                    });
                })
                .catch((err) => {
                    console.log(err.response);
                    this.$bvToast.toast(`Failed to save settings`, {
                        title: "Failure",
                        variant: "danger",
                        autoHideDelay: 2500,
                    });
                });
        },
    },
};
</script>