const stream_mixin = {
    data() {
        return {
            stream_options: {
                chart: {
                    type: 'streamgraph',
                    zoomType: 'x',
                    spacingBottom: 30,
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                    crosshair: true,
                    labels: {
                        reserveSpace: false,
                    },
                },
                yAxis: {
                    visible: false,
                },
                legend: {
                    margin: 20
                },
                plotOptions: {
                    series: {
                        stacking: 'stream',
                        connectNulls: true,
                    }
                },
                tooltip: {
                    shared: true,
                },
                series: []
            }
        }
    }
}

export default stream_mixin;