import axios from "axios";
import blocks from "./blocks";
import flutterwave from "./flutterwave";

const token = localStorage.getItem("user-token") || "";
axios.defaults.headers.common["Authorization"] = `Token ${token}`;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export default {
  ...blocks,
  ...flutterwave,
  /*
   * get calls
   */
  getToken: (user) => {
    const config = {
      headers: {
        Authorization: "",
        "Content-Type": "application/json",
      },
    };
    return axios.post("/api/get-token/", user, config);
  },
  getAppSettings: () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/accounts/app-settings")
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  userActiveStatus: () => {
    return axios.get("/api/accounts/user-active-status/");
  },
  getDataIntegrity: () => {
    let url = `/api/tracker/data-integrity/`;
    return axios.get(url);
  },
  getAccounts: () => {
    let url = `/api/tracker/account/`;
    return axios.get(url);
  },
  getCSVDateFormats: () => {
    let url = `/api/tracker/csv-date-format/`;
    return axios.get(url);
  },
  getTags: (orderBy = "name", filterText = "") => {
    let url = `/api/tracker/tag/?order_by=${orderBy}&filter_text=${filterText}`;
    return axios.get(url);
  },
  getTransactionCategories: (orderBy = "name", filterText = "") => {
    let url = `/api/tracker/transaction-category/?order_by=${orderBy}&filter_text=${filterText}`;
    return axios.get(url);
  },
  getIncomeCategories: (orderBy = "name", filterText = "") => {
    let url = `/api/tracker/income-category/?order_by=${orderBy}&filter_text=${filterText}`;
    return axios.get(url);
  },
  getSavingCategories: (orderBy = "name", filterText = "") => {
    let url = `/api/tracker/saving-category/?order_by=${orderBy}&filter_text=${filterText}`;
    return axios.get(url);
  },
  getFilteredPaginatedSortedRecords: (
    record_type,
    pageNum,
    orderBy,
    filterText
  ) => {
    let url = `/api/tracker/${record_type}/filter-paginate-sort-records/?page=${pageNum}&order_by=${orderBy}&filter_text=${filterText}`;
    return axios.get(url);
  },
  getPlans: (planType) => {
    if (planType === "basic") {
      return axios.get("/api/fiplan/basic-plan/");
    } else {
      return axios.get("/api/fiplan/integrated-plan/");
    }
  },
  getPlan: (plan_id, plan_type) => {
    if (plan_type === "basic") {
      return axios.get(`/api/fiplan/basic-plan/${plan_id}`);
    } else {
      return axios.get(`/api/fiplan/integrated-plan/${plan_id}`);
    }
  },
  getRules: () => {
    let url = `/api/tracker/rule/`;
    return axios.get(url);
  },
  getQueries: () => {
    let url = `/api/tracker/query/`;
    return axios.get(url);
  },
  getRecordCountsForAccount: (account_id) => {
    return axios.get(
      `/api/tracker/account/${account_id}/record-counts-for-account/`
    );
  },

  /*
   * create calls
   */
  createAccount: (data) => axios.post(`/api/tracker/account/`, data),
  createCategory: (categoryType, data) =>
    axios.post(`/api/tracker/${categoryType}-category/`, data),
  createRule: (data) => axios.post(`/api/tracker/rule/`, data),
  createTag: (data) => axios.post(`/api/tracker/tag/`, data),
  createRecords: (transactionType, data) =>
    axios.post(`/api/tracker/${transactionType}/`, data),
  createRule: (data) => axios.post(`/api/tracker/rule/`, data),
  createPlan: (planType, data) =>
    axios.post(`/api/fiplan/${planType}-plan/`, data),
  createQuery: (data) => axios.post(`/api/tracker/query/`, data),

  /*
   * update calls
   */
  updateAppSettings: (data) => axios.put(`/api/accounts/app-settings/`, data),
  updateCategory: (categoryType, data) =>
    axios.put(`/api/tracker/${categoryType}-category/${data.id}/`, data),
  updateTag: (data) => axios.put(`/api/tracker/tag/${data.id}/`, data),
  updateRecord: (record_type, record) =>
    axios.put(`/api/tracker/${record_type}/${record.id}/`, record),
  updatePlan: (planType, data) => {
    if (planType === "basic") {
      return axios.put(`/api/fiplan/basic-plan/${data.id}/`, data);
    } else {
      return axios.put(`/api/fiplan/integrated-plan/${data.id}/`, data);
    }
  },
  updateAccount: (data) => axios.put(`/api/tracker/account/${data.id}/`, data),
  updateRule: (data) => axios.put(`/api/tracker/rule/${data.id}/`, data),
  updateQuery: (data) => axios.put(`/api/tracker/query/${data.id}/`, data),

  /*
   * delete calls
   */
  deleteAccount: (account_id) =>
    axios.delete(`/api/tracker/account/${account_id}/`),
  deleteCategory: (categoryType, categoryId) =>
    axios.delete(`/api/tracker/${categoryType}-category/${categoryId}/`),
  deleteTag: (tagId) => axios.delete(`/api/tracker/tag/${tagId}/`),
  deleteRule: (rule_id) => axios.delete(`/api/tracker/rule/${rule_id}/`),
  deleteRecord: (record_type, record_id) =>
    axios.delete(`/api/tracker/${record_type}/${record_id}/`),
  deletePlan: (plan_type, plan_id) =>
    axios.delete(`/api/fiplan/${plan_type}-plan/${plan_id}`),

  /*
   * POST calls
   */
  runPlan: (plan_type, plan_params) => {
    if (plan_type === "basic") {
      return axios.post("/api/fiplan/basic-plan/run-plan/", plan_params);
    } else {
      return axios.post("/api/fiplan/integrated-plan/run-plan/", plan_params);
    }
  },
  applyRules: (csv_data) => {
    return axios.post("/api/tracker/rule/apply/", csv_data);
  },
  reorderRules: (data) => {
    return axios.post("/api/tracker/rule/reorder/", data);
  },
  runQuery: (data) => {
    return axios.post("/api/tracker/query/run-query/", data);
  },
};
