<template>
  <b-container fluid>
    <b-row align-h="center">
      <h5 class="text-secondary">
        Update Record
      </h5>
    </b-row>
    <b-row class="mt-3" align-h="center">
      <b-col>
        <b-form-datepicker
          v-model="record.date"
          :max="new Date()"
          size="sm"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col class="form-inline">
        <b-dropdown
          size="sm"
          variant="info"
          class="w-75"
          split
          split-variant="outline-info"
        >
          <template v-slot:button-content>
            <span class="text-wrap">{{ categoryName(record.category) }}</span>
          </template>
          <b-dropdown-item-button
            v-for="category in categoryOptions"
            :key="category.id"
            @click="onSelectCategory(category.id)"
          >
            {{ category.name }}
          </b-dropdown-item-button>
        </b-dropdown>
        <b-button
          variant="info"
          size="sm"
          class="ml-3"
          @click="displayCreateCategoryModal()"
        >
          <b-icon variant="light" icon="plus-square" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col>
        <b-form-input
          v-model="record.payee"
          type="text"
          placeholder="Payee"
          size="sm"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col>
        <b-popover
          target="id_exclude_from_totals_label"
          triggers="hover focus"
          placement="top"
          variant="info"
        >
          <template v-slot:title>
            Exclude from totals
          </template>
          <p>
            If checked, this record will not be included in any data aggregations (eg:
            Dashboards, FI Plan etc).
          </p>
          <p>
            You will be able to explicitly include this record in data aggregations
            within the Query Tool.
          </p>
          <p>
            This is useful for excluding such things as transferring money from one
            account to another since this should not be considered an expense.
          </p>
        </b-popover>
        <b-form-checkbox v-model="record.exclude_from_totals" switch>
          <span class="text-secondary">Exclude from totals</span>
          <label id="id_exclude_from_totals_label" class="ml-2">
            <b-icon icon="info-circle" variant="info" />
          </label>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="record.record_type === 'saving'" class="mt-2" align-h="center">
      <b-col>
        <b-input-group size="sm" :prepend="currency">
          <b-form-input
            v-model="record.balance"
            type="number"
            placeholder="Balance"
            size="sm"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col>
        <b-input-group size="sm" :prepend="currency">
          <template v-slot:append>
            <div
              v-if="(record.amount > 0) | (record.amount < 0)"
              class="input-group-text"
            >
              <span v-if="record.amount < 0" class="text-danger">debit</span>
              <span v-if="record.amount > 0" class="text-success">credit</span>
            </div>
          </template>
          <b-form-input
            v-model="record.amount"
            type="number"
            placeholder="Amount"
            size="sm"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col class="form-inline">
        <multiselect
          v-model="selected_tags"
          class="w-75"
          placeholder="Tags"
          label="name"
          track-by="id"
          :limit="3"
          :options="tagOptions()"
          :multiple="true"
          :close-on-select="false"
          hide-selected
        />
        <b-button variant="info" size="sm" class="ml-3" @click="displayCreateTagModal()">
          <b-icon variant="light" icon="plus-square" />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-textarea
          v-model="record.notes"
          placeholder="Notes..."
          rows="3"
          max-rows="3"
        />
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col class="text-right">
        <b-button variant="info" size="sm" @click="onClickUpdate">
          Update
        </b-button>
      </b-col>
    </b-row>
    <!--
          Modals 
    -->
    <b-modal ref="create_category_modal" hide-footer>
      <CreateCategoryForm
        :record-type="record.record_type"
        @categoryCreated="categoryCreated"
      />
    </b-modal>
    <b-modal ref="create_tag_modal" hide-footer>
      <CreateTagForm :record-type="record.record_type" @tagCreated="tagCreated" />
    </b-modal>
  </b-container>
</template>

<script>
import Multiselect from "vue-multiselect";
import CreateCategoryForm from "@/components/category_manager/CreateForm.vue";
import CreateTagForm from "@/components/tag_manager/CreateForm.vue";

export default {
    components: { Multiselect, CreateCategoryForm, CreateTagForm },
    props: { recordIndex: [Number, String], hideEditModal: Function },
    data() {
        return {
            record: {},
            selected_tags: [],
        };
    },
    computed: {
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
        tagOptions() {
            return this.$store.getters["importer/tagList"];
        },
        categoryOptions() {
            switch (this.record.record_type) {
                case "transaction":
                    return this.$store.getters["importer/transactionCategoryList"];
                    break;
                case "income":
                    return this.$store.getters["importer/incomeCategoryList"];
                    break;
                case "saving":
                    return this.$store.getters["importer/savingCategoryList"];
                    break;
                default:
                    return [];
            }
        },
    },
    /*
     * set this.record to corresponding record in store based on this.recordIndex
     */
    created() {
        this.record = {
            ...this.$store.getters["importer/record"](this.recordIndex),
            tags: [...this.$store.getters["importer/record"](this.recordIndex).tags],
        };
        const store_tags = this.$store.getters["importer/record"](this.recordIndex).tags;
        store_tags.forEach((tag_id) => this.selected_tags.push(this.tag(tag_id)));
    },
    methods: {
        /*
         * Return tag object from store based on tag id
         */
        tag(tag_id) {
            return this.$store.getters["importer/tag"](tag_id);
        },
        /*
         * Return name from categoryOptions based on id
         */
        categoryName(category_id) {
            return this.categoryOptions.find((el) => el.id === category_id).name;
        },
        /*
         * Update record in store and hide modal
         */
        onClickUpdate() {
            const data = {
                ...this.record,
                tags: this.selected_tags.map((el) => el.id),
            };
            this.$store.commit("importer/updateRecord", data);
            this.$emit("hideEditModal");
        },
        /*
         * emitted via CreateCategoryForm when category created on server
         */
        categoryCreated(category) {
            switch (this.record.record_type) {
                case "income":
                    this.$store.commit("importer/createUpdateIncomeCategory", category);
                    break;
                case "saving":
                    this.$store.commit("importer/createUpdateSavingCategory", category);
                    break;
                case "transaction":
                    this.$store.commit("importer/createUpdateTransactionCategory", category);
                    break;
            }
            this.record.category = category.id;
            this.$refs["create_category_modal"].hide();
            this.$bvToast.toast("Category Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * set this.record.category = category_id when a category is selected from dropdown
         */
        onSelectCategory(category_id) {
            this.record.category = category_id;
        },
        /*
         * display create_category_modal
         */
        displayCreateCategoryModal() {
            this.$refs["create_category_modal"].show();
        },
        /*
         * display create_tag_modal
         */
        displayCreateTagModal() {
            this.$refs["create_tag_modal"].show();
        },
        /*
         * emitted via CreatetagForm when tag created on server
         */
        tagCreated(tag) {
            this.$store.commit("importer/createUpdateTag", tag);
            this.selected_tags.unshift(tag);
            this.$refs["create_tag_modal"].hide();
            this.$bvToast.toast("Tag Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
    },
};
</script>