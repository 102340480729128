<template>
  <div>
    <b-row>
      <b-col class="text-nowrap">
        <b-button v-b-modal.open-modal size="sm" variant="info">
          <b-icon icon="folder-fill" />
          <span> Open</span>
        </b-button>
        <b-button v-b-modal.save-modal size="sm" variant="info" class="ml-1">
          <b-icon icon="check-circle" />
          <span> Save</span>
        </b-button>
      </b-col>
      <b-col class="text-nowrap mt-2 mt-sm-0">
        <b-dropdown size="sm" variant="info">
          <template v-slot:button-content>
            <span>{{ timespanLabel(timespan) }}</span>
          </template>
          <b-dropdown-header>Timespan</b-dropdown-header>
          <b-dropdown-item-button
            v-for="option in timespan_options"
            :key="option.key"
            @click="onChangeParam({ timespan: option.key })"
          >
            {{ option.label }}
          </b-dropdown-item-button>
        </b-dropdown>
        <b-dropdown size="sm" variant="info" class="ml-1">
          <template v-slot:button-content>
            <span>{{ group_by.charAt(0).toUpperCase() + group_by.slice(1) }}</span>
          </template>
          <b-dropdown-header>Group By</b-dropdown-header>
          <b-dropdown-item-button
            v-for="option in group_by_options"
            :key="option"
            @click="onChangeParam({ group_by: option })"
          >
            {{ option.charAt(0).toUpperCase() + option.slice(1) }}
          </b-dropdown-item-button>
        </b-dropdown>
        <b-button v-b-modal.filter-params-modal size="sm" variant="info" class="ml-1">
          <b-icon icon="funnel-fill" />
          <span> Filter</span>
        </b-button>
      </b-col>
      <b-col class="mt-2 mt-md-0">
        <b-button-group>
          <b-button
            size="sm"
            :variant="selectedDisplayOption == 'table' ? 'danger' : 'info'"
            @click="updateSelectedDisplayOption('table')"
          >
            <b-icon icon="table" />
          </b-button>
          <b-button
            size="sm"
            :variant="selectedDisplayOption == 'chart_pie' ? 'danger' : 'info'"
            @click="updateSelectedDisplayOption('chart_pie')"
          >
            <b-icon icon="pie-chart-fill" />
          </b-button>
          <b-button
            size="sm"
            :variant="selectedDisplayOption == 'spline' ? 'danger' : 'info'"
            @click="updateSelectedDisplayOption('spline')"
          >
            <b-icon icon="graph-up" />
          </b-button>
          <b-button
            size="sm"
            :variant="selectedDisplayOption == 'column' ? 'danger' : 'info'"
            @click="updateSelectedDisplayOption('column')"
          >
            <b-icon icon="bar-chart" />
          </b-button>
          <b-button
            size="sm"
            :variant="selectedDisplayOption == 'stacked_column' ? 'danger' : 'info'"
            @click="updateSelectedDisplayOption('stacked_column')"
          >
            <b-icon icon="bar-chart-fill" />
          </b-button>
          <b-button
            size="sm"
            :variant="selectedDisplayOption == 'stream' ? 'danger' : 'info'"
            @click="updateSelectedDisplayOption('stream')"
          >
            <b-icon icon="soundwave" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <!-- 
            modals
         -->
    <b-modal
      id="filter-params-modal"
      ref="filter-params-modal"
      title="Filter your data"
      scrollable
      hide-footer
    >
      <FilterParams @filterParamsUpdated="filterParamsUpdated" />
    </b-modal>
    <b-modal id="save-modal" ref="save-modal" hide-footer>
      <FormSave @querySaved="querySaved" />
    </b-modal>
    <b-modal id="open-modal" ref="open-modal" hide-footer>
      <FormOpen @queryOpened="queryOpened" />
    </b-modal>
  </div>
</template>

<script>
import FilterParams from "./FilterParams.vue";
import FormSave from "./FormSave.vue";
import FormOpen from "./FormOpen.vue";
import Api from "@/api";

export default {
    components: { FilterParams, FormSave, FormOpen },
    data() {
        return {
            timespan_options: [
                { key: 3, label: "Prior 3 Months" },
                { key: 6, label: "Prior 6 Months" },
                { key: 12, label: "Prior 1 Year" },
                { key: 36, label: "Prior 3 Years" },
                { key: 60, label: "Prior 5 Years" },
            ],
            group_by_options: ["account", "category", "tag", "none"],
        };
    },
    computed: {
        /*
         * Return timespan from store
         */
        timespan() {
            return this.$store.state.query_tool.params.timespan;
        },
        /*
         * Return group_by from store
         */
        group_by() {
            return this.$store.state.query_tool.params.group_by;
        },
        /*
         * Return selected_display_option from store
         */
        selectedDisplayOption() {
            return this.$store.state.query_tool.selected_display_option;
        },
    },
    created() {
        this.getData();
    },
    methods: {
        /*
         * Update selected_display_option in store to provided option
         */
        updateSelectedDisplayOption(option) {
            this.$store.commit("query_tool/updateSelectedDisplayOption", option);
        },
        /*
         * Return the label associated with the provided timespan
         */
        timespanLabel(key) {
            const option = this.timespan_options.find((obj) => obj.key === key);
            return option.label;
        },
        /*
         * Update param in store and call getData
         */
        onChangeParam(param) {
            this.$store.commit("query_tool/updateParams", param);
            this.getData();
        },
        /*
         * Emitted via params Form/Modal when filter params are updated
         */
        filterParamsUpdated() {
            this.$refs["filter-params-modal"].hide();
            this.getData();
        },
        /*
         * Emitted via params Form/Modal when filter params are updated
         */
        querySaved() {
            this.$refs["save-modal"].hide();
            this.$bvToast.toast("Query Saved", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * Emitted via FormOpen when a saved query is opened. Close modal and call getData
         */
        queryOpened() {
            this.$refs["open-modal"].hide();
            this.getData();
        },
        /*
         * Get query data from survey based on store params. Commit response to store.
         */
        getData() {
            this.$store.commit("query_tool/updateFetching", true);
            const params = {
                ...this.$store.state.query_tool.params,
                name: "temp_name",
                account_pks: this.$store.state.query_tool.params.accounts.map((el) => el.id),
                category_pks: this.$store.state.query_tool.params.categories.map((el) => el.id),
                tag_pks: this.$store.state.query_tool.params.tags.map((el) => el.id),
            };
            Api.runQuery(params)
                .then((res) => {
                    this.$store.commit("query_tool/updateTableHeaders", res.data.table_headers);
                    this.$store.commit("query_tool/updateTableData", res.data.table_data);
                    this.$store.commit(
                        "query_tool/updateTableMonthTotals",
                        res.data.table_month_totals
                    );
                    this.$store.commit("query_tool/updateChartPie", res.data.chart_pie);
                    this.$store.commit(
                        "query_tool/updateChartTimeseries",
                        res.data.chart_timeseries
                    );
                    this.$store.commit("query_tool/updateFetching", false);
                })
                .catch((err) => {
                    this.$store.commit("query_tool/updateFetching", false);
                    console.log(err.response);
                });
        },
    },
};
</script>