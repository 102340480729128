<template>
  <b-container fluid>
    <b-row align-h="center">
      <h5 class="text-secondary">
        Update Record
      </h5>
    </b-row>
    <b-row class="mt-3" align-h="center">
      <b-col>
        <b-form-datepicker
          v-model="recordCopy.date"
          :max="new Date()"
          size="sm"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col class="form-inline">
        <b-dropdown
          size="sm"
          variant="info"
          class="w-75"
          split
          split-variant="outline-info"
        >
          <template v-slot:button-content>
            <span class="text-wrap">{{ account(recordCopy.account).full_name }}</span>
          </template>
          <b-dropdown-item-button
            v-for="account in accounts"
            :key="account.id"
            @click="onSelectAccount(account.id)"
          >
            {{ account.full_name }}
          </b-dropdown-item-button>
        </b-dropdown>
        <b-button
          variant="info"
          size="sm"
          class="ml-3"
          @click="displayCreateAccountModal()"
        >
          <b-icon variant="light" icon="plus-square" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col class="form-inline">
        <b-dropdown
          size="sm"
          variant="info"
          class="w-75"
          split
          split-variant="outline-info"
        >
          <template v-slot:button-content>
            <span class="text-wrap">{{ category(recordCopy.category).name }}</span>
          </template>
          <b-dropdown-item-button
            v-for="category in categories"
            :key="category.id"
            @click="onSelectCategory(category.id)"
          >
            {{ category.name }}
          </b-dropdown-item-button>
        </b-dropdown>
        <b-button
          variant="info"
          size="sm"
          class="ml-3"
          @click="displayCreateCategoryModal()"
        >
          <b-icon variant="light" icon="plus-square" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col>
        <b-form-input
          v-model="recordCopy.payee"
          type="text"
          placeholder="Payee"
          size="sm"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col>
        <b-form-checkbox v-model="recordCopy.exclude_from_totals" switch>
          <span class="text-secondary">Exclude from totals</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="recordType === 'saving'" class="mt-2" align-h="center">
      <b-col>
        <b-input-group size="sm" :prepend="currency">
          <b-form-input
            v-model="recordCopy.balance"
            type="number"
            placeholder="Balance"
            size="sm"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col>
        <b-input-group size="sm" :prepend="currency">
          <template v-slot:append>
            <div
              v-if="(recordCopy.amount > 0) | (recordCopy.amount < 0)"
              class="input-group-text"
            >
              <span v-if="recordCopy.amount < 0" class="text-danger">debit</span>
              <span v-if="recordCopy.amount > 0" class="text-success">credit</span>
            </div>
          </template>
          <b-form-input
            v-model="recordCopy.amount"
            type="number"
            placeholder="Amount"
            size="sm"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row v-if="recordType === 'saving'" class="mt-2" align-h="center">
      <b-col>
        <b-input-group size="sm" :prepend="currency">
          <template v-slot:append>
            <div
              v-if="(recordCopy.dividends > 0) | (recordCopy.dividends < 0)"
              class="input-group-text"
            >
              <span v-if="recordCopy.dividends < 0" class="text-danger">debit</span>
              <span
                v-if="recordCopy.dividends > 0"
                class="text-success"
              >credit</span>
            </div>
          </template>
          <b-form-input
            v-model="recordCopy.dividends"
            type="number"
            placeholder="Dividends"
            size="sm"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col class="form-inline">
        <multiselect
          v-model="selectedTags"
          class="w-75"
          placeholder="Tags"
          label="name"
          track-by="id"
          :limit="3"
          :options="tags"
          :multiple="true"
          :close-on-select="false"
          hide-selected
        />
        <b-button variant="info" size="sm" class="ml-3" @click="displayCreateTagModal()">
          <b-icon variant="light" icon="plus-square" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col>
        <b-form-textarea
          v-model="recordCopy.notes"
          placeholder="Notes..."
          rows="3"
          max-rows="3"
        />
      </b-col>
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-button size="sm" variant="info" @click="updateRecord">
        <b-spinner v-if="fetching" small />
        <span v-else>Update</span>
      </b-button>
    </b-row>
    <!--
            Modals 
        -->
    <b-modal ref="create_account_modal" hide-footer>
      <CreateAccountForm @accountCreated="accountCreated" />
    </b-modal>
    <b-modal ref="create_category_modal" hide-footer>
      <CreateCategoryForm :record-type="recordType" @categoryCreated="categoryCreated" />
    </b-modal>
    <b-modal ref="create_tag_modal" hide-footer>
      <CreateTagForm :record-type="recordType" @tagCreated="tagCreated" />
    </b-modal>
  </b-container>
</template>

<script>
import Api from "@/api";
import Multiselect from "vue-multiselect";
import CreateAccountForm from "@/components/account_manager/CreateForm.vue";
import CreateCategoryForm from "@/components/category_manager/CreateForm.vue";
import CreateTagForm from "@/components/tag_manager/CreateForm.vue";

export default {
    components: { CreateAccountForm, CreateCategoryForm, CreateTagForm, Multiselect },
    props: { record: Object, recordType: String },
    data() {
        return {
            fetching: false,
            recordCopy: {},
            selectedTags: [],
        };
    },
    computed: {
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
        accounts() {
            return this.$store.getters["records/accountList"];
        },
        categories() {
            return this.$store.getters["records/categoryList"];
        },
        tags() {
            return this.$store.getters["records/tagList"];
        },
    },
    created() {
        /*
         * use a local copy of record to use for changes until they are applied on server
         */
        this.recordCopy = Object.assign({}, this.record);
        this.record.tags.forEach((tag_id) => this.selectedTags.push(this.tag(tag_id)));
    },
    methods: {
        account(id) {
            return this.$store.getters["records/account"](id);
        },
        category(id) {
            return this.$store.getters["records/category"](id);
        },
        tag(id) {
            return this.$store.getters["records/tag"](id);
        },
        onSelectAccount(account_id) {
            this.recordCopy.account = account_id;
        },
        onSelectCategory(category_id) {
            this.recordCopy.category = category_id;
        },
        /*
         * emitted via CreateAccountForm when account created on server
         */
        accountCreated(account) {
            this.$store.commit("records/createUpdateAccount", account);
            this.recordCopy.account = account.id;
            this.$refs["create_account_modal"].hide();
            this.$bvToast.toast("Account Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * display create_account_modal
         */
        displayCreateAccountModal() {
            this.$refs["create_account_modal"].show();
        },
        /*
         * emitted via CreateCategoryForm when category created on server
         */
        categoryCreated(category) {
            this.$store.commit("records/createUpdateCategory", category);
            this.recordCopy.category = category.id;
            this.$refs["create_category_modal"].hide();
            this.$bvToast.toast("Category Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * display create_category_modal
         */
        displayCreateCategoryModal() {
            this.$refs["create_category_modal"].show();
        },
        /*
         * display create_tag_modal
         */
        displayCreateTagModal() {
            this.$refs["create_tag_modal"].show();
        },
        /*
         * emitted via CreatetagForm when tag created on server
         */
        tagCreated(tag) {
            this.$store.commit("records/createUpdateTag", tag);
            this.selectedTags.unshift(tag);
            this.$refs["create_tag_modal"].hide();
            this.$bvToast.toast("Tag Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * Update record on server then emit recordUpdated in parent
         */
        updateRecord() {
            this.fetching = true;
            Api.updateRecord(this.recordType, {
                ...this.recordCopy,
                tags: this.selectedTags.map((el) => el.id),
            })
                .then((res) => {
                    this.fetching = false;
                    this.$emit("recordUpdated", res.data);
                })
                .catch((err) => {
                    this.fetching = false;
                    this.$bvToast.toast("Unable to update record.", {
                        title: "Record Not Updated",
                        variant: "danger",
                        autoHideDelay: 2500,
                    });
                    console.log(err.response);
                });
        },
    },
};
</script>