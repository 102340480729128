<template>
  <b-table
    ref="query_results_table"
    responsive
    outlined
    striped
    bordered
    small
    sticky-header="500px"
    :items="tableData"
    :fields="tableHeaders"
    :busy="fetching"
  >
    <template v-slot:table-busy>
      <div class="text-center">
        <b-spinner variant="info" />
      </div>
    </template>
    <template v-slot:cell()="data">
      {{ formatCurrency(data.value, currency) }}
    </template>
    <template v-slot:custom-foot>
      <tr>
        <th v-for="header in tableHeaders" :key="header.key" class="table-light">
          {{ formatCurrency(tableMonthTotals[header.key], currency) }}
        </th>
      </tr>
    </template>
  </b-table>
</template>

<script>
import { formatCurrency } from "@/utils/general.js";

export default {
    computed: {
        tableHeaders() {
            return this.$store.state.query_tool.results.table_headers;
        },
        tableData() {
            return this.$store.state.query_tool.results.table_data || [];
        },
        tableMonthTotals() {
            return this.$store.state.query_tool.results.table_month_totals || [];
        },
        fetching() {
            return this.$store.state.query_tool.fetching;
        },
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
    },
    methods: {
        formatCurrency,
    },
};
</script>