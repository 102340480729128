const getDefaultState = () => {
  return {
    active_status: {
      user_is_paidup: true,
      user_is_within_trial: false,
    },
  };
};

const state = getDefaultState();

const getters = {
  // user is active if they are paid up or within the trial period
  userIsActive: (state) => {
    return (
      state.active_status.user_is_paidup ||
      state.active_status.user_is_within_trial
    );
  },
};

const mutations = {
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
  updateUserActiveStatus: (state, data) => {
    state.active_status = { ...state, ...data };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
