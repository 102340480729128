<template>
  <b-container fluid>
    <hr>
    <h4 id="results_section" class="text-center">
      FI Projection Results
    </h4>
    <b-row align-h="center" class="mt-4">
      <b-col cols="12" sm="6" md="5" lg="3">
        <b-row align-h="center">
          <highcharts
            ref="solid_gauge_chart"
            :options="solid_gauge_options"
            :update-args="chart_update_args"
          />
        </b-row>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="5"
        lg="3"
        class="mt-4 mt-sm-0"
      >
        <b-card style="position: relative; height: 200px; overflow-y: auto">
          <b-row align-h="center">
            <span class="text-secondary"> Current Investment Balance: </span>
          </b-row>
          <b-row align-h="center">
            <span>
              {{ formatCurrency(stats.current_balance, currency) }}
            </span>
          </b-row>
          <hr>
          <b-row>
            <b-col class="text-center">
              <span class="text-secondary">Target Balance:</span><br>
              <span>
                {{ formatCurrency(stats.target_balance, currency) }}
              </span>
            </b-col>
            <b-col class="text-center">
              <span class="text-secondary">FI Date:</span><br>
              <span>{{ stats.fi_date }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="5"
        lg="3"
        class="mt-4 mt-lg-0"
      >
        <b-card style="position: relative; height: 200px; overflow-y: auto">
          <b-row align-h="center">
            <span class="text-secondary"> Monthly {{ rolling_type_string }} </span>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-row align-h="center">
                <span class="text-secondary"> Invest: </span>
              </b-row>
              <b-row align-h="center">
                <span>
                  {{
                    formatCurrency(stats.current_monthly_contribution, currency)
                  }}
                </span>
              </b-row>
              <hr>
              <b-row align-h="center">
                <span class="text-secondary"> Spend: </span>
              </b-row>
              <b-row align-h="center">
                <span>
                  {{ formatCurrency(stats.current_monthly_expenses, currency) }}
                </span>
              </b-row>
            </b-col>
            <b-col>
              <b-row align-v="center" class="h-100">
                <b-col>
                  <b-row align-h="center">
                    <h5 class="text-secondary">
                      SER
                    </h5>
                  </b-row>
                  <b-row align-h="center">
                    <h2>{{ stats.ser }}</h2>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="5"
        lg="3"
        class="mt-4 mt-lg-0"
      >
        <b-card style="position: relative; height: 200px; overflow-y: auto">
          <b-row align-h="center">
            <span class="text-center text-secondary"> Annual Expenses Invested </span>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <b-row align-h="center">
                <span class="text-secondary"> Current: </span>
              </b-row>
              <b-row align-h="center" class="mt-2">
                <h2>{{ stats.annual_expenses_invested }}x</h2>
              </b-row>
            </b-col>
            <b-col>
              <b-row align-h="center">
                <span class="text-secondary"> Target: </span>
              </b-row>
              <b-row align-h="center" class="mt-2">
                <h2>{{ stats.target_annual_expenses_invested }}x</h2>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12">
        <b-card>
          <p>
            I currently have
            <span class="text-info">
              {{ formatCurrency(stats.current_balance, currency) }}
            </span>
            invested which is
            <span class="text-info"> {{ stats.annual_expenses_invested }} times </span>
            my annual expenses{{ historical_expenses_string }}
          </p>
          <p>
            Currently my investments would cover
            <span class="text-info"> {{ stats.expense_coverage }}% </span> of my monthly
            expenses based on a
            <span class="text-info"> {{ withdrawal_rate }}% </span>
            withdrawal rate.
          </p>
          <p>
            I will reach FI by
            <span class="text-info">{{ stats.fi_date }}</span> with an investment
            balance of
            <span class="text-info">
              {{ formatCurrency(stats.target_balance, currency) }}.
            </span>
          </p>
          <p>
            This projection assumes I increase the amount I invest by
            <span class="text-info"> {{ annual_contribution_increase }}% </span>
            each year and takes into account a
            <span class="text-info">{{ inflation }}%</span> inflation rate with a
            <span class="text-info">{{ annual_return }}%</span> annual return on my
            investments.
          </p>
          <p v-if="num_events > 0">
            Additionally, there are
            <span class="text-info">{{ num_events }}</span> future events which impact
            my finances and resulting FI timeline.
          </p>
          <b-alert v-else show variant="danger">
            <span class="text-danger">NB:</span> There are no financial events occurring
            in the future. To calculate an accurate projection of the FI timeline,
            adding events which impact future finances is highly recommended.
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <highcharts
          ref="chartRef"
          :options="timeLineMixinOptions"
          :update-args="timeLineMixinUpdateArgs"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import timeLineMixin from "./chart_mixins/time_line.js";
import solid_gauge_mixin from "./chart_mixins/solid_gauge.js";
import { formatCurrency } from "@/utils/general.js";

export default {
    mixins: [timeLineMixin, solid_gauge_mixin],
    props: { planType: String, resultsData: Object },
    data() {
        return {
            chart_update_args: [true, true, { duration: 3000 }],
            stats: {
                current_balance: 0,
                target_balance: 0,
                fi_date: "",
                current_monthly_expenses: 0,
                current_monthly_contribution: 0,
                ser: 0,
                annual_expenses_invested: 0,
                target_annual_expenses_invested: 0,
                expense_coverage: 0,
            },
        };
    },
    computed: {
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
        rolling_type_string() {
            if (this.$store.state.fi_plan.plan_params.rolling_window === 1) {
                return "";
            } else {
                if (this.$store.state.fi_plan.plan_params.rolling_agg_type === "mean") {
                    return "Average";
                }
                return "Median";
            }
        },
        withdrawal_rate() {
            return this.$store.state.fi_plan.plan_params.withdrawal_rate;
        },
        annual_contribution_increase() {
            return this.$store.state.fi_plan.plan_params.annual_contribution_increase;
        },
        inflation() {
            return this.$store.state.fi_plan.plan_params.inflation;
        },
        annual_return() {
            return this.$store.state.fi_plan.plan_params.annual_return;
        },
        num_events() {
            return (
                this.$store.getters["fi_plan/onceOffEventList"].length +
                this.$store.getters["fi_plan/recurringEventList"].length
            );
        },
        historical_expenses_string() {
            if (this.planType !== "basic") {
                if (this.$store.state.fi_plan.plan_params.rolling_window === 1) {
                    return " based on the latest month's activity.";
                } else {
                    if (this.$store.state.fi_plan.plan_params.rolling_agg_type === "mean") {
                        return ` based on a ${this.$store.state.fi_plan.plan_params.rolling_window} month average.`;
                    }
                    return ` based on a ${this.$store.state.fi_plan.plan_params.rolling_window} month median.`;
                }
            }
            return ".";
        },
    },
    watch: {
        /*
         * when resultsData prop changes, populate chart, stats data and scroll results
         * into view
         */
        resultsData() {
            this.stats = { ...this.resultsData.stats };
            this.poplulateFIChart();
            this.populateProgressChart();
            let el = document.getElementById("results_section");
            el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        },
    },
    methods: {
        formatCurrency,
        populateProgressChart() {
            let chartInstance = this.$refs.solid_gauge_chart.chart;
            chartInstance.series[0].setData([this.stats.progress_to_fi], false);
            chartInstance.redraw();
        },
        /*
         * remove existing chart data and annotations before adding new data
         */
        poplulateFIChart() {
            let chartInstance = this.$refs.chartRef.chart;
            while (chartInstance.series.length > 0) {
                chartInstance.series[0].remove(false);
            }
            chartInstance.removeAnnotation("annotations");
            this.resultsData["chart_fi_timeline"].forEach((data) => {
                chartInstance.addSeries({ ...data, yAxis: 0 }, false);
            });
            if (this.resultsData["chart_fi_timeline_comparison"] !== undefined) {
                this.resultsData["chart_fi_timeline_comparison"].forEach((data) => {
                    chartInstance.addSeries({ ...data, yAxis: 0 }, false);
                });
            }
            this.resultsData["chart_recurring_events"].forEach((data) => {
                chartInstance.addSeries({ ...data, yAxis: 1, zIndex: 50 }, false);
            });
            this.resultsData["chart_stats"].forEach((data) => {
                chartInstance.addSeries({ ...data, yAxis: 2, zIndex: 100 }, false);
            });
            this.resultsData["chart_expenses_contributions"].forEach((data) => {
                chartInstance.addSeries({ ...data, yAxis: 3, zIndex: 200 }, false);
            });
            chartInstance.addAnnotation(this.resultsData["chart_once_off_event_annotations"]);
            chartInstance.redraw();
        },
    },
};
</script>