<template>
  <b-card>
    <b-row>
      <b-col>
        <strong v-if="onboarding_step === 1" class="text-success"> 1. Overview. </strong>
        <span v-else class="text-secondary">1. Overview.</span>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <strong v-if="onboarding_step === 2" class="text-success">
          2. Select your currency.
        </strong>
        <span v-else class="text-secondary">2. Select your currency.</span>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <strong v-if="onboarding_step === 3" class="text-success">
          3. Create your financial accounts.
        </strong>
        <span v-else class="text-secondary">3. Create your financial accounts.</span>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <strong v-if="onboarding_step === 4" class="text-success">
          4. Set up your income categories.
        </strong>
        <span v-else class="text-secondary">4. Set up your income categories.</span>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <strong v-if="onboarding_step === 5" class="text-success">
          5. Set up your investment categories.
        </strong>
        <span v-else class="text-secondary">5. Set up your investment categories.</span>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <strong v-if="onboarding_step === 6" class="text-success">
          6. Set up your transaction categories.
        </strong>
        <span v-else class="text-secondary">6. Set up your transaction categories.</span>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <strong v-if="onboarding_step === 7" class="text-success">
          7. What now? Next steps.
        </strong>
        <span v-else class="text-secondary"> 7. What now? Next steps. </span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
    computed: {
        onboarding_step() {
            return this.$store.state.onboarding.onboarding_step;
        },
    },
};
</script>