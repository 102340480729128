<template>
  <b-card header-bg-variant="transparent">
    <template v-slot:header>
      <b-row align-h="end">
        <b-button variant="info" size="sm" @click="onClickSave">
          Save
        </b-button>
        <b-button
          v-if="id !== undefined"
          variant="info"
          size="sm"
          class="ml-1"
          @click="displayPlanCreateModal"
        >
          Save as
        </b-button>
      </b-row>
      <b-row align-h="center">
        <h5>{{ name }}</h5>
      </b-row>
    </template>
    <b-form id="plan_form">
      <b-row v-if="planType == 'basic'">
        <b-col cols="12" md="4" class="mt-3">
          <b-popover
            target="id_balance_label"
            triggers="hover focus"
            placement="top"
            variant="info"
          >
            <template v-slot:title>
              Investment Balance
            </template>
            <p>The current total balance of all your investments.</p>
            <p>
              Do not include the house that you live in. This should only include
              investments which have the potential to provide you with income when you
              reach financial independence.
            </p>
          </b-popover>
          <label id="id_balance_label">
            <b-icon icon="info-circle" variant="info" />
          </label>
          <label class="pl-1">Investment Balance:</label>
          <b-form-input
            v-model.number="balance"
            size="sm"
            type="number"
            step=".01"
            min="0"
            required
          />
        </b-col>
        <b-col cols="12" md="4" class="mt-3">
          <b-popover
            target="id_monthly_expense_label"
            triggers="hover focus"
            placement="top"
            variant="info"
          >
            <template v-slot:title>
              Monthly Expenses
            </template>
            <p>This is how much you spend each month excluding your investments.</p>
            <p>
              This should include all debt repayments, purchases, shopping, mortgage
              payments etc.
            </p>
          </b-popover>
          <label id="id_monthly_expense_label">
            <b-icon icon="info-circle" variant="info" />
          </label>
          <label class="pl-1">Monthly Expenses:</label>
          <b-form-input
            v-model.number="monthly_expense"
            size="sm"
            type="number"
            step=".01"
            min="1"
            required
          />
        </b-col>
        <b-col cols="12" md="4" class="mt-3">
          <b-popover
            target="id_monthly_contribution_label"
            triggers="hover focus"
            placement="top"
            variant="info"
          >
            <template v-slot:title>
              Invested Monthly
            </template>
            <p>
              This is the amount you contribute to your investments on a monthly
              basis.
            </p>
            <p>
              Do not include debt repayments. This should only include investments
              which have the potential to provide you with income when you reach
              financial independence.
            </p>
          </b-popover>
          <label id="id_monthly_contribution_label">
            <b-icon icon="info-circle" variant="info" />
          </label>
          <label class="pl-1">Invested Monthly:</label>
          <b-form-input
            v-model.number="monthly_contribution"
            size="sm"
            type="number"
            step=".01"
            min="1"
            required
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4" class="mt-3">
          <b-popover
            target="id_inflation_label"
            triggers="hover focus"
            placement="top"
            variant="info"
          >
            <template v-slot:title>
              Inflation
            </template>
            Annual inflation percentage.
          </b-popover>
          <label id="id_inflation_label">
            <b-icon icon="info-circle" variant="info" />
          </label>
          <label class="pl-1">Inflation:</label>
          <b-input-group size="sm" :append="inflation_string">
            <b-form-input
              v-model="inflation"
              type="range"
              size="sm"
              min="0"
              max="25"
              step="1"
              required
            />
          </b-input-group>
        </b-col>
        <b-col cols="12" md="4" class="mt-3">
          <b-popover
            target="id_annual_return_label"
            triggers="hover focus"
            placement="top"
            variant="info"
          >
            <template v-slot:title>
              Annual Return
            </template>
            <p>The estimated average percentage return on your investments.</p>
            <p>
              If you're unsure of what to enter and are invested broadly across the
              stock market, a value of 8% is a reasonable assumption.
            </p>
          </b-popover>
          <label id="id_annual_return_label">
            <b-icon icon="info-circle" variant="info" />
          </label>
          <label class="pl-1">Annual Return:</label>
          <b-input-group size="sm" :append="annualReturnString">
            <b-form-input
              v-model="annual_return"
              type="range"
              size="sm"
              min="0"
              max="100"
              step="1"
              required
            />
          </b-input-group>
        </b-col>
        <b-col cols="12" md="4" class="mt-3">
          <b-popover
            target="id_target_withdrawal_rate_label"
            triggers="hover focus"
            placement="top"
            variant="info"
          >
            <template v-slot:title>
              Withdrawal Rate
            </template>
            <p>
              The annual percentage of your investments which you plan to withdraw
              once reaching FI.
            </p>
            <p>The standard for FI is 4% which is known as the safe withdrawal rate.</p>
            <p>
              Lowering this value means being more conservative with your withdrawals
              however it will require a larger investment balance (or lower expenses)
              to achieve.
            </p>
          </b-popover>
          <label id="id_target_withdrawal_rate_label">
            <b-icon icon="info-circle" variant="info" />
          </label>
          <label class="pl-1">Withdrawal Rate:</label>
          <b-input-group size="sm" :append="withdrawal_rate_string">
            <b-form-input
              v-model="withdrawal_rate"
              type="range"
              size="sm"
              min="1"
              max="4"
              step="0.5"
              required
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4" class="mt-3">
          <b-popover
            target="id_annual_contribution_increase_label"
            triggers="hover focus"
            placement="top"
            variant="info"
          >
            <template v-slot:title>
              Annual Contribution Increase
            </template>
            <p>Do you plan to increase the amount you invest each year?</p>
            <p>
              Enter the annual percentage by which you plan to increase your
              investment contributions.
            </p>
          </b-popover>
          <label id="id_annual_contribution_increase_label">
            <b-icon icon="info-circle" variant="info" />
          </label>
          <label class="pl-1">Contribution Increase:</label>
          <b-input-group size="sm" :append="annual_contribution_increase_string">
            <b-form-input
              v-model="annual_contribution_increase"
              type="range"
              size="sm"
              min="0"
              max="25"
              step="1"
              required
            />
          </b-input-group>
        </b-col>
        <b-col cols="12" md="4" class="mt-3">
          <b-popover
            target="id_rolling_window_label"
            triggers="hover focus"
            placement="top"
            variant="info"
          >
            <template v-slot:title>
              Rolling Timeframe
            </template>
            <p>Personal finances often vary greatly from month to month.</p>
            <p>
              By specifying a
              <strong>3 or 12 month</strong> rolling timeframe, forward looking
              projections are smoothed to give a more representative picture for your
              typical financial behavior.
            </p>
            <p>
              Select
              <strong>None</strong> to not use a rolling window for future
              projections.
            </p>
          </b-popover>
          <label id="id_rolling_window_label">
            <b-icon icon="info-circle" variant="info" />
          </label>
          <label class="pl-1">Rolling Timeframe:</label>
          <b-form-select
            v-model="rolling_window"
            :options="rollingWindowOptions"
            size="sm"
            required
          />
        </b-col>
        <b-col cols="12" md="4" class="mt-3">
          <b-popover
            target="id_rolling_agg_type_label"
            triggers="hover focus"
            placement="top"
            variant="info"
          >
            <template v-slot:title>
              Rolling Type
            </template>
            <p>This represents two options for smoothing your monthly finances.</p>
            <p><strong>Average:</strong> Calculate a rolling average over X months.</p>
            <p>
              <strong>Median:</strong> Calculate a rolling median over X months. This
              method ensures that large outlier months don't distort your results.
            </p>
          </b-popover>
          <label id="id_rolling_agg_type_label">
            <b-icon icon="info-circle" variant="info" />
          </label>
          <label class="pl-1">Rolling Type:</label>
          <b-form-select
            v-model="rolling_agg_type"
            :options="rollingAggTypeOptions"
            :disabled="rolling_window == 1"
            size="sm"
            required
          />
        </b-col>
      </b-row>
      <b-row align-h="end" class="mt-4">
        <b-button size="sm" variant="info" @click="onClickRunPlan">
          <b-spinner v-if="fetching" small />
          <span v-else>Run Plan</span>
        </b-button>
      </b-row>
    </b-form>
    <!--
            Modals 
        -->
    <b-modal ref="create_modal" hide-footer>
      <PlanCreateForm :plan-type="planType" @planCreated="planCreated" />
    </b-modal>
  </b-card>
</template>

<script>
import { mapState } from "vuex";
import Api from "@/api";
import PlanCreateForm from "./PlanCreateForm.vue";

/*
 * helper to create computed properties with getters/setters for all plan_params
 * which are housed in vuex
 */
function vuexModels(propertyNames) {
    const models = {};
    for (const name of propertyNames) {
        models[name] = {
            get() {
                return this.$store.state.fi_plan.plan_params[name];
            },
            set(value) {
                return this.$store.commit("fi_plan/updatePlanParam", { name, value });
            },
        };
    }
    return models;
}

export default {
    components: { PlanCreateForm },
    props: { planType: String },
    data() {
        return {
            rollingWindowOptions: [
                { value: 12, text: "12 Months" },
                { value: 3, text: "3 Months" },
                { value: 1, text: "None" },
            ],
            rollingAggTypeOptions: [
                { value: "mean", text: "Average" },
                { value: "median", text: "Median" },
            ],
        };
    },
    computed: {
        ...vuexModels([
            "id",
            "annual_contribution_increase",
            "annual_return",
            "balance",
            "inflation",
            "monthly_contribution",
            "monthly_expense",
            "name",
            "withdrawal_rate",
            "rolling_window",
            "rolling_agg_type",
        ]),
        fetching() {
            return this.$store.state.fi_plan.fetching;
        },
        withdrawal_rate_string() {
            return `${this.withdrawal_rate}%`;
        },
        annual_contribution_increase_string() {
            return `${this.annual_contribution_increase}%`;
        },
        inflation_string() {
            return `${this.inflation}%`;
        },
        annualReturnString() {
            return `${this.annual_return}%`;
        },
    },
    methods: {
        /*
         * display plan create modal if plan_form is valid
         */
        displayPlanCreateModal() {
            const form = document.getElementById("plan_form");
            if (form.checkValidity() === true) {
                this.$refs["create_modal"].show();
            } else {
                form.classList.add("was-validated");
            }
        },
        /*
         * Emited via create_modal when a plan has been created / renamed
         */
        planCreated() {
            this.$refs["create_modal"].hide();
            this.$bvToast.toast("Your plan has been saved", {
                title: "Plan Saved",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * emit runPlan with plan+event params when run plan button is clicked
         */
        onClickRunPlan() {
            this.$emit("runPlan");
        },
        /*
         * If form is valid and plan is new (ie: no id on server), call
         * displayPlanCreateModal else call updatePlan
         */
        onClickSave() {
            const form = document.getElementById("plan_form");
            if (form.checkValidity() === true) {
                if (this.id === undefined) {
                    this.displayPlanCreateModal();
                } else {
                    this.updatePlan();
                }
            } else {
                form.classList.add("was-validated");
            }
        },
        /*
         * update existing plan on server
         */
        updatePlan() {
            const data = {
                ...this.$store.state.fi_plan.plan_params,
                once_off_events: this.$store.getters["fi_plan/onceOffEventList"],
                recurring_events: this.$store.getters["fi_plan/recurringEventList"],
            };
            Api.updatePlan(this.planType, data)
                .then((res) => {
                    this.$bvToast.toast("Your plan has been saved", {
                        title: "Plan Saved",
                        variant: "success",
                        autoHideDelay: 2500,
                    });
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
    },
};
</script>