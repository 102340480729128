<template>
  <b-card
    header-bg-variant="transparent"
    body-class="pl-1 pl-md-3 pr-1 pr-md-3"
  >
    <template v-slot:header>
      <b-row align-h="end">
        <DatePicker
          :selected-date="month"
          :minimum-view="'month'"
          :disabled-dates="disabled_dates"
          input-classes="bg-info text-light"
          @onDateSelected="onDateSelected"
        />
      </b-row>
    </template>
    <div
      v-if="fetching"
      class="text-center"
    >
      <b-spinner
        small
        label="Small Spinner"
        variant="info"
      />
    </div>
    <div v-else>
      <b-row v-b-toggle.income-collapse>
        <b-col cols="1">
          <b-icon
            icon="caret-down"
            variant="info"
          />
        </b-col>
        <b-col>
          <span class="text-info">Income</span>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <strong>{{ formatCurrency(income.total, currency) }}</strong>
        </b-col>
      </b-row>
      <b-collapse
        id="income-collapse"
        accordion="my-accordion"
      >
        <b-table-simple class="table table-sm mt-2">
          <b-tr
            v-for="(value, name) in income.accounts"
            :key="name"
          >
            <b-td class="text-secondary pl-0">
              {{ name }}
            </b-td>
            <td class="d-flex justify-content-end pr-0">
              {{ formatCurrency(value, currency) }}
            </td>
          </b-tr>
        </b-table-simple>
      </b-collapse>
      <b-row
        v-b-toggle.transaction-collapse
        class="mt-3"
      >
        <b-col cols="1">
          <b-icon
            icon="caret-down"
            variant="info"
          />
        </b-col>
        <b-col>
          <span class="text-info">Transactions</span>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <strong>{{ formatCurrency(transaction.total, currency) }}</strong>
        </b-col>
      </b-row>
      <b-collapse
        id="transaction-collapse"
        accordion="my-accordion"
      >
        <b-table-simple class="table table-sm mt-2">
          <b-tr
            v-for="(value, name) in transaction.accounts"
            :key="name"
          >
            <b-td class="text-secondary pl-0">
              {{ name }}
            </b-td>
            <td class="d-flex justify-content-end pr-0">
              {{ formatCurrency(value, currency) }}
            </td>
          </b-tr>
        </b-table-simple>
      </b-collapse>
      <b-row
        v-b-toggle.saved-collapse
        class="mt-3"
      >
        <b-col cols="1">
          <b-icon
            icon="caret-down"
            variant="info"
          />
        </b-col>
        <b-col>
          <span class="text-info">{{ invested_in_title }}</span>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <strong>{{ formatCurrency(saved_in_month.total, currency) }}</strong>
        </b-col>
      </b-row>
      <b-collapse
        id="saved-collapse"
        accordion="my-accordion"
      >
        <b-table-simple class="table table-sm mt-2">
          <b-tr
            v-for="(value, name) in saved_in_month.accounts"
            :key="name"
          >
            <b-td class="text-secondary pl-0">
              {{ name }}
            </b-td>
            <td class="d-flex justify-content-end pr-0">
              {{ formatCurrency(value, currency) }}
            </td>
          </b-tr>
        </b-table-simple>
      </b-collapse>
      <b-row
        v-b-toggle.saving-balance-collapse
        class="mt-3"
      >
        <b-col cols="1">
          <b-icon
            icon="caret-down"
            variant="info"
          />
        </b-col>
        <b-col>
          <span class="text-info">Investments Balance</span>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <strong>{{ formatCurrency(saving_balance.total, currency) }}</strong>
        </b-col>
      </b-row>
      <b-collapse
        id="saving-balance-collapse"
        accordion="my-accordion"
      >
        <b-table-simple class="table table-sm mt-2">
          <b-tr
            v-for="(value, name) in saving_balance.accounts"
            :key="name"
          >
            <b-td class="text-secondary pl-0">
              {{ name }}
            </b-td>
            <td class="d-flex justify-content-end pr-0">
              {{ formatCurrency(value, currency) }}
            </td>
          </b-tr>
        </b-table-simple>
      </b-collapse>
    </div>
  </b-card>
</template>

<script>
import Api from "@/api";
import { formatCurrency } from "@/utils/general.js";
import DatePicker from "@/components/date_picker/Index.vue";

export default {
    components: { DatePicker },
    data() {
        return {
            month: "",
            fetching: false,
            income: {
                total: 0,
                accounts: {},
            },
            transaction: {
                total: 0,
                accounts: {},
            },
            saved_in_month: {
                total: 0,
                accounts: {},
            },
            saving_balance: {
                total: 0,
                accounts: {},
            },
            disabled_dates: {
                from: new Date(),
            },
        };
    },
    computed: {
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
        invested_in_title() {
            if (this.month !== "") {
                let res_month = new Date(this.month);
                return `Invested in ${res_month.toLocaleString("default", {
                    month: "short",
                })} ${res_month.getFullYear()}`;
            }
            return "";
        },
    },
    created() {
        this.onDateSelected();
    },
    methods: {
        formatCurrency,
        onDateSelected(date) {
            this.fetching = true;
            let query_param = date;
            if (date === undefined) {
                query_param = "";
            } else {
                query_param = date.toISOString().substr(0, 10);
            }
            Api.getBlockAccountStatsMonth(query_param)
                .then((res) => {
                    this.fetching = false;
                    this.month = res.data.month;
                    this.transaction.total = res.data.transaction.total;
                    this.transaction.accounts = res.data.transaction.accounts;
                    this.income.total = res.data.income.total;
                    this.income.accounts = res.data.income.accounts;
                    this.saved_in_month.total = res.data.saved_in_month.total;
                    this.saved_in_month.accounts = res.data.saved_in_month.accounts;
                    this.saving_balance.total = res.data.saving_balance.total;
                    this.saving_balance.accounts = res.data.saving_balance.accounts;
                })
                .catch((err) => {
                    this.fetching = false;
                    console.log(err.response);
                });
        },
    },
};
</script>