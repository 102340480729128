const getDefaultState = () => {
  return {
    onboarding_step: 1,
    data_integrity: {
      num_accounts: null,
      num_income_categories: null,
      num_saving_categories: null,
      num_transaction_categories: null,
    },
  };
};

const state = getDefaultState();

const getters = {
  onboardingIncomplete: (state) => {
    if (
      state.data_integrity.num_accounts === 0 ||
      state.data_integrity.num_income_categories === 0 ||
      state.data_integrity.num_saving_categories === 0 ||
      state.data_integrity.num_transaction_categories === 0
    ) {
      return true;
    }
    return false;
  },
};

const mutations = {
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
  updateDataIntegrity: (state, data) => {
    state.data_integrity = data;
  },
  incrementOnboardingStep: (state) => {
    state.onboarding_step += 1;
  },
  updateNumAccounts: (state, num_accounts) => {
    state.data_integrity.num_accounts = num_accounts;
  },
  updateNumIncomeCategories: (state, num_income_categories) => {
    state.data_integrity.num_income_categories = num_income_categories;
  },
  updateNumSavingCategories: (state, num_saving_categories) => {
    state.data_integrity.num_saving_categories = num_saving_categories;
  },
  updateNumTransactionCategories: (state, num_transaction_categories) => {
    state.data_integrity.num_transaction_categories = num_transaction_categories;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
