<template>
  <b-container fluid class="pt-5 pt-sm-0">
    <b-row align-h="center">
      <b-col cols="10" sm="12" class="text-center">
        <h5 v-if="recordType === 'income'">
          Log a single income record.
        </h5>
        <h5 v-else-if="recordType === 'saving'">
          Log a single investment record.
        </h5>
        <h5 v-else>
          Log a single transaction record.
        </h5>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-2">
      <b-col cols="10" sm="12" class="text-center">
        <span class="text-secondary">
          Alternatively, import records in bulk using the
        </span>
        <router-link :to="{ name: 'BulkImport' }">
          <span class="text-info">CSV Importer</span>
        </router-link>
      </b-col>
    </b-row>
    <b-row class="mt-5" align-h="center">
      <b-col cols="12" sm="6" md="4">
        <b-form-datepicker v-model="date" :max="new Date()" size="sm" />
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col cols="12" sm="6" md="4" class="form-inline">
        <b-dropdown
          size="sm"
          variant="info"
          class="w-75"
          split
          split-variant="outline-info"
        >
          <template v-slot:button-content>
            <span class="text-wrap">{{ account.full_name }}</span>
          </template>
          <b-dropdown-item-button
            v-for="account in accounts"
            :key="account.id"
            @click="onSelectAccount(account)"
          >
            {{ account.full_name }}
          </b-dropdown-item-button>
        </b-dropdown>
        <b-button
          variant="info"
          size="sm"
          class="ml-3"
          @click="displayCreateAccountModal()"
        >
          <b-icon variant="light" icon="plus-square" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col cols="12" sm="6" md="4" class="form-inline">
        <b-dropdown
          size="sm"
          variant="info"
          class="w-75"
          split
          split-variant="outline-info"
        >
          <template v-slot:button-content>
            <span class="text-wrap">{{ category.name }}</span>
          </template>
          <b-dropdown-item-button
            v-for="category in categories"
            :key="category.id"
            @click="onSelectCategory(category)"
          >
            {{ category.name }}
          </b-dropdown-item-button>
        </b-dropdown>
        <b-button
          variant="info"
          size="sm"
          class="ml-3"
          @click="displayCreateCategoryModal()"
        >
          <b-icon variant="light" icon="plus-square" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col cols="12" sm="6" md="4">
        <b-form-input
          v-model="payee"
          type="text"
          placeholder="Payee"
          size="sm"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col cols="12" sm="6" md="4">
        <b-form-checkbox v-model="excludeFromTotals" switch>
          <span class="text-secondary">Exclude from totals</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="recordType === 'saving'" class="mt-2" align-h="center">
      <b-col cols="12" sm="6" md="4">
        <b-input-group size="sm" :prepend="currency">
          <b-form-input
            v-model="balance"
            type="number"
            placeholder="Balance"
            size="sm"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col cols="12" sm="6" md="4">
        <b-input-group size="sm" :prepend="currency">
          <template v-slot:append>
            <div v-if="(amount > 0) | (amount < 0)" class="input-group-text">
              <span v-if="amount < 0" class="text-danger">debit</span>
              <span v-if="amount > 0" class="text-success">credit</span>
            </div>
          </template>
          <b-form-input
            id="amount"
            v-model="amount"
            type="number"
            placeholder="Amount"
            size="sm"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row v-if="recordType === 'saving'" class="mt-2" align-h="center">
      <b-col cols="12" sm="6" md="4">
        <b-input-group size="sm" :prepend="currency">
          <b-form-input
            v-model="dividends"
            type="number"
            placeholder="Dividends"
            size="sm"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col cols="12" sm="6" md="4" class="form-inline">
        <multiselect
          v-model="selectedTags"
          class="w-75"
          placeholder="Tags"
          label="name"
          track-by="id"
          :limit="3"
          :options="tags"
          :multiple="true"
          :close-on-select="false"
          hide-selected
        />
        <b-button variant="info" size="sm" class="ml-3" @click="displayCreateTagModal()">
          <b-icon variant="light" icon="plus-square" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-col cols="12" sm="6" md="4">
        <b-form-textarea
          v-model="notes"
          placeholder="Notes..."
          rows="3"
          max-rows="3"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2" align-h="center">
      <b-button variant="info" size="sm" @click="createRecord">
        <b-spinner v-if="fetching" small />
        <span v-else>Create</span>
      </b-button>
    </b-row>
    <!--
            Modals 
        -->
    <b-modal ref="create_account_modal" hide-footer>
      <CreateAccountForm @accountCreated="accountCreated" />
    </b-modal>
    <b-modal ref="create_category_modal" hide-footer>
      <CreateCategoryForm :record-type="recordType" @categoryCreated="categoryCreated" />
    </b-modal>
    <b-modal ref="create_tag_modal" hide-footer>
      <CreateTagForm :record-type="recordType" @tagCreated="tagCreated" />
    </b-modal>
  </b-container>
</template>

<script>
import Api from "@/api";
import Multiselect from "vue-multiselect";
import CreateAccountForm from "@/components/account_manager/CreateForm.vue";
import CreateCategoryForm from "@/components/category_manager/CreateForm.vue";
import CreateTagForm from "@/components/tag_manager/CreateForm.vue";

export default {
    components: {
        Multiselect,
        CreateAccountForm,
        CreateCategoryForm,
        CreateTagForm,
    },
    props: { recordType: String },
    data() {
        return {
            display_as_list: true,
            fetching: false,
            payee: "",
            account: { full_name: "Account" },
            selectedTags: [],
            category: { name: "Category" },
            date: "",
            balance: null,
            amount: null,
            dividends: null,
            notes: "",
            excludeFromTotals: false,
        };
    },
    computed: {
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
        accounts() {
            return this.$store.getters["record_creator/accountList"];
        },
        categories() {
            return this.$store.getters["record_creator/categoryList"];
        },
        tags() {
            return this.$store.getters["record_creator/tagList"];
        },
    },
    methods: {
        onSelectAccount(account) {
            this.account = account;
        },
        onSelectCategory(category) {
            this.category = category;
        },
        /*
         * POST data to create record and add response to this.records
         */
        createRecord() {
            this.fetching = true;
            const data = {
                payee: this.payee,
                account: this.account.id,
                tags: this.selectedTags.map((el) => el.id),
                category: this.category.id,
                date: this.date,
                amount: this.amount === null ? 0 : this.amount,
                balance: this.balance,
                dividends: this.dividends === null ? 0 : this.dividends,
                notes: this.notes,
                exclude_from_totals: this.excludeFromTotals,
            };
            Api.createRecords(this.recordType, data)
                .then((res) => {
                    this.fetching = false;
                    this.$bvToast.toast("Record saved", {
                        title: "Success",
                        variant: "success",
                        autoHideDelay: 2500,
                    });
                    this.payee = "";
                    this.account = { full_name: "Account" };
                    this.selectedTags = [];
                    this.category = { name: "Category" };
                    this.date = "";
                    this.amount = null;
                    this.balance = null;
                    this.dividends = null;
                    this.notes = "";
                    this.excludeFromTotals = false;
                    this.$store.commit("record_creator/createUpdateRecord", res.data);
                })
                .catch((err) => {
                    this.fetching = false;
                    this.$bvToast.toast("Failed to import data", {
                        title: "Failure",
                        variant: "danger",
                        autoHideDelay: 2500,
                    });
                    console.log(err.response);
                });
        },
        /*
         * display create_account_modal
         */
        displayCreateAccountModal() {
            this.$refs["create_account_modal"].show();
        },
        /*
         * emitted via CreateaccountForm when account created on server
         */
        accountCreated(account) {
            this.$store.commit("record_creator/createUpdateAccount", account);
            this.account = account;
            this.$refs["create_account_modal"].hide();
            this.$bvToast.toast("Account Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * display create_category_modal
         */
        displayCreateCategoryModal() {
            this.$refs["create_category_modal"].show();
        },
        /*
         * emitted via CreateCategoryForm when category created on server
         */
        categoryCreated(category) {
            this.$store.commit("record_creator/createUpdateCategory", category);
            this.category = category;
            this.$refs["create_category_modal"].hide();
            this.$bvToast.toast("Category Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
        /*
         * display create_tag_modal
         */
        displayCreateTagModal() {
            this.$refs["create_tag_modal"].show();
        },
        /*
         * emitted via CreatetagForm when tag created on server
         */
        tagCreated(tag) {
            this.$store.commit("record_creator/createUpdateTag", tag);
            this.selectedTags.unshift(tag);
            this.$refs["create_tag_modal"].hide();
            this.$bvToast.toast("Tag Created", {
                title: "Success",
                variant: "success",
                autoHideDelay: 2500,
            });
        },
    },
};
</script>