export default {
  "chart": {
    "backgroundColor": "#1285af",
    "marginTop": 40
  },
  "xAxis": {
    "labels": {
      "style": {
        "color": "#fff"
      }
    }
  },
  "yAxis": {
    "labels": {
      "style": {
        "color": "#ffffff"
      },
      "title": {
        "style": {
          "color": "#ffffff"
        }
      }
    }
  },
  "legend": {
    "itemStyle": {
      "color": "#ffffff"
    },
  },
  "colors": ['#f58231', '#3cb44b', '#46f0f0', '#f032e6', '#e6194b', '#4363d8', '#ffe119', '#bcf60c', '#911eb4', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000'],
};