<template>
  <b-container fluid>
    <b-row align-h="center">
      <h5 class="text-secondary text-center">
        Are you sure you want to delete this record?
      </h5>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <span>Date:</span>
      </b-col>
      <b-col>
        <span class="text-secondary">{{ record.date }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <span>Account:</span>
      </b-col>
      <b-col>
        <span class="text-secondary">{{ record.account }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <span>Payee:</span>
      </b-col>
      <b-col>
        <span class="text-secondary">{{ record.payee }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <span>Exclude from totals:</span>
      </b-col>
      <b-col>
        <span class="text-secondary">{{ record.exclude_from_totals }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <span>Category:</span>
      </b-col>
      <b-col>
        <span class="text-secondary">{{ record.category }}</span>
      </b-col>
    </b-row>
    <b-row v-if="record.balance" class="mt-1">
      <b-col>
        <span>Balance:</span>
      </b-col>
      <b-col>
        <span :class="record.balance < 0 ? 'text-danger' : 'text-success'">{{
          formatCurrency(record.balance, currency)
        }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <span>Amount:</span>
      </b-col>
      <b-col>
        <span :class="record.amount < 0 ? 'text-danger' : 'text-success'">{{
          formatCurrency(record.amount, currency)
        }}</span>
      </b-col>
    </b-row>
    <b-row v-if="record.dividends" class="mt-1">
      <b-col>
        <span>Dividends:</span>
      </b-col>
      <b-col>
        <span :class="record.dividends < 0 ? 'text-danger' : 'text-success'">{{
          formatCurrency(record.dividends, currency)
        }}</span>
      </b-col>
    </b-row>
    <b-row v-if="record.tags && record.tags.length" class="mt-1">
      <b-col>
        <span>Tags:</span>
      </b-col>
      <b-col>
        <b-badge
          v-for="tag in record.tags"
          :key="tag"
          variant="transparent"
          class="m-1 border border-secondary"
        >
          <b-icon icon="tag" />
          {{ tag }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row v-if="record.notes && record.notes.length" class="mt-1">
      <b-col>
        <span>Notes:</span>
        <span class="text-secondary ml-3">{{ record.notes }}</span>
      </b-col>
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-button size="sm" variant="danger" @click="deleteRecord">
        <b-spinner v-if="fetching" small />
        <span v-else>Delete</span>
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import Api from "../../api";
import { formatCurrency } from "@/utils/general.js";

export default {
    props: ["record", "recordType"],
    data() {
        return {
            fetching: false,
        };
    },
    computed: {
        currency() {
            return this.$store.getters["appSettings/currency"];
        },
    },
    methods: {
        formatCurrency,
        /*
         * Update record on server then update state
         */
        deleteRecord() {
            this.fetching = true;
            Api.deleteRecord(this.recordType, this.record.id)
                .then((res) => {
                    this.fetching = false;
                    this.$emit("recordDeleted");
                })
                .catch((err) => {
                    this.fetching = false;
                    console.log(err.response);
                });
        },
    },
};
</script>